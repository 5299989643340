<template>
    <div class="main">
        <div class="nav">
            <router-link to="/" class="navlink" style="color: #036D69"> Home </router-link>
            <router-link to="/phase" class="navlink" style="color: #036D69"> Room Checker</router-link>
            <router-link to="/recommend" class="navlink" style="color: #036D69"> Recommended</router-link>
            <router-link to="/allItems" class="navlink" style="color: #036D69"> All Items</router-link>
            <router-link to="/createItem" class="navlink"> Add Item </router-link>
            <router-link to="/deleteItem" class="navlink"> Remove Item </router-link>
            <router-link to="/orders" class="navlink"> Order History </router-link>
            <a class="navLink" v-on:click="showCart" style="cursor: pointer; color: #1279B7;"> Cart </a>
        </div>
        <h1> Select a Phase </h1>
        <div class="sectionBody">
            <div class="phaseController">
                <div class="phases">
                    <div class="phase" v-bind:class="[roomSelected && isActiveOne ? 'phaseSelected' : 'phase']" v-on:click="selectPhase(1)">
                        <h3>Veg</h3>
                    </div>
                    <div class="phase" v-bind:class="[roomSelected && isActiveTwo ? 'phaseSelected' : 'phase']" v-on:click="selectPhase(2)">
                      <h3>Flower Transplant</h3>
                    </div>
                    <div class="phase" v-bind:class="[roomSelected && isActiveThree ? 'phaseSelected' : 'phase']" v-on:click="selectPhase(3)">
                      <h3>Flower</h3>
                    </div>
                    <div class="phase" v-bind:class="[roomSelected && isActiveFour ? 'phaseSelected' : 'phase']" v-on:click="selectPhase(4)">
                      <h3>Cure</h3>
                    </div>
                </div>

                <div class="selectSubmit">
                    <div class="roomSelector">
                        <h2> Select a Room </h2>
                          <select v-model="selected">
                             <option disabled value="">Please select one</option>
                             <option>Veg</option>
                             <option>1</option>
                             <option>2</option>
                             <option>3</option>
                             <option>Cure</option>
                          </select>
                       <!-- <h2> Room: {{selected}} </h2> -->
                    </div>
                    <div class="roomSubmit">
                        <button v-on:click="roomDirect"> Submit </button>
                    </div>
                </div>
            </div>
        </div>

<!-- Cart Model -->

<!-- Cart Model -->

<transition name="modal">
<div v-if="modal2" class="modal-mask">
  <div class="modal-wrapper">
    <div class="modal-container">

      <div class="modal-header">
        <slot name="header">
          <div style="display: flex; flex-direction: row;">
            <h2 class="modal-banner">Your Cart</h2>
            <button v-on:click="closeCart" class="cancelButton"> X </button>
          </div>
        </slot>
      </div>

      <div class="modal-body" v-if="modal3">
        <slot name="body">
            <div>
            <table>
                <tr>
                    <th> Name </th>
                    <th> Amount </th>
                    <th> On Hand </th>
                    <th> Check </th>
                    <th> Comment </th>
                <tr v-for="item in this.$store.state.shoppingCart" :key="item.custom">
                    <td>{{item.name}}
                    <td>{{item.amount}}</td>
                    <td v-if="item.onhand" v-bind:style="item.cur >= item.onhand? {'color' : '#07ABA1' } : {'color' : '#ff9b9b'}">{{item.cur}} <span style="color: #000">/</span> <span style="color: #000"> {{item.onhand}}</span></td>
                    <td v-else style="color: #CDEEEC"> N/A </td>
                    <td> <span v-if="item.cur + item.amount >= item.onhand || !item.onhand" style="color: #07ABA1">&#x2713;</span>
                         <span v-else style="color: #ff9b9b; font-weight: bold;"> X </span>
                    </td>
                    <td>{{item.comment}}</td>
                </tr>
            </table>
            </div>
        </slot>
      </div>

      <div class="modal-body" v-if="modal4">
        <slot name="body">
            <div style="padding: 5px;">
            <h3 style="color: #ff9b9b; margin: 0px 0px 10px 0px;">{{msg}}</h3>
            <table>
                <tr>
                    <th> Name </th>
                    <th> Order Amount </th>
                    <th> On Hand </th>
                    <th> Comments </th>
                    <th> Remove</th>
                </tr>
                <tr v-for="product in orderForm">
                    <td v-if="product.name.length > 1">{{product.name}}</td>
                    <td v-else><input type="text" style="width: 200px" v-on:change="onCustomChangeCart(product.custom, $event)"/></td>
                    <td><input type="number" name="amount" min="1" style="width: 50px; border:none;" v-on:input="onChangeCart(product.name, $event)" :placeholder="product.amount"/></td>
                    <td v-bind:style="product.cur > product.onhand? {'color' : '#07ABA1' } : {'color' : '#ff9b9b'}">{{product.cur}} <span style="color: #000">/</span> <span style="color: #000"> {{product.onhand}}</span></td>
                    <td><input type="text" name="comment" style="width: 400px; border: none;" v-on:input="onTextChangeCart(product.name, $event)" :placeholder="product.comment"/></td>
                    <td><button style="width: 25px; height: 25px; padding: 2px; background-color: #FF9b9b" v-on:click.stop.prevent="onRemove(product.custom, $event)">x</button></td>
                </tr>
            </table>
            <button style="margin-top: 2%; background-color: #07ABA1" v-on:click="updateCart"> Update Cart </button>
            </div>
        </slot>
      </div>

      <div class="modal-body" v-if="modal5">
        <slot name="body">
            <div>
                <h1> Your order has been successfully registered! </h1>
                <h2> We will contact you if there are any problems</h2>
                <table>
                    <tr>
                        <th> Name </th>
                        <th> Amount </th>
                        <th> Comment </th>
                    <tr v-for="item in this.$store.state.shoppingCart" :key="item.custom">
                        <td>{{item.name}}
                        <td>{{item.amount}}</td>
                        <td>{{item.comment}}</td>
                    </tr>
                </table>
            </div>
        </slot>
      </div>

      <div class="modal-footer" v-if="!modal4 && !modal5">
        <slot name="footer">
          <button class="modal-default-button" v-on:click="clearCart" style="background-color: #8CB6D2;"> Clear Cart </button>
          <button class="modal-default-button" v-on:click="editCart" style="background-color: #9CDDD9;"> Edit Cart </button>
          <button class="modal-default-button" v-on:click="submitCart"> Submit </button>
        </slot>
      </div>
    </div>
  </div>
</div>
</transition>

</div>
</template>

<script>
    export default {
        data () {
            return {
                msg: 'Which phase are you currently at?',
                currentTarget: '',
                selected: '',
                hashTable: {'Veg.1' : '15774493', 'Veg.2' : '15774494', 'Veg.3': '15774498', 'Veg.4' : '15774500',
                '1.1' : '15773682', '1.2' : '15773678', '1.3' : '15773685', '1.4' : '15773689',
                '2.1' : '15773655', '2.2' : '15773639', '2.3' : '15773648', '2.4' : '15773670',
                '3.1' : '15773253', '3.2': '15773260', '3.3' : '15773339', '3.4' : '15773324',
                'Cure.1' : '15774335', 'Cure.2' : '15774339', 'Cure.3' : '15774341', 'Cure.4' : '15774346'
                },
                room: '',
                isActiveOne: 0,
                isActiveTwo: 0,
                isActiveThree: 0,
                isActiveFour: 0,
                roomSelected: 0,
                facility: '15505335',
                posts: [],
                error: [],
                filter: [],
                restock: [],
                orderForm: [],
                order: [],
                folderIDs: [],
                subFolderIDs: [],
                phasesFolders: [],
                facilityFolders: [],
                additionalLocations: [],
                dupAlert: [],
                testTable: {},
                stockTable: {},
                dupCheck: {},
                i: 0,
                flag1: 0,
                flag2: 0,
                flag3: 0,
                flag4: 1,
                flag5: 0,
                alertDup: 0,
                showOrder: 0,
                custom: 0,
                showModal: 0,
                modal1: 1,
                modal2: 0,
                modal3: 0,
                modal4: 0,
                modal5: 0,
                editFlag: 0,
                finalSub: 0,
                name: '',
                notes: '',
                quantity: '',
                minimum: '',
                edit_id: '',
                search: '',
            }
        },
        methods: {
                roomDirect(){
                  this.room = this.hashTable[this.selected + '.' + this.currentTarget]
                  this.$router.push({ path: 'room/' + this.room })
                },
                selectPhase(str){
                  this.currentTarget = str
                  if (!this.roomSelected){
                    switch(str) {
                      case 1:
                        this.isActiveOne = 1
                        this.roomSelected = 1
                        break;
                      case 2:
                        this.isActiveTwo = 1
                        this.roomSelected = 1
                        break;
                      case 3:
                        this.isActiveThree = 1
                        this.roomSelected = 1
                        break;
                      case 4:
                        this.isActiveFour = 1
                        this.roomSelected = 1
                        break;
                    }
                  }
                  else {
                      this.roomSelected = 0
                      this.isActiveOne = 0
                      this.isActiveTwo = 0
                      this.isActiveThree = 0
                      this.isActiveFour = 0
                  }
                },
//////////////////////////////////////////// Shopping Cart Functions ////////////////////////////////////////////
            showCart(){
                this.modal2 = 1
                this.modal3 = 1
            },
            closeCart(){
                if(this.finalSub){
                  this.clearCart()
                  this.finalSub = 0
                }
                this.modal2 = 0
                this.modal3 = 0
                this.modal4 = 0
                this.modal5 = 0
                this.orderForm = []
                this.editFlag = 0
            },
            editCart(){
                this.modal4 = 1
                this.modal3 = 0
                this.modal5 = 0
                if(!this.editFlag){
                    this.editFlag = 1
                    let curCart = this.$store.state.shoppingCart
                    for(let i = 0; i < curCart.length; i++){
                       this.orderForm.push(curCart[i])
                    }
                }
            },
            onRemove(id) {
                console.log(this.orderForm)
                console.log("firing from on onRemove - id is: " + id)
                let newView = []
                for(let i = 0; i < this.orderForm.length; i++){
                    if(this.orderForm[i].custom != id) {
                        newView.push(this.orderForm[i])
                    }
                }

                this.orderForm = newView

                let output = []
                for(let j = 0; j < this.order.length; j++){
                    console.log(id)
                    if(this.order[j].custom != id) {
                        output.push(this.order[j])
                    }
                }
                this.order = output
            },
            clearCart(){
                this.$store.commit('removeItems', this.$store.state.shoppingCart.length)
                this.modal2 = 0
                this.modal3 = 0
                this.modal4 = 0
            },
            updateCart(){
                let curCart = this.$store.state.shoppingCart
                this.$store.commit('removeItems', curCart.length)
                for(let j = 0; j < this.orderForm.length;j++){
                    this.$store.commit('addItems', this.orderForm[j])
                }
                this.modal3 = 1
                this.modal4 = 0
                this.orderForm = []
                this.editFlag = 0
            },
            submitCart(e){
                e.preventDefault()
                this.modal5 = 1
                this.modal3 = 0
                this.handleSubmit(e)
                this.finalSub = 1
            },
            stepBack(){
              this.cleartCart()
              this.$router.go(-1)
            },
            addToCart(){
                this.dupAlert = []
                this.alertDup = 0
                let curCart = this.$store.state.shoppingCart
                for (let i = 0; i < curCart.length;i++){
                    if(!this.dupCheck[curCart[i].name]){
                      this.dupCheck[curCart[i].name] = true
                    }
                }

                for(let i = 0; i < this.order.length;i++){
                    if(!this.dupCheck[this.order[i].name]){
                        this.$store.commit('addItems', this.order[i])
                        this.dupCheck[this.order[i].name] = true
                    }
                    else {
                        this.dupAlert.push(this.order[i].name)
                        this.alertDup = 1
                    }
                }
                this.flag2 = 0
                this.flag5 = 1
                this.orderForm = []
                this.order = []
                setTimeout(() => this.flag1 = 1 ,  2000);
            },
            // Submit order form
            handleSubmit(e){
              e.preventDefault()
              let order = this.$store.state.shoppingCart
              console.log(order)
              this.$store.dispatch('logOrder', order)

            },
            resetCartView() {
                this.flag1 = 1
                this.dupAlert = []
                this.alertDup = 0
            },
            onChangeCart(str, event){
                  if(event.target.value < 1){
                    this.msg = "Please input a valid amount " + str
                  }
                  else {
                    this.msg = ''
                    for (let i = 0; i < this.orderForm.length; i++){
                          if(this.orderForm[i].name === str){
                            this.orderForm[i].amount = event.target.value
                        }
                    }
                }
            },
            onTextChangeCart(str, event){
                for (let i = 0; i < this.orderForm.length; i++){
                      if(this.orderForm[i].name === str){
                        this.orderForm[i].comment = event.target.value
                    }
                }
            },
            onCustomChangeCart(id, event) {
                event.preventDefault()
                for (let i = 0; i < this.orderForm.length; i++) {
                    if(id === this.orderForm[i].custom){
                          this.orderForm[i].name = event.target.value
                    }
                }
            },
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    table, th, td {
      border: 1px solid #DFDFDF;
      padding: 2px;
    }

    table {
        border-collapse: collapse;
        margin-left:auto;
        margin-right:auto;
    }  
    h1, h2 {
        font-weight: normal;
    }
    ul {
        list-style-type: none;
        padding: 0;
    }
    li {
        display: inline-block;
        margin: 0 10px;
    }
    a {
        color: #07ABA1;
        font-size: 1.5em;
    }
    button {
        padding: 1%;
        font-size: 1em;
        background-color: #07ABA1;
        color: #FFF;
        border-radius: 2px;
        border: none;
        cursor: pointer;
        outline: none;
        box-shadow: none;
    }
    .main {
        display: block;
        width: 100vw;
        height: 100vh;
    }
    .nav {
        padding-left: 2%;
        height: 1vh;
        width: 100vw;
        display: flex;
        /* border: 1px dotted #000; */
        align-items: center;
        justify-content: left;

    }
    .navlink {
        padding-right: 25px;
        font-size: 1.5em;
        text-decoration: none;
    }
    .sectionBody {
        width: 100vw;
        height: 90vh;
        /* border: 1px dotted #000; */
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: -50px;
    }
    .phaseController {
        width: 85%;
        height: 85%;
        /* border: dotted #000 1px; */
        display: flex;
        flex-direction: column;
    }
    .phases {
        width: 100%;
        height: 55%;
        /* border: dotted #000 1px; */
        display: flex;
        flex-direction: row;
    }
    .phase {
        width: 25%;
        height: 100%;
        margin: 2px;
        background-color: #07ABA1;
        cursor : grab;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .phase:hover {
        width: 25%;
        height: 100%;
        background-color: lightgreen;
        cursor: grab;
    }
    .phaseSelected {
        width: 25%;
        height: 100%;
        background-color: lightgreen;
        cursor: grab;
    }
    .selectSubmit {
        height: 25%;
        width: 100%;
        padding-top: 2%;
        display: flex;
        /* border: dotted 1px #000; */
        flex-direction: column;
    }
    .roomSelector {
        width: 100%;
        height: 100%;
    }
    .roomSubmit {
        padding-top: 2%;
        width: 100%;
        height: 40%;
        /* border: dotted 1px #000; */
    }
/* Modal CSS */

    .modal-mask {
      position: fixed;
      z-index: 9998;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: table;
      transition: opacity 0.3s ease;
    }

    .modal-wrapper {
      display: table-cell;
      vertical-align: middle;
    }

    .modal-container {
      width: 60%;
      height: 80%;
      margin: 0px auto;
      background-color: #fff;
      border-radius: 2px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
      transition: all 0.3s ease;
    }

    .modal-header h3 {
      margin-top: 0;
      color: #42b983;
    }

    .modal-body {
      margin: 20px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .modal-default-button {
      margin: 5px;
    }
    .modal-banner {
      margin: 0px;
      padding: 0px; 
      background-color: #07ABA1; 
      width: 98%;
      font-size: 2em;
      color: #FFF;

    }
    .cancelButton {
      background-color: #07ABA1;
      color: #FFF; 
      float: lef; 
      font-size: 1.4em;
      font-weight: 1200;
      border-radius: 0px;
    }

    /* Transition CSS */

    .fade-enter-active, .fade-leave-active {
      transition-property: opacity;
      transition-duration: .25s;
    }

    .fade-enter-active {
      transition-delay: .25s;
    }

    .fade-enter, .fade-leave-active {
      opacity: 0;
    }

    .modal-enter {
      opacity: 0;
    }       

    .modal-leave-active {
      opacity: 0;
    }

    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
</style>
