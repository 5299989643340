<template>
<div style="margin: auto 0; padding-top: 60px">

<div class="nav">
    <router-link to="/" class="navlink" style="color: #036D69"> Home </router-link>
    <router-link to="/orders" class="navlink"> Order History </router-link>
    <a class="navLink" v-on:click="showCart" style="cursor: pointer; color: #1279B7;"> Cart </a>
</div>

<transition appear appear-class="fade">
<div v-if="!flag1 && !flag2" style="display: flex; flex-direction: row; justify-content: center; padding-top: 15%;">
    <rotate-square2></rotate-square2>
</div>
</transition appear>

<transition name="fade">
<div v-if="flag1" style="display: flex; flex-direction: row; justify-content: center; align-items: center; width: 100%; height: 450px; margin-top: 4%">
    <div style="width: 20%; height: 100%; display: flex; flex-direction: column; justify-content: left; align-items: center; padding-left: 7%">
        <div class="tool" style="display: flex; flex-direction: row;"><div class="toolButton" style="background-color: #07ABA1; font-weight: 600; font-size: 1em" v-on:click="filterADS"> <span>ADS</span></div> <span class="tooltiptext"> ADS Inventory </span></div>
        <div class="tool" style="display: flex; flex-direction: row;"><div class="toolButton" style="background-color: #FF9B9B;" v-on:click="filterRecommended"> <span>R</span></div> <span class="tooltiptext"> Recommended </span></div>
        <div class="tool" style="display: flex; flex-direction: row;"><div class="toolButton" style="background-color: #9CDDD9;" v-on:click="filterAll"> <span>I</span></div> <span class="tooltiptext"> Your Inventory </span></div>
        <div class="tool" style="display: flex; flex-direction: row;"><div class="toolButton" style="background-color: #8CB6D2; font-size: 2em" v-on:click="onAdd"> <span>+</span></div> <span class="tooltiptext"> Add Item </span></div>
        <div class="tool" style="display: flex; flex-direction: row;" v-if="card"><div class="toolButton" style="background-color: #C1D6D3;" v-on:click="card = 0"> <span>V</span></div> <span class="tooltiptext"> List View </span></div>
        <div class="tool" style="display: flex; flex-direction: row;" v-if="!card"><div class="toolButton" style="background-color: #B2D2B5;" v-on:click="card = 1"> <span>V</span></div> <span class="tooltiptext"> Card View </span></div>
        <div class="tool" style="display: flex; flex-direction: row;"><router-link to="/print" class="toolButton" style="background-color: #406D74; text-decoration: none"> P </router-link><span class="tooltiptext"> Print </span></div>
    </div>

    <div v-if="card" style="width: 90%; height: 100%; display: flex; justify-content: center; align-items: center; padding-right: 250px;">
        <div class="cardBody">
              <div class="card" v-for="(item, i) in stock" v-bind:key="i">
                  <div v-if="item.ADSitem" style="background-color: #07ABA1;"> ADS </div>
                  <button v-else v-on:click="onEdit(item)" style="background-color: #8CB6D2"> Edit </button>
                  <div> 
                      <h2 class="cardHeader" v-bind:style="item.name.length < 25 ? {'font-size' : '1.1em'} : {'font-size' : '0.9em', 'padding-top' : '5px'}" >{{item.name}}</h2>
                  </div>
                  <div>
                      <div>
                        <span v-if="item.category == 'Equipment' || item.category == 'Fans & Filter'">
                          <img src="~@/assets/equipment.png" class="icon"/>
                        </span>
                        <span v-else-if="item.category == 'Nutrients & Supplements'"  v-on:click="filterSearch(item.category)">
                          <img src="~@/assets/fert.png" class="icon"/>
                        </span>
                        <span v-else-if="item.category == 'Office Supplies'" v-on:click="filterSearch(item.category)">
                          <img src="~@/assets/shears.png" class="icon"/>
                        </span>
                        <span v-else-if="item.category == 'Grow Media & Amendments'" v-on:click="filterSearch(item.category)">
                          <img src="~@/assets/grow.png" class="icon"/>
                        </span>
                        <span v-else-if="item.category == 'Lighting'" v-on:click="filterSearch(item.category)">
                          <img src="~@/assets/lighting.png" class="icon"/>
                        </span>
                        <span v-else-if="item.category == 'Hygiene & Safety'" v-on:click="filterSearch(item.category)">
                          <img src="~@/assets/safety.png" class="icon"/>
                        </span>
                        <span v-else-if="item.category == 'Bugs'" v-on:click="filterSearch(item.category)">
                          <img src="~@/assets/bug.png" class="icon"/>
                        </span>
                        <span v-else-if="item.category == 'Irrigation' || item.category == 'Water Pump & Air Pump'" v-on:click="filterSearch(item.category)">
                          <img src="~@/assets/pump.png" class="icon"/>
                        </span>
                        <span v-else>
                         <!-- <img src="~@/assets/custom.png" class="icon"/> -->
                        </span>
                      </div>
                      <h1 v-bind:style="item.quantity  > item.necessary_amount? {'color' : '#000' } : {'color' : '#ff9b9b'}" style="font-size: 4em; padding: 0px; margin: 0px;">{{item.quantity}}</h1> 
                      <input type="checkbox" :value="item" v-model="orderForm">
                  </div>
              </div>
        </div>
    </div>

    <div v-else style="width: 95%; height: 100%; display: flex; justify-content: center; align-items: center; padding-right: 250px">
        <div style="width: 100%; height: 100%; overflow-y: auto">
              <table>
                <tr>
                    <th> Name </th>
                    <th> Description </th>
                    <th> Add </th>
                </tr>
                <tr v-for="(item, i) in stock" v-bind:key="i">
                    <td>{{item.name}}</td>
                    <td v-if="!item.SID">{{item.description}}</td>
                    <td v-else>{{item.notes}}</td>
                    <td><input type="checkbox" :value="item" v-model="orderForm"></td>
                </tr>
              </table>
        </div>
    </div>
</div>
</transition>


<!--- //////////////////////////////////////// handling search option //////////////////////////////////////////////-->

<transition name="fade">
<div v-if="flag1" style="padding-top: 2%">
    <button v-on:click="checkForm" style="background-color: #FF9b9b"> Add to Cart </button>
    <div v-if="!adsView">
        <form>
        <!-- <h3> Inventory Search </h3> -->
        <div style="display: flex; flex-direction: row; justify-content: center;">
        <input style="width: 300px; margin-top: 1%; padding: 5px; outline: none;" type="text" v-model="search" v-on:input="confirmItems($event)" placeholder="Search for a product in your inventory">
        </div>
        </form>
    </div>
    <div v-else>
        <!-- <h3> ADS Search </h3> -->
        <form>
        <div style="display: flex; flex-direction: row; justify-content: center;">
        <input style="width: 300px; margin-top: 1%; padding: 5px; outline: none;" type="text" v-model="searchADS" v-on:input="confirmItemsADS($event)" placeholder="Search for a ADS product">
        </div>
        </form>
    </div>
</div>
</transition>

<!--- ///////////////////////////////////////// edit features ///////////////////////////////////////////////////////-->

<transition name="fade">
<div v-if="flag2 && !flag1" style="padding-top: 3%">
<h1> Order Sheet </h1>
<h2 style="color: #ff9b9b">{{msg}}</h2>
<div v-if="flag2 && !flag1" style="display: flex; justify-content: center;">
    <form>
    <table>
        <tr>
            <th> Name </th>
            <th> Current Stock </th>
            <th> Minimum On Hand </th>
            <th> Order Amount </th>
            <th> Add Details </th>
           <!-- <th> Comments </th> -->
            <th> Remove </th>
        </tr>
        <tr v-for="product in orderForm">
            <td v-if="product.name.length > 1">{{product.name}}</td>
            <td v-else><input type="text" style="wdith: 200px" v-on:change="onCustomChange(product.custom, $event)"/></td>
            <td>{{product.quantity}}</td>
            <td v-if="product.onhand" v-bind:style="product.quantity >= product.onhand? {'color' : '#07ABA1' } : {'color' : '#ff9b9b'}">{{product.quantity}} <span style="color: #000">/</span> <span style="color: #000"> {{product.onhand}}</span></td>
            <td v-else>N/A</td>
            <td><input type="number" name-="amount" min="1" style="width: 50px;" v-on:input="onChange(product.name, $event)" required/></td>
            <td><button style="width: 25px; height: 25px; padding: 2px;" v-on:click.stop.prevent="createDetails(product)">+</button> </td>
            <!-- <td><input type="text" name="comment" style="width: 400px" v-on:input="onTextChange(product.name, $event)"/></td> -->
            <td><button style="width: 25px; height: 25px; padding: 2px; background-color: #FF9b9b" v-on:click.stop.prevent="onRemove(product.custom, $event)">x</button></td>
        </tr>
    </table>
    </form>
</div>
<div style="padding-top: 2%">
    <button v-on:click.stop.prevent="cancelOrderForm" style="background-color: #8CB6D2; margin-right: 5px;"> Cancel </button>
    <button v-on:click.stop.prevent="insertProd" style="background-color: #9CDDD9; margin-right: 5px;"> Custom </button>
    <button v-on:click.stop.prevent="addToCart"> Add to Cart </button>
</div>
</div>
</transition>

<transition name="fade">
<div v-if="!flag2 && !flag1 && flag5" style="padding-top: 3%">
<h1> Order has Successfully Been Added to Your Cart</h1>
<div v-if="alertDup">
    <h2 style="color: red;">The following item(s) are already in your cart: </h2>
    <ul v-for="dup in dupAlert">
        <li>{{dup}}</li>
    </ul>
</div>
<h3>Sending you back to the previous screen</h3></div>
</transition>

<!-- ////////////////////////////////////////// add modal ///////////////////////////////////////////////////////-->

<transition name="modal">
<div v-if="modalAdd" class="modal-mask">
  <div class="modal-wrapper">
    <div class="modal-container" style="width: 450px; height: 500px; overflow-y: hidden;">
      <div class="modal-header">
        <slot name="header">
        <h1 v-if="modalAdd" style="padding: 0; margin: 0; background-color: #8CB6D2; color: #FFF"> Add Item </h1>
        </slot>
      </div>
      <div class="modal-body" style="padding: 0; margin: 0;" v-if="modalAdd">
        <slot name="body">
           <div class="form-style-7">
            <ul>
            <li>
                <label for="name">Product Name</label>
                <input type="text" name="name" maxlength="100" v-model="name" :placeholder="name">
                <span>Enter a comment</span>
            </li>
            <li>
                <label for="stock">Current Stock</label>
                <input type="number" name="stock" maxlength="100" v-model="quantity" :placeholder="quantity">
                <span>Enter amount currently available in inventory</span>
            </li>
            <li>
                <label for="minimum">Minimum Requirement</label>
                <input type="number" name="minimum" maxlength="100" v-model="minimum" :placeholder="minimum">
                <span>Enter amount necessary for operations at any given time</span>
            </li>
            <li style="display: block; padding: 9px; border: 1px solid #ddd; margin-bottom: 30px; border-radius: 3px">
                <label for="notes">Notes</label>
                <input type="text" name="notes" v-model="notes" :placeholder="notes">
                <span>Enter additional info (eg: Pack of 20)</span>
            </li>
                <div v-if="modalAdd">
                    <button class="modal-default-button" v-on:click="modalAdd = 0" style="background-color: rgb(156, 221, 217)"> Cancel </button>
                    <button class="modal-default-button" v-on:click="addItem"> Add </button>
                </div>
            </ul>
           </div>
        </slot>
      </div>

      <div class="modal-footer">
        <slot name="footer">
        </slot>
      </div>
    </div>
  </div>
</div>
</transition>


<!-- ////////////////////////////////////////// edit modal ///////////////////////////////////////////////////////-->

<transition name="modal">
<div v-if="showModal" class="modal-mask">
  <div class="modal-wrapper">
    <div class="modal-container" style="width: 450px; height: 500px; overflow-y: hidden;">
      <div class="modal-header">
        <slot name="header">
        <h1 v-if="modal1" style="padding: 0; margin: 0; background-color: #8CB6D2; color: #FFF"> Edit </h1>
        <h1 v-else style="padding 0; margin: 0; background-color: #FF9B9B; color: #FFF"> Remove </h1>
        </slot>
      </div>

      <div class="modal-body" style="padding: 0; margin: 0;" v-if="modal1">
        <slot name="body">
           <div class="form-style-7">
            <ul>
            <li>
                <label for="name">Product Name</label>
                <input type="text" name="name" maxlength="100" v-model="name" :placeholder="name">
                <span>Enter a comment</span>
            </li>
            <li>
                <label for="stock">Current Stock</label>
                <input type="number" name="stock" maxlength="100" v-model="quantity" :placeholder="quantity">
                <span>Enter amount currently available in inventory</span>
            </li>
            <li>
                <label for="minimum">Minimum Requirement</label>
                <input type="number" name="minimum" maxlength="100" v-model="minimum" :placeholder="minimum">
                <span>Enter amount necessary for operations at any given time</span>
            </li>
            <li style="display: block; padding: 9px; border: 1px solid #ddd; margin-bottom: 30px; border-radius: 3px">
                <label for="notes">Notes</label>
                <input type="text" name="notes" v-model="notes">
                <span>Enter additional info (eg: Pack of 20)</span>
            </li>
                <div v-if="modal1">
                    <button class="modal-default-button" v-on:click="modal1 = 0" style="background-color: #FF9B9B"> Remove </button>
                    <button class="modal-default-button" v-on:click="cancelEdit" style="background-color: rgb(156, 221, 217)"> Cancel </button>
                    <button class="modal-default-button" v-on:click="editItem"> Update </button>
                </div>
            </ul>
           </div>

        </slot>
      </div>

      <div class="modal-body" v-else>
        <slot name="body">
           <div class="form-style-7">
            <h1> Are you sure you want to remove <span>{{name}}</span>?</h1>
           </div>
        </slot>
          <div>
              <button class="modal-default-button" style="width: 65px"  v-on:click="removeItem"> Yes </button>
              <button class="modal-default-button" style= "width: 65px; background-color: #8CB6D2;" v-on:click="modal1 = 1"> Cancel </button>
          </div>
      </div>

      <div class="modal-footer">
        <slot name="footer">
        </slot>
      </div>
    </div>
  </div>
</div>
</transition>

<!--//////////////////////////////////////////// Cart Model///////////////////////////////////////////////////////// -->

<transition name="modal">
<div v-if="modal2" class="modal-mask">
  <div class="modal-wrapper">
    <div class="modal-container" style="width: 1100px; height: 800px">

      <div class="modal-header">
        <slot name="header">
          <div style="display: flex; flex-direction: row;">
            <h2 class="modal-banner">Your Cart 
            <span v-if="emergencyFlag" style="color: red">(URGENT)</span>
            <span v-on:click="closeCart" style="color: #FFF; float: right; cursor: pointer; padding-right: 2%">X</span> 
            </h2>
            <!-- <button v-on:click="closeCart" class="cancelButton" style="padding-right: 3%"> X </button> -->
          </div>
        </slot>
      </div>

      <transition name="slide">
      <div v-if="doubleAlert" style="margin-bottom: 2%; box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); margin-top: 2%">
      <div style="display: flex; flex-direction: row">
          <h3 style="margin: 0px; padding: 0px; background-color: #FF9B9B; border: solid 2px #FF9B9B; width: 100%; color: #FFF"> Double Entry? <span v-on:click="doubleAlert = 0" style="color: #FFF; float: right; cursor: pointer; padding-right: 2%">X</span> </h3>
      </div>
        <div style="display: flex; flex-direction: column; justify-content: center; align-items: center">
          <h2> The following items have already been ordered: </h2>
          <div>
                <tr>
                    <th> Name </th>
                    <th> Amount </th>
                    <th> Comment </th>
                    <th> Ordered By </th>
                    <th> Remove </th>
                <tr v-for="item in prevOrder" :key="item.custom">
                    <td>{{item.name}}
                    <td>{{item.amount}}</td>
                    <td>{{item.comment}}</td>
                    <td>{{item.orderedBy}}</td>
                    <td><input type="checkbox" :value="item" v-model="cancelForm"></td>
                </tr>
          </div>
        </div>
        
        <div style="padding-top: 1%; padding-bottom: 1%">
            <button style="margin-right: 1%; background-color: #8CB6D2" v-on:click="removeFromOrder"> Remove </button>
            <button style="background-color: #9CDDD9" v-on:click="doubleAlert = 0"> Ignore </button>
        </div>
      </div>
      </transition>

      <div class="modal-body" v-if="modal3">
        <slot name="body">
            <!-- <div v-if="shoppingCart"> -->
          <div>
            <table>
                <tr>
                    <th> Name </th>
                    <th> Amount </th>
                    <th> On Hand </th>
                    <th> Check </th>
                    <th> Comment </th>
                <tr v-for="item in this.$store.state.shoppingCart" :key="item.custom">
                    <td>{{item.name}}
                    <td>{{item.amount}}</td>
                    <td v-if="item.onhand" v-bind:style="item.cur >= item.onhand? {'color' : '#07ABA1' } : {'color' : '#ff9b9b'}">{{item.cur}} <span style="color: #000">/</span> <span style="color: #000"> {{item.onhand}}</span></td>
                    <td v-else style="color: #CDEEEC"> N/A </td>
                    <td> <span v-if="item.cur + item.amount >= item.onhand || !item.onhand" style="color: #07ABA1">&#x2713;</span>
                         <span v-else style="color: #ff9b9b; font-weight: bold;"> X </span>
                    </td>
                    <td>{{item.comment}}</td>
                </tr>
            </table>
            </div>
        </slot>
      </div>

      <div class="modal-body" v-if="modal4">
        <slot name="body">
            <div style="padding: 5px;">
            <h3 style="color: #ff9b9b; margin: 0px 0px 10px 0px;">{{msg}}</h3>
            <table>
                <tr>
                    <th> Name </th>
                    <th> On Hand </th>
                    <th> Order Amount </th>
                    <th> Add Details </th>
                    <th> Remove</th>
                </tr>
                <tr v-for="product in orderForm">
                    <td v-if="product.name.length > 1">{{product.name}}</td>
                    <td v-else><input type="text" style="width: 200px; border: solid lightgrey 1px" v-on:change="onCustomChangeCart(product.custom, $event)"/></td>
                    <td v-if="product.onhand"v-bind:style="product.cur > product.onhand? {'color' : '#07ABA1' } : {'color' : '#ff9b9b'}">{{product.cur}} <span style="color: #000">/</span> <span style="color: #000"> {{product.onhand}}</span></td>
                    <td v-else> N/A </td>
                    <td><input type="number" name="amount" min="1" style="width: 50px; border: lightgrey solid 1px;" v-on:input="onChangeCart(product.name, $event)" :placeholder="product.amount"/></td>
                    <td><button style="width: 25px; height: 25px; padding: 2px;" v-on:click.stop.prevent="createDetails(product)">+</button> </td>
                    <td><button style="width: 25px; height: 25px; padding: 2px; background-color: #FF9b9b" v-on:click.stop.prevent="onRemove(product.custom, $event)">x</button></td>
                </tr>
            </table>
            <button v-on:click.stop.prevent="insertProd" style="background-color: #9CDDD9; margin-right: 5px;"> Custom </button>
            <button style="margin-top: 2%; background-color: #07ABA1" v-on:click="updateCart"> Update Cart </button>
            </div>
        </slot>
      </div>

      <div class="modal-body" v-if="modal5">
        <slot name="body">
            <div v-if="customItemFlag">
                <h2> Do you want to add any of the following custom items to your inventory? </h2>
                <table>
                    <tr>
                        <th> Name </th>
                        <th> Yes </th>
                    <tr v-for="(item, i) in customItems" :key="i">
                        <td>{{item.name}}</td>
                        <td><input type="checkbox" :value="item" v-model="customForm"> </td>
                    </tr>
                </table>
                <div style="padding-top: 2%">
                    <button style="margin-right: 5px" v-on:click="fbAddInventory"> Add to Inventory </button>
                    <button v-on:click="cancelAddInventory"> No Need </button>
                </div>
            </div>
            <div v-else>
                <h1> Your order has been successfully registered! </h1>
                <h2> We will contact you if there are any problems</h2>
                <table>
                    <tr>
                        <th> Name </th>
                        <th> Amount </th>
                        <th> Comment </th>
                    <tr v-for="item in this.$store.state.shoppingCart" :key="item.custom">
                        <td>{{item.name}}
                        <td>{{item.amount}}</td>
                        <td>{{item.comment}}</td>
                    </tr>
                </table>
            </div>
        </slot>
      </div>

      <div class="modal-footer" v-if="!modal4 && !modal5">
        <slot name="footer">
          <button v-if="!emergencyFlag" class="modal-default-button" v-on:click="emergencyFlag = 1" style="background-color: #FF9B9B;"> Urgent </button>
          <button v-else class="modal-default-button" v-on:click="emergencyFlag = 0" style="background-color: red;"> Urgent </button>
          <button class="modal-default-button" v-on:click="clearCart" style="background-color: #8CB6D2;"> Clear Cart </button>
          <button class="modal-default-button" v-on:click="editCart" style="background-color: #9CDDD9;"> Edit Cart </button>
          <button class="modal-default-button" v-on:click="submitCart"> Submit </button>
        </slot>
      </div>
    </div>
  </div>
</div>
</transition>

<!---//////////////////////////////////  Add Link to Order Model //////////////////////////////////// -->


<transition name="modal">
<div v-if="modalAdditionalInfo" class="modal-mask">
  <div class="modal-wrapper">
    <div class="modal-container" style="width: 450px; height: 500px; overflow-y: hidden;">
      <div class="modal-header">
        <slot name="header">
            <h2 class="modal-banner" style="width: 100%; font-size: 1; font-size: 1.7em">{{currentItem.name}}</h2>
        </slot>
      </div>

      <div class="modal-body">
        <slot name="body">
            <div class="form-style-7" style="margin: 0">
            <ul>
            <li>
                <label for="comment">Comment</label>
                <input type="text" name="name" maxlength="100" v-on:input="addDetails(currentItem, $event, 'comment')" :placeholder="currentItem.comment">
                <span>Enter a comment</span>
            </li>
            <li>
                <label for="manufacturer">Manufacturer</label>
                <input type="text" name="manufacturer" maxlength="100" v-on:input="addDetails(currentItem, $event, 'manu')" :placeholder="currentItem.manufacturer">
                <span>Enter a manufacturer (eg: OpenSprinklr)</span>
            </li>
            <li>
                <label for="part">Part No.</label>
                <input type="text" name="part" maxlength="100" v-on:input="addDetails(currentItem, $event, 'part')" :placeholder="currentItem.part">
                <span>Enter a part number (if applicable)</span>
            </li>
            <li style="display: block; padding: 9px; border: 1px solid #ddd; margin-bottom: 30px; border-radius: 3px">
                <label for="link">Link</label>
                <textarea name="link" v-on:input="addDetails(currentItem, $event, 'link')" :placeholder="currentItem.productLink"></textarea>
                <span>Enter a link for the product</span>
            </li>
            <!-- <li>
                <input type="submit" value="Send This" >
            </li> -->
            <button class="modal-default-button" style="width: 65px"  v-on:click="modalAdditionalInfo = 0"> Update </button>
            <button class="modal-default-button" style= "width: 65px; background-color: #8CB6D2;" v-on:click="modalAdditionalInfo = 0"> Cancel </button>
            </ul>
            </div>
        </slot>
      </div>

      <div class="modal-footer">
        <slot name="footer">
        </slot>
      </div>
    </div>
  </div>
</div>
</transition>

<!--  ////////////////////////////// End of the HTML elements ////////////////////////////// -->

</div>

</template>

<script>
    import {RotateSquare2} from 'vue-loading-spinner'
    export default {
        created() {
            // this.$store.dispatch('getUserInfo').then(res => {
            //     this.$store.state.userProfile = res
            // })
            this.getADSInventory()
            // this.loadFolders()
        },
        mounted() {
            setTimeout(() => this.flag1 = 1, 3000);
            this.$store.dispatch('getUserInfo').then(res => {
                this.$store.state.userProfile = res
            })
        },
        components: {
          RotateSquare2
        },
        data () {
            return {
                facility: this.$store.state.facility,
                posts: [],
                error: [],
                filter: [],
                ADSstock: [],
                allStock: [],
                stock: [],
                restock: [],
                orderForm: [],
                order: [],
                folderIDs: [],
                subFolderIDs: [],
                phasesFolders: [],
                facilityFolders: [],
                additionalLocations: [],
                dupAlert: [],
                testTable: {},
                stockTable: {},
                shoppingCart: this.$store.state.shoppingCart,
                dupCheck: {},
                //////
                searchTable: {},
                searchNames: [],
                searchTableADS: {},
                searchNamesADS: [],
                adsView: 1,
                //////
                accProfile: this.$store.state.userProfile,
                modalAdditionalInfo: 0,
                modalAddLink: 0,
                currentItem: {},
                modalAdd: 0,
                card: 1,
                adsFlag : 0,
                //////// ** 9/23 ** /////////
                customItemFlag: 0,
                customItems: [],
                customForm: [],
                emergencyFlag: 0,
                //////////////** 10/13 **//////
                prevOrder: {},
                cancelForm: [],
                doubleAlert: 0,
                cancelDict: {},
                /////////
                i: 0,
                flag1: 0,
                flag2: 0,
                flag3: 0,
                flag4: 1,
                flag5: 0,
                alertDup: 0,
                showOrder: 0,
                custom: 0,
                showModal: 0,
                modal1: 1,
                modal2: 0,
                modal3: 0,
                modal4: 0,
                modal5: 0,
                editFlag: 0,
                finalSub: 0,
                name: '',
                notes: '',
                quantity: '',
                minimum: '',
                edit_id: '',
                search: '',
                searchADS: '',
                msg: '',
            }
        },
        methods: {
              getADSInventory() {
                this.ADSstock = []
                this.searchNamesADS = []
                this.$store.dispatch('getADSInventory').then(res => {
                  for(let i = 0; i < res.length; i++){
                    if(res[i]){
                      this.searchTableADS[(res[i].name.toLowerCase())] = res[i]
                      this.searchNamesADS.push(res[i].name.toLowerCase())
                      this.ADSstock.push(res[i])
                      this.stockTable[res[i].name] = (res[i].necessary_amount + 1) - res[i].quantity
                    }
                  }
                  this.stock = this.ADSstock.sort(this.sortItems)
                  this.adsFlag = 1
                  this.getInventory()
                })
              },
              getInventory() {
                this.$store.dispatch('getUserInfo').then(res => {
                    this.$store.state.userProfile = res
                    this.allStock = []
                    this.searchTable = {}
                    this.searchNames = []
                    this.stockTable = {}
      
                    this.$store.dispatch('getInventory').then(res => {
                      for(let i = 0; i < res.length; i++){
                        if(res[i]){
                          this.searchTable[(res[i].name.toLowerCase())] = res[i]
                          this.searchNames.push(res[i].name.toLowerCase())
                          this.allStock.push(res[i])
                          this.stockTable[res[i].name] = (res[i].necessary_amount + 1) - res[i].quantity
                        }
                      }
                      this.allStock = this.allStock.sort(this.sortItems)
                    })
                })
              },
              filterRecommended() {
                this.stock = []
                this.stock = this.allStock
                this.restock = this.stock.filter(item => item.necessary_amount > item.quantity)
                this.restock.sort(this.sortItems)
                this.stock = this.restock
                this.adsView = 0
                this.search = ''
              },
              filterAll(){
                this.stock = []
                this.adsView = 0
                this.stock = this.allStock.sort(this.sortItems)
                this.search = ''
              },
              filterADS(){
                setTimeout(() => this.flag1 = 1, 1500);
                this.stock = []
                this.flag1 = 0
                this.adsView = 1
                if(!this.adsFlag){
                    this.getADSInventory()
                }
                this.stock = this.ADSstock
                this.search = ''
              },
              sortItems(a,b){
                const itemA = a.name.toUpperCase()
                const itemB = b.name.toUpperCase()

                let comparison = 0
                if(itemA > itemB){
                  comparison = 1;
                } else if(itemA < itemB) {
                  comparison = -1
                }
                return comparison
              },
              generateHash(el, arr){
                let newObject = {'name': '', 
                                 'amount' : '',  
                                 'cur' : '', 
                                 'onhand' : '', 
                                 'comment' : '', 
                                 'custom' : '',
                                 'isCustom' : '', 
                                 'ADSitem' : '',
                                 'status' : '',
                                 'adsComment' : '',
                                 'productLink' : '',
                                 'manufacturer' : '',
                                 'part' : '',
                                 'orderDate': '',
                                 'orderedBy' : '',
                                 }
                    newObject['name'] = el.name
                    newObject['cur'] = el.cur
                    newObject['onhand'] = el.onhand
                    newObject['isCustom'] = el.isCustom
                    newObject['ADSitem'] = el.ADSitem
                    newObject['custom'] = el.custom
                    newObject['status'] = el.status
                    newObject['productLink'] = el.productLink
                    newObject['manufacturer'] = el.manufacturer
                    newObject['part'] = el.partNo
                    newObject['orderDate'] = el.orderDate
                    newObject['orderedBy'] = el.orderedBy
                    arr.push(newObject)
              },
              checkForm() {
                this.$store.dispatch('getUserInfo').then(res => {
                        this.$store.state.userProfile = res
                        this.accProfile = res
                        this.flag1 = 0
                        this.flag2 = 1
                        let temp = []
                        for(let i = 0; i < this.orderForm.length; i++) {
                            let newObject = {'name' : this.orderForm[i].name,
                                            'cur' : this.orderForm[i].quantity,
                                            'onhand': this.orderForm[i].necessary_amount,
                                            'quantity' : this.orderForm[i].quantity,
                                            'isCustom' : this.orderForm[i].isCustom,
                                            'ADSitem' : this.orderForm[i].ADSitem,
                                            'custom' : this.createRand(),
                                            'status' : 0,
                                            'adsComment' : '',
                                            'productLink' : this.orderForm[i].productLink,
                                            'manufacturer' : this.orderForm[i].manufacturer,
                                            'part' : this.orderForm[i].partNo,
                                            'orderDate' : new Date(),
                                            'orderedBy' : this.accProfile[0].name
                                            }
                            temp.push(newObject)
                        }
                        this.orderForm = temp
                        this.orderForm.forEach(el =>
                            this.generateHash(el, this.order)
                        );
                    })
              },
              cancelOrderForm(){
                  this.flag2 = 0
                  this.flag1 = 1
                  this.orderForm =[]
              },
              createRand() {
                  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
              },
              insertProd(e){
                  e.preventDefault()
                  this.$store.dispatch('getUserInfo').then(res => {
                      this.$store.state.userProfile = res
                      this.accProfile = res
                      let newObject = {'name' : '', 
                                   'amount' : '', 
                                   'cur': '', 
                                   'onhand' : '', 
                                   'comment' : '', 
                                   'isCustom' : 1,
                                   'ADSitem' : 0,
                                   'custom' : this.createRand(), 
                                   'status' : 0, 
                                   'adsComment' : '',
                                   'productLink' : '',
                                   'manufacturer' : '',
                                   'part' : '',
                                   'orderDate' : new Date(),
                                   'orderedBy': this.accProfile[0].name}
                      this.orderForm.push(newObject)
                      this.order.push(newObject)
                  })
              },
              onChange(str, event){
                  if(event.target.value < 1){
                      this.msg = "A invalid amount has been typed for " + str
                  }
                  else {
                  this.msg = ''
                      for (let i = 0; i < this.order.length; i++){
                            if(this.order[i].name === str){
                              this.order[i].amount = event.target.value
                          }
                      }
                  }
              },
              onTextChange(str, event){
                  for (let i = 0; i < this.order.length; i++){
                        if(this.order[i].name === str){
                          this.order[i].comment = event.target.value
                      }
                  }
              },
              onCustomChange(id, event) {
                  event.preventDefault()
                  console.log("item id is: " + id)
                  for (let i = 0; i < this.order.length; i++) {
                      if(id === this.order[i].custom){
                            this.order[i].name = event.target.value
                      }
                  }
              },
              onRemove(id) {
                console.log(this.orderForm)
                console.log("firing from on onRemove - id is: " + id)
                let newView = []
                for(let i = 0; i < this.orderForm.length; i++){
                    if(this.orderForm[i].custom != id) {
                        newView.push(this.orderForm[i])
                    }
                }

                this.orderForm = newView

                let output = []
                for(let j = 0; j < this.order.length; j++){
                    console.log(id)
                    if(this.order[j].custom != id) {
                        output.push(this.order[j])
                    }
                }
                this.order = output
              },
              createDetails(item){
                console.log('what is happening: ' + item)
                console.log(item)
                this.modalAdditionalInfo = 1
                this.currentItem = item
                console.log('add a link to the current order row for: ' + item.custom)
              },
              addDetails(item, event, subject){
                console.log("Item is: " + item)
                console.log(item);
                if(this.order.length < 1){
                  if(this.$store.state.shoppingCart.length < 1){
                    this.order = []
                  } else {
                  this.order = this.$store.state.shoppingCart
                  }
                }
                if (subject ===  'link'){
                    for(let i = 0; i < this.order.length; i++) {
                        if(item.custom === this.order[i].custom) {
                            this.order[i].productLink = event.target.value
                            this.currentItem.productLink = event.target.value
                        }
                    }
                } else if (subject === 'manu'){
                    for(let i = 0; i < this.order.length; i++) {
                        if(item.custom === this.order[i].custom) {
                            this.order[i].manufacturer = event.target.value
                            this.currentItem.manufacturer = event.target.value
                        }
                    }
                } else if (subject === 'part'){
                    for(let i = 0; i < this.order.length; i++) {
                        if(item.custom === this.order[i].custom) {
                            this.order[i].part = event.target.value
                            this.currentItem.part = event.target.value
                        }
                    }                  
                } else if (subject === 'comment'){
                    for(let i = 0; i < this.order.length; i++) {
                        if(item.custom === this.order[i].custom) {
                            this.order[i].comment = event.target.value
                            this.currentItem.comment = event.target.value
                        }
                    }                  
                }
              },
//////////////////////////////////////////// Search Bar Functions //////////////////////////////////////////////
              confirmItems(e){
                console.log(event.target.value)
                //console.log(this.searchTable)
                let term = event.target.value
                // let result = this.searchNames.filter(word => word.includes(term));
                
                const result = this.match(term.toLowerCase()) 

                let temp = []
                if(result.length){
                  //go through the searchTable find the right items and store for display
                  for(let i = 0; i < result.length; i++){
                      let cur = result[i]
                      if(this.searchTable[cur]){
                          temp.push(this.searchTable[cur])
                      }
                  }
                  this.stock = [...new Set (temp.sort(this.sortItems))]
                }
              },
              confirmItemsADS(e){
                console.log(event.target.value)
                //console.log(this.searchTable)
                let term = event.target.value
                // let result = this.searchNames.filter(word => word.includes(term));
                
                const result = this.matchADS(term.toLowerCase()) 

                let temp = []
                if(result.length){
                  //go through the searchTable find the right items and store for display
                  for(let i = 0; i < result.length; i++){
                      let cur = result[i]
                      if(this.searchTableADS[cur]){
                          temp.push(this.searchTableADS[cur])
                      }
                  }
                  this.stock = [...new Set (temp.sort(this.sortItems))]
                }
              },
              match(s){
                  const p = Array.from(s).reduce((a, v, i) => `${a}[^${s.substr(i)}]*?${v}`, '');
                  const re = RegExp(p);
  
                  return this.searchNames.filter(v => v.match(re));
              },
              matchADS(s){
                  const p = Array.from(s).reduce((a, v, i) => `${a}[^${s.substr(i)}]*?${v}`, '');
                  const re = RegExp(p);
  
                  return this.searchNamesADS.filter(v => v.match(re));
              },
//////////////////////////////////////////// Add + Modal Functions ////////////////////////////////////////////
              onAdd() {
                console.log("on add")
                this.modalAdd = 1
                this.name = ''
                this.search = ''
                this.quantity = 0
                this.minimum = 1
                this.notes = ''
              },
              addItem(){
                  let body = {
                    'name': this.name,
                    'notes': this.notes,
                    'quantity': this.quantity,
                    'necessary_amount': this.minimum
                  };

                  this.$store.dispatch('createItem', body).then(res => {
                      console.log(res)
                      this.modalAdd = 0
                      this.stock = []
                      this.search = ''
                      this.quantity = 0
                      this.minimum = 0
                      this.getInventory()
                  })
              },
//////////////////////////////////////////// Edit + Modal Functions ////////////////////////////////////////////
              onEdit(item) {
                console.log("in edit item")
                console.log(item)
                this.showModal = 1
                console.log(item)
                console.log("the id of the product is: " + item.id)
                this.edit_id = item.FID
                this.name = item.name
                this.notes = item.notes
                this.quantity = item.quantity
                this.minimum = item.necessary_amount
              },
              editItem() {
                let body = {
                  'name': this.name,
                  'notes': this.notes,
                  'quantity': this.quantity,
                  'necessary_amount' : this.minimum,
                  'FID' : this.edit_id
                };
                this.$store.dispatch('updateItem', body).then(res => {
                  console.log(res)
                  this.showModal = 0
                  setTimeout(() => {
                    this.modal1 = 1
                    this.stock = []
                    this.search = ''
                    this.getInventory()
                  }, 1000);
                })
            },
            returnPage(){
              this.showModal = 0
              setTimeout(() => {
                this.modal1 = 1
                this.stock = []
                this.search = ''
                this.getInventory()
              })
            },
            cancelEdit(){
                this.showModal = 0
                this.name = ''
                this.notes = ''
                this.quantity = ''
                this.minimum = ''
                this.edit_id = ''
            },
            removeItem() {
                  let id = this.edit_id
                  console.log("id is: " + id)
                  this.$store.dispatch('deleteItem', id).then(res => {
                    console.log(res)
                    this.returnPage()
                  })
            },
//////////////////////////////////////////// Shopping Cart Functions ////////////////////////////////////////////
            showCart(){
                console.log("View Cart")
                console.log("this is from session storage")
                console.log(sessionStorage.getItem("order"))
                console.log(this.$store.state.shoppingCart === null)
                console.log(this.$store.state.shoppingCart)
                let refreshCheck = 1
                if(this.$store.state.shoppingCart === null || this.$store.state.shoppingCart.length < 1){
                  console.log("it's null")
                  refreshCheck = 0
                }
                if(!refreshCheck){
                  console.log("Nothing in cart!")
                  this.$store.state.shoppingCart = JSON.parse(decodeURI(sessionStorage.getItem("order")))
                }
                this.modal2 = 1
                this.modal3 = 1
                let dict = {}
                this.$store.dispatch('getLastOrder').then(res => {
                  // console.log(res)
                  // this.prevOrder =JSON.parse(decodeURI(res[0].orderInfo))
                  // console.log(this.prevOrder)
                  let result = JSON.parse(decodeURI(res[0].orderInfo))
                  for(let i = 0; i < result.length; i++){
                    let cur = result[i]
                    dict[cur.name] = cur
                  }
                  // console.log(dict)
                  if(this.checkPrev(dict)){
                    this.doubleAlert = 1
                  }
                })
            },
            checkPrev(order){
              let output = []
              if(this.$store.state.shoppingCart){
                  for(let i = 0; i < this.$store.state.shoppingCart.length; i++){
                      let cur = this.$store.state.shoppingCart[i]
                      if(order[cur.name]){
                        output.push(order[cur.name])
                      }
                  }
                  if(output.length >= 1) {
                    this.prevOrder = output
                    return 1
                  } else {
                    return 0
                  }
              }
            },
            removeFromOrder(){
              console.log("in remove from order")
              // console.log(this.prevOrder)
              // console.log(this.shoppingCart)
              // console.log(this.cancelForm)
              let actFlag = 0
             
              if(this.cancelForm.length >= 1){
                console.log(this.cancelForm)
                for(let i = 0; i < this.cancelForm.length; i++){
                    this.cancelDict[this.cancelForm[i].name] = this.cancelForm[i]
                    actFlag = 1
                }
              }
              // console.log(this.cancelDict)
              let output = []
              if(actFlag){
                for(let i = 0; i < this.$store.state.shoppingCart.length; i++){
                  let cur = this.$store.state.shoppingCart[i]
                  if(!this.cancelDict[cur.name]){
                    output.push(cur)
                  }
                }
                this.$store.state.shoppingCart = output
                this.doubleAlert = 0
              } else {
                console.log("nothing has been selected")
              }
            },
            closeCart(){
                if(this.finalSub){
                  this.clearCart()
                  this.finalSub = 0
                }
                this.modal2 = 0
                this.modal3 = 0
                this.modal4 = 0
                this.modal5 = 0
                this.orderForm = []
                this.editFlag = 0
            },
            editCart(){
                this.modal4 = 1
                this.modal3 = 0
                this.modal5 = 0
                let curCart = []
                if(!this.editFlag){
                    this.editFlag = 1
                    if(this.$store.state.shoppingCart){
                        curCart = this.$store.state.shoppingCart
                    }
                    for(let i = 0; i < curCart.length; i++){
                       this.orderForm.push(curCart[i])
                    }
                }
                // console.log(this.orderForm)
            },
            clearCart(){
                this.$store.commit('removeItems', this.$store.state.shoppingCart.length)
                sessionStorage.clear()
                this.modal2 = 0
                this.modal3 = 0
                this.modal4 = 0
                this.msg = ''
                this.dupCheck = {}
            },
            updateCart(){
                let commit = 1
                let curCart = []
                if(this.$store.state.shoppingCart){
                  curCart = this.$store.state.shoppingCart
                }
                let missing = []
                // this.$store.commit('removeItems', curCart.length)
                // let order = this.$store.state.shoppingCart
                // console.log('order: ' + order)
                for(let i = 0; i < this.orderForm.length; i++){
                    let cur = this.orderForm[i]
                    console.log(cur)
                    if(!cur.amount){
                      missing.push(cur.name)
                      commit = 0
                      this.msg = "Please insert an amount for: " + missing
                      break
                    } 
                    // else if (cur.isCustom && cur.comment.length < 3){
                    //   missing.push(cur.name)
                    //   commit = 0
                    //   this.msg = "Please include a description in the comments (link, details, etc.) for: " + missing
                    //   break
                    // }
                }
                this.$store.state.shoppingCart = []
                if (commit){
                  for(let j = 0; j < this.orderForm.length;j++){
                      this.$store.commit('addItems', this.orderForm[j])
                  }
                  let body = ''
                  body = JSON.stringify(this.$store.state.shoppingCart)
                  body = encodeURI(body)
                  sessionStorage.clear()
                  sessionStorage.setItem("order", body);
                  this.modal3 = 1
                  this.modal4 = 0
                  this.orderForm = []
                  this.editFlag = 0
                  console.log(curCart)
                } 
            },
            submitCart(e){
                e.preventDefault()
                this.modal5 = 1
                this.modal3 = 0
                console.log("Flags are: " + this.modal2 + this.modal3 + this.modal4)
                this.handleSubmit(e)
                // this.$store.state.shoppingCart = []
                this.finalSub = 1
            },
            addToCart(){
                this.dupAlert = []
                this.alertDup = 0
                let commit = 1
                let missing = []
                let curCart = []
                if(this.$store.state.shoppingCart === null || this.$store.state.shoppingCart.length < 1){
                  console.log("it's null")
                } else {
                    curCart = this.$store.state.shoppingCart
                }
                for (let i = 0; i < curCart.length;i++){
                    if(!this.dupCheck[curCart[i].name]){
                      this.dupCheck[curCart[i].name] = true
                    }
                }

                // scan through and make sure there aren't any items that have not amounts attached
                for(let i = 0; i < this.order.length; i++){
                    if(!this.order[i].amount){
                      missing.push(this.order[i].name)
                      commit = 0
                      this.msg = "Please insert an amount for: " + missing
                      break
                    } 
                    // else if (this.order[i].isCustom && this.order[i].comment.length < 3){
                    //   missing.push(this.order[i].name)
                    //   commit = 0
                    //   this.msg = "Please include a description in the comments (link, details, etc.) for: " + missing
                    //   break
                    // }
                }
                if(!commit){
                    console.log("Add to cart did not go through")
                }
                if(commit){
                    for(let i = 0; i < this.order.length;i++){
                        if(!this.dupCheck[this.order[i].name]){
                            this.$store.commit('addItems', this.order[i])
                            this.dupCheck[this.order[i].name] = true
                        }
                        else {
                            this.dupAlert.push(this.order[i].name)
                            this.alertDup = 1
                        }
                    }
                    this.flag2 = 0
                    this.flag5 = 1
                    this.orderForm = []
                    this.order = []
                    this.msg = ''
                    let body = '';
                    body = JSON.stringify(this.$store.state.shoppingCart)
                    body = encodeURI(body)
                    sessionStorage.setItem("order", body);
                    setTimeout(() => this.flag1 = 1 ,  500);
                }
            },
            // Submit order form
            handleSubmit(e){
              e.preventDefault()
              let order = this.$store.state.shoppingCart
              let output = {}
              if(this.emergencyFlag){
                // mark that it is an emergency run
                output.status = -1
              } else {
                output.status = 0
              }
              output.order = this.$store.state.shoppingCart
              // console.log(order)
              console.log("In handle submit - this is the order info")
              console.log(order)
              for(let i = 0; i < order.length; i++){
                  if(order[i].isCustom){
                    if(!this.searchTable[order[i].name.toLowerCase()]){
                      this.customItems.push(order[i])
                      this.customItemFlag = 1
                    } else {
                      console.log("this item is already in the inventory: " + order[i])
                    }
                  }
                  
                  if(order[i].ADSitem){
                    if(!this.searchTable[order[i].name.toLowerCase()]){
                      this.customItems.push(order[i])
                      this.customItemFlag = 1
                    } else {
                      console.log("this item is already in the inventory: " + order[i])
                    }
                  }
              }
              console.log(this.customItems)
              // this.$store.commit('removeItems', this.$store.state.shoppingCart.length)
              // sessionStorage.clear()
              // console.log(custom)
              ////////////////// Add All Custom Items to Folder for Adding to ADSInventory ////////////////////
              this.$store.dispatch('logOrder', output).then(res => {
              // add custom items to inventory
                  this.$store.dispatch('storeCustomItems', this.customItems)
                  // this.$store.commit('removeItems', this.$store.state.shoppingCart.length)
                  // sessionStorage.clear()
              })
            },
            resetCartView() {
                this.flag1 = 1
                this.dupAlert = []
                this.alertDup = 0
            },
            onChangeCart(str, event){
                  if(event.target.value < 1){
                    this.msg = "Please input a valid amount " + str
                  }
                  else {
                    this.msg = ''
                    for (let i = 0; i < this.orderForm.length; i++){
                          if(this.orderForm[i].name === str){
                            this.orderForm[i].amount = event.target.value
                        }
                    }
                }
            },
            onTextChangeCart(str, event){
                for (let i = 0; i < this.orderForm.length; i++){
                      if(this.orderForm[i].name === str){
                        this.orderForm[i].comment = event.target.value
                    }
                }
            },
            onCustomChangeCart(id, event) {
                event.preventDefault()
                console.log("item id is: " + id)
                for (let i = 0; i < this.orderForm.length; i++) {
                    if(id === this.orderForm[i].custom){
                          this.orderForm[i].name = event.target.value
                    }
                }
            },
/////////////////////////////////////////////////////////// Wizard Functions /////////////////////////////////////////////////
            fbAddInventory(){
                console.log("inside fbAddInventory")
                // console.log(this.customForm)
                let temp = []
                temp = this.customForm
                console.log(temp)
                this.$store.dispatch('createCustom', temp).then( res => {
                  this.closeCart()
                  this.getInventory()
                })
            },
            cancelAddInventory(){
              this.customForm = ''
              this.closeCart()
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    table, th, td {
      border: 1px solid #DFDFDF;
      padding: 2px;
    }
    table {
        border-collapse: collapse;
        margin-left:auto;
        margin-right:auto;
    }  

    ul {
      list-style-type: square;
    }

    h3 {
      margin: 40px 0 0;
    }
    ul {
      list-style-type: none;
      padding: 0;
    }
    li {
      display: inline-block;
      margin: 0 10px;
    }
    a {
      color: #07ABA1;
      font-size: 1.5em;
    }
    button {
      padding: 1%;
      font-size: 1em;
      background-color: #07ABA1;
      color: #FFF;
      border-radius: 2px;
      border: none;
      cursor: pointer;
      outline: none;
      box-shadow: none;
    }
    .toolButton {
      width: 50px; 
      height: 50px; 
      margin: 1%; 
      margin-top: 10%;
      cursor: pointer;
      border-radius: 50%;
      color: #FFF;
      font-size: 1.5em;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .nav {
      padding-left: 3%;
      height: 1vh;
      width: 100vw;
      display: flex;
      align-items: center;
      justify-content: left;
    }

    .navlink {
      padding-right: 25px;
      font-size: 1.5em;
      text-decoration: none;
    }
    .photo {
      width: 75px;
      height: 75px;
      object-fit: cover;
    }

    .test {
      margin: 0px;
      padding: 0px;
    }

    h1, h2 {
      font-weight: normal;
    }
    .cardBody {
      display: grid;
      grid-template-columns: 250px 250px 250px 250px;
      grid-gap: 10px;
      padding: 1%;
      overflow-y: scroll;
      height: 400px;
    }
    .card {
      background-color: lightgrey;
      border-radius: 5px;
      width: 250px;
      height: 200px;
      display: flex;
      flex-direction: column;
    }
    .tool:hover .tooltiptext {
      visibility: visible;
    }
    .tooltiptext {
        visibility: hidden;
        background-color:#FFF;
        color: #000;
        text-align: left;
        align-self: center;
        border-radius: 6px;
        padding: 5px 0;
        padding-left: 20px;
        margin-top: 10%;
        width: 140px;
        /* position: relative;
        z-index: 5;
        top: -145px;
        left: 105%; */
    }

    .icon {
      width: 75px; 
      height: 75px; 
      border-radius: 50%;
      background-size: contain;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.2);
    }
    .iconL {
      width: 150px; 
      height: 150px; 
      border-radius: 50%;
      background-size: contain;
      box-shadow: 0px 0px 5px rgba(0,0,0,0.2);
    }


/* Modal CSS */

    .modal-mask {
      position: fixed;
      z-index: 9998;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: table;
      transition: opacity 0.3s ease;
    }

    .modal-wrapper {
      display: table-cell;
      vertical-align: middle;
    }

    .modal-container {
      width: 1000px;
      height: 500px;
      margin: 0px auto;
      background-color: #fff;
      border-radius: 2px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
      transition: all 0.3s ease;
      overflow-y: scroll;
      padding-bottom: 2%;
    }

    .modal-header h3 {
      margin-top: 0;
      color: #42b983;
    }

    .modal-body {
      margin: 20px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .modal-default-button {
      margin: 5px;
    }
    .modal-banner {
      margin: 0px;
      padding: 0px; 
      background-color: #07ABA1; 
      width: 100%;
      font-size: 2em;
      color: #FFF;

    }
    .cancelButton {
      background-color: #07ABA1;
      color: #FFF; 
      float: left; 
      padding-right: 2%;
      font-size: 1.4em;
      font-weight: 1200;
      border-radius: 0px;
    }

    /* Transition CSS */

    .fade-enter-active, .fade-leave-active {
      transition-property: opacity;
      transition-duration: .25s;
    }

    .fade-enter-active {
      transition-delay: .25s;
    }

    .fade-enter, .fade-leave-active {
      opacity: 0;
    }

    .modal-enter {
      opacity: 0;
    }       

    .modal-leave-active {
      opacity: 0;
    }

    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
/*                form test                       */
    .form-style-7{
      max-width:400px;
      margin:50px auto;
      background:#fff;
      border-radius:2px;
      padding:20px;
    }
    .form-style-7 h1{
      display: block;
      text-align: center;
      padding: 0;
      margin: 0px 0px 20px 0px;
      color: #5C5C5C;
      font-size:x-large;
    }
    .form-style-7 ul{
      list-style:none;
      padding:0;
      margin:0;	
    }
    .form-style-7 li{
      display: block;
      padding: 9px;
      border:1px solid #DDDDDD;
      margin-bottom: 30px;
      border-radius: 3px;
    }
    .form-style-7 li:last-child{
      border:none;
      margin-bottom: 0px;
      text-align: center;
    }
    .form-style-7 li > label{
      display: block;
      float: left;
      margin-top: -19px;
      background: #FFFFFF;
      height: 14px;
      padding: 2px 5px 2px 5px;
      color: #B9B9B9;
      font-size: 14px;
      overflow: hidden;
    }
    .form-style-7 input[type="text"],
    .form-style-7 input[type="date"],
    .form-style-7 input[type="datetime"],
    .form-style-7 input[type="email"],
    .form-style-7 input[type="number"],
    .form-style-7 input[type="search"],
    .form-style-7 input[type="time"],
    .form-style-7 input[type="url"],
    .form-style-7 input[type="password"],
    .form-style-7 textarea,
    .form-style-7 select 
    {
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      width: 100%;
      display: block;
      outline: none;
      border: none;
      height: 25px;
      line-height: 25px;
      font-size: 16px;
      padding: 0;
    }
    .form-style-7 input[type="text"]:focus,
    .form-style-7 input[type="date"]:focus,
    .form-style-7 input[type="datetime"]:focus,
    .form-style-7 input[type="email"]:focus,
    .form-style-7 input[type="number"]:focus,
    .form-style-7 input[type="search"]:focus,
    .form-style-7 input[type="time"]:focus,
    .form-style-7 input[type="url"]:focus,
    .form-style-7 input[type="password"]:focus,
    .form-style-7 textarea:focus,
    .form-style-7 select:focus 
    {
    }
    .form-style-7 li > span{
      background: #F3F3F3;
      display: block;
      padding: 3px;
      margin: 0 -9px -9px -9px;
      text-align: center;
      color: #C0C0C0;
      font-size: 11px;
    }
    .form-style-7 textarea{
      resize:none;
    }
    .form-style-7 input[type="submit"],
    .form-style-7 input[type="button"]{
      background: #2471FF;
      border: none;
      padding: 10px 20px 10px 20px;
      border-bottom: 3px solid #5994FF;
      border-radius: 3px;
      color: #D2E2FF;
    }
    .form-style-7 input[type="submit"]:hover,
    .form-style-7 input[type="button"]:hover{
      background: #6B9FFF;
      color:#fff;
    }

</style>
