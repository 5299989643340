<template>
<div style="width: 100%; height: 100%; padding-top: 60px">
<div class="nav">
  <router-link to="/" class="navlink" style="color: #036D69"> Home </router-link>
  <router-link to="/allItems" class="navlink" style="color: #036D69"> Inventory </router-link>
  <a v-if="orderView" v-on:click="returnMain" class="navlink"> Order History </a>
</div>

<!-- Search Bar -->
<div v-if="mainView" style="padding-top: 2%;">
    <!-- <h1 style="margin: 0px; padding: 0px"> Order History </h1> -->

     <div class="table">
      <ul id="horizontal-list">
          <li v-for="item in autoFill"><a style="cursor: pointer" v-on:click="fetchRelatedOrders(item)">{{item}}</a></li>
      </ul>
     </div>

    <div style="display: flex; flex-direction: row; justify-content: center;">
      <input style="width: 300px; margin-top: 1%; padding: 5px;" type="text" v-on:input="findItemOrder($event)">
     </div>
</div>

<!-- Main View -->

<div v-if="mainView" style="padding-top: 1%; border-right: solid 1px lightgrey; border-left: solid 1px lightgrey; padding-bottom: 2%">
  <button class="filterButton" style="background-color: #FF9b9b" v-on:click="filterSearch(0)"> Confirmed </button>
  <button class="filterButton" style="background-color: #FFAD42" v-on:click="filterSearch(1)"> Processed </button>
  <button class="filterButton" style="background-color: #ABCBE2" v-on:click="filterSearch(2)"> Delivered</button> 
  <button class="filterButton"  v-on:click="filterSearch(3)"> All </button>
</div>

<div v-if="mainView" class="displayOrders">
<table>
    <tr>
      <th> Order Date </th>
      <th> Order Label </th>
      <th> Order Status </th>
      <th> View</th>
    </tr>
    <tr v-for="order in orders">
      <td style="color: lightgrey"> {{order.date}}</td>
      <td style="padding-right: 10px; padding-left: 10px"> {{order.orderName}}</td>
      <td v-if="order.status === 0"> Confirmed</td>
      <td v-else-if="order.status === 1" style="color: #FFAD42"> Processed</td>
      <td v-else-if="order.status === 2" style="color: lightgrey"> Delivered</td>
      <td v-else-if="order.status === -1" style="color: red"> Urgent</td>
      <td><button v-on:click="showOrder(order)"> View </button></td>
    </tr>
</table>
</div>

<!-- Order View -->

<transition name="fade">
<div v-if="orderView">
<div class="jumbotron">
      <h1> Order Status</h1>
      <div v-if="orderStatus === 0">
          <h2> Your order has been viewed and is currently being processed </h2>
          <h2>Estimated Delivery Date for: <span style="color: #07ABA1">{{orderETA}}</span></h2>
      </div>
      <div v-else-if="orderStatus === 1">
          <h2> Your order has been processed </h2>
          <h2>Estimated Delivery Date for: {{orderETA}}</h2>
      </div>
      <div v-else-if="orderStatus  === 2">
          <h2 style="color: #07ABA1"> Your order has been delivered </h2>
      </div>
  </div>

  <transition name="slide">
  <div v-if="displayDetails" style="margin-bottom: 2%; box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);">
  <div style="display: flex; flex-direction: row;">
      <h3 style="margin: 0px; padding: 0px; background-color: #9CDDD9; border: solid 2px #9CDDD9; width: 100%">{{displayProdName}}<span v-on:click="displayDetails = 0" style="color: #FFF; float: right; cursor: pointer; padding-right: 2%">X</span> </h3>
  </div>
    <div style="display: flex; flex-direction: row; justify-content: center;">
        <div style="text-align: left; padding: 2%; width: 50%; word-wrap: break-word;">
            <h4 style="margin: 0px; padding: 0px"> Comment: <span style="font-weight: 100">{{displayComment}}</span></h4>
            <h4 style="margin: 0px; padding: 0px"> Product Link: <span style="font-weight: 100"><a v-on:click="redirectLink(displayLink)" style="cursor: pointer">{{displayLink}}</a></span> </h4>
            <h4 style="margin: 0px; padding: 0px"> Ordered By: <span style="font-weight: 100">{{displayUser}}</span></h4>
        </div>
        <div style="text-align: left; padding: 2%; width: 50%; word-wrap: break-word;">
            <h4 style="margin: 0px; padding: 0px"> Manufacturer: <span style="font-weight: 100">{{displayManufacturer}}</span></h4>
            <h4 style="margin: 0px; padding: 0px"> Part No: <span style="font-weight: 100">{{displayPartNo}}</span></h4>
            <h4 style="margin: 0px; padding: 0px"> Ordered On: <span style="font-weight: 100">{{displayDate}}</span></h4>
        </div>
    </div>
        
  </div>
  </transition>

  <div style="width: 100%; height: 225px; overflow-y:auto">
  <table>
      <tr>
          <th> Name </th>
          <th> Amount </th>
          <th> Details </th>
          <th> Status </th>
          <th> Updates </th>
      <tr v-for="item in targetOrder" :key="item.custom">
          <td v-bind:style="item.status == '5'? {'text-decoration' : 'line-through'} : {'text-decoration' : 'none'}">{{item.name}}
          <td v-bind:style="item.status == '5'? {'text-decoration' : 'line-through'} : {'text-decoration' : 'none'}">{{item.amount}}</td>
          <td v-bind:style="item.status == '5'? {'text-decoration' : 'line-through'} : {'text-decoration' : 'none'}" v-if="item.comment || item.additionalComment || item.productLink"><a v-on:click="showDetails(item)" style="cursor: pointer">View</a> </td> 
          <td v-bind:style="item.status == '5'? {'text-decoration' : 'line-through'} : {'text-decoration' : 'none'}" v-else style="color: lightgrey">N/A</td>
          <td v-if="item.status === '0'" style="padding-right: 5px; padding-left: 5px;">Confirmed</td>
          <td v-else-if="item.status === '1'" style="padding-right: 5px; padding-left: 5px"> Ordered</td>
          <td v-else-if="item.status === '2'" style="padding-right: 5px; padding-left: 5px"> Delivered</td>
          <td v-else-if="item.status === '3'" style="padding-right: 5px; padding-left: 5px"> Returned</td> 
          <td v-else-if="item.status === '4'" style="padding-right: 5px; padding-left: 5px"> Back Ordered</td> 
          <td v-else-if="item.status === '5'" style="padding-right: 5px; padding-left: 5px; color: lightgrey"> Canceled</td> 
          <td v-else-if="item.status === '6'" style="padding-right: 5px; padding-left: 5px"> Shipping</td> 
          <td v-else-if="item.status === '7'" style="padding-right: 5px; padding-left: 5px"> At ADS</td> 
          <td v-else-if="item.status === '8'" style="padding-right: 5px; padding-left: 5px"> Pick Up</td> 
          <td v-else-if="item.status === '9'" style="padding-right: 5px; padding-left: 5px"> Partially Received</td> 
          <td v-else style="color: #000">Confirmed</td>
          <td v-bind:style="item.status == '5'? {'text-decoration' : 'line-through'} : {'text-decoration' : 'none'}" v-if="item.adsComment" style="padding-right: 2px; padding-left: 2px; font-size: 0.7em;">{{item.adsComment}}</td>
          <td v-bind:style="item.status == '5'? {'text-decoration' : 'line-through'} : {'text-decoration' : 'none'}" v-else style="color: lightgrey"> N/A </td>
    </tr>
</table>
</div>

<div style="margin-top: 1%">
<button v-if="orderStatus == 0" v-on:click="editScreen" style="width: 75px"> Edit </button>
</div>

</div>
</transition>

<!-- test -->

<transition name="fade">
<div v-if="editView">
<div class="jumbotron">
      <h1> Edit Order</h1>
      <!-- <div v-if="orderStatus === 0">
          <h2> Your order has been viewed and is currently being processed </h2>
          <h2>Estimated Delivery Date for: <span style="color: #07ABA1">{{orderETA}}</span></h2>
      </div>
      <div v-else-if="orderStatus === 1">
          <h2> Your order has been processed </h2>
          <h2>Estimated Delivery Date for: {{orderETA}}</h2>
      </div>
      <div v-else-if="orderStatus  === 2">
          <h2 style="color: #07ABA1"> Your order has been delivered </h2>
      </div> -->
  </div>

  <transition name="slide">
  <div v-if="displayDetails" style="margin-bottom: 2%; box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); width: 90%">
  <div style="display: flex; flex-direction: row">
      <h3 style="margin: 0px; padding: 0px; background-color: #9CDDD9; border: solid 2px #9CDDD9; width: 100%">{{displayProdName}}<span v-on:click="displayDetails = 0" style="color: #FFF; float: right; cursor: pointer; padding-right: 2%">X</span> </h3>
  </div>
    <div style="display: flex; flex-direction: row; justify-content: center;">
        <div style="text-align: left; padding: 2%; width: 50%; word-wrap: break-word;">
            <h4 style="margin: 0px; padding: 0px"> Comment: <span style="font-weight: 100">{{displayComment}}</span></h4>
            <h4 v-bind:style="currentItem.modified && currentItem.productLink ? {'background-color' : 'lightyellow'} : {'background-color' : '#FFF'}" style="margin: 0px; padding: 0px"> Product Link: <span style="font-weight: 100"><a v-on:click="redirectLink(displayLink)" style="cursor: pointer">{{displayLink}}</a></span> </h4>
            <h4 style="margin: 0px; padding: 0px"> Ordered By: <span style="font-weight: 100">{{displayUser}}</span></h4>
            <h4 v-bind:style="currentItem.modified && currentItem.additionalComment ? {'background-color' : 'lightyellow'} : {'background-color' : '#FFF'}" style="margin: 0px; padding: 0px"> Additional Comment: <span style="font-weight: 100">{{currentItem.additionalComment}}</span></h4>
        </div>
        <div style="text-align: left; padding: 2%; width: 50%; word-wrap: break-word;">
            <h4 v-bind:style="currentItem.modified && currentItem.manufacturer ? {'background-color' : 'lightyellow'} : {'background-color' : '#FFF'}" style="margin: 0px; padding: 0px"> Manufacturer: <span style="font-weight: 100">{{displayManufacturer}}</span></h4>
            <h4 v-bind:style="currentItem.modified && currentItem.part ? {'background-color' : 'lightyellow'} : {'background-color' : '#FFF'}" style="margin: 0px; padding: 0px"> Part No: <span style="font-weight: 100">{{displayPartNo}}</span></h4>
            <h4 style="margin: 0px; padding: 0px"> Ordered On: <span style="font-weight: 100">{{displayDate}}</span></h4>
        </div>
    </div>
        
  </div>
  </transition>
  <div style="margin-bottom: 2%">
      <button v-on:click="showDelete" style="width: 105px; margin-right: 1%; background-color: #FF9B9B">  Delete </button>
      <button v-on:click="showUpdate" style="width: 105px; margin-right: 1%"> Resubmit </button>
      <button v-on:click="returnMain" style="width: 105px; background-color: #9CDDD9"> Return</button>
  </div>
   <div style="width: 100%; height: 225px; overflow-y:auto">
  <table>
      <tr>
          <th> Name </th>
          <th> Amount </th>
          <th> Details </th>
          <th> Status </th>
          <th> Updates </th>
          <th> Edit </th>
          <th> Cancel </th>
      <tr v-for="item in targetOrder" :key="item.custom">
          <td v-bind:style="item.modified ? {'background-color' : 'lightyellow'} : {'background-color' : '#FFF'}">{{item.name}}</td>
          <td v-bind:style="item.modified && item.status !== '5'? {'background-color' : 'lightyellow'} : {'background-color' : '#FFF'}">{{item.amount}}</td>
          <td v-bind:style="item.modified && item.status !== '5'? {'background-color' : 'lightyellow'} : {'background-color' : '#FFF'}" v-if="item.comment || item.additionalComment || item.productLink"><a v-on:click="showDetails(item)" style="cursor: pointer">View</a> </td> 
          <td v-else style="color: lightgrey">N/A</td>
          <td v-if="item.status === '0'" style="padding-right: 5px; padding-left: 5px">Confirmed</td>
          <td v-else-if="item.status === '1'" style="padding-right: 5px; padding-left: 5px"> Ordered</td>
          <td v-else-if="item.status === '2'" style="padding-right: 5px; padding-left: 5px"> Delivered</td>
          <td v-else-if="item.status === '3'" style="padding-right: 5px; padding-left: 5px"> Returned</td> 
          <td v-else-if="item.status === '4'" style="padding-right: 5px; padding-left: 5px"> Back Ordered</td> 
          <td v-else-if="item.status === '5'" style="padding-right: 5px; padding-left: 5px"> Cancel</td> 
          <td v-else-if="item.status === '6'" style="padding-right: 5px; padding-left: 5px"> Shipping</td> 
          <td v-else-if="item.status === '7'" style="padding-right: 5px; padding-left: 5px"> At ADS</td> 
          <td v-else-if="item.status === '8'" style="padding-right: 5px; padding-left: 5px"> Pick Up</td> 
          <td v-else-if="item.status === '9'" style="padding-right: 5px; padding-left: 5px"> Partially Received</td> 
          <td v-else style="color: lightgrey">Confirmed</td>
          <td v-if="item.adsComment" style="padding-right: 2px; padding-left: 2px">{{item.adsComment}}</td>
          <td v-else style="color: lightgrey"> N/A </td>
          <td><button v-on:click="editItem(item)" style="background-color: #9CDDD9"><span><img src="~@/assets/edit.png" style="width: 25px; height: 25px; background-size: contain;"/></span></button></td>
          <td><button v-on:click="cancelItem(item)" style="background-color: #FF9B9B"><span><img src="~@/assets/archive.png" style="width: 25px; height: 25px; background-size: contain;"/></span></button></td>
    </tr>
</table>
</div>

</div>
</transition>


<!---//////////////////////////////////  Add Link to Order Model //////////////////////////////////// -->


<transition name="modal">
<div v-if="modalAdditionalInfo" class="modal-mask">
  <div class="modal-wrapper">
    <div class="modal-container" style="width: 550px; height: 650px; overflow-y: auto; display: flex; flex-direction: column; justify-content: center">
      <div class="modal-header">
        <slot name="header">
            <h2 class="modal-banner" style="width: 100%; font-size: 1; font-size: 1.7em">{{currentItem.name}}</h2>
        </slot>
      </div>

      <div class="modal-body" style="align-self:center">
        <slot name="body">
            <div class="form-style-7" style="margin: 0">
            <ul>
            <li>
                <label for="amount">Amount</label>
                <input type="number" name="minimum" maxlength="100" v-on:input="addDetails(currentItem, $event, 'amount')" :placeholder="currentItem.amount">
                <span>Enter new amount</span>
            </li>
            <li>
                <label for="comment">Additional Comment</label>
                <input type="text" name="name" maxlength="100" v-on:input="addDetails(currentItem, $event, 'comment')">{{currentItem.additionalComment}}</input>
                <span>Enter a comment</span>
            </li>
            <li>
                <label for="manufacturer">Manufacturer</label>
                <input type="text" name="manufacturer" maxlength="100" v-on:input="addDetails(currentItem, $event, 'manu')" :placeholder="currentItem.manufacturer">
                <span>Enter a manufacturer (eg: OpenSprinklr)</span>
            </li>
            <li>
                <label for="part">Part No.</label>
                <input type="text" name="part" maxlength="100" v-on:input="addDetails(currentItem, $event, 'part')" :placeholder="currentItem.part">
                <span>Enter a part number (if applicable)</span>
            </li>
            <li style="display: block; padding: 9px; border: 1px solid #ddd; margin-bottom: 30px; border-radius: 3px">
                <label for="link">Link</label>
                <textarea style="height: 100px" name="link" v-on:input="addDetails(currentItem, $event, 'link')" :placeholder="currentItem.productLink"></textarea>
                <span>Enter a link for the product</span>
            </li>
            <!-- <li>
                <input type="submit" value="Send This" >
            </li> -->
            <button class="modal-default-button" style="width: 65px; margin-right: 1%"  v-on:click="modalAdditionalInfo = 0"> Update </button>
            <button class="modal-default-button" style= "width: 65px; background-color: #8CB6D2;" v-on:click="modalAdditionalInfo = 0"> Cancel </button>
            </ul>
            </div>
        </slot>
      </div>

      <div class="modal-footer">
        <slot name="footer">
        </slot>
      </div>
    </div>
  </div>
</div>
</transition>

<!---- Delete Confirmation ---->

<transition name="modal">
<div v-if="modalConfirm" class="modal-mask">
  <div class="modal-wrapper">
    <div class="modal-container" style="width: 450px; height: 300px; overflow-y: hidden;">
      <div class="modal-header">
        <slot name="header">
        <!-- <h1 style="padding: 0; margin: 0; background-color: #8CB6D2; color: #FFF"> Delete Order </h1> -->
        </slot>
      </div>
      <div v-if="deleteFlag"class="modal-body" style="padding: 0; margin: 0;">
        <slot name="body">
           <div class="form-style-7">
                <h2> Are you sure you want to delete this order? </h2>
                <div>
                    <button class="modal-default-button" v-on:click="modalConfirm = 0" style="background-color: rgb(156, 221, 217); margin-right: 2%"> Cancel </button>
                    <button class="modal-default-button" v-on:click="deleteOrder"> Confirm </button>
                </div>
           </div>
        </slot>
      </div>

      <div v-if="updateFlag" class="modal-body" style="padding: 0; margin: 0;">
        <slot name="body">
           <div class="form-style-7">
                <h2> Are you sure you want to update this order? </h2>
                <div>
                    <button class="modal-default-button" v-on:click="modalConfirm = 0" style="background-color: rgb(156, 221, 217); margin-right: 2%"> Cancel </button>
                    <button class="modal-default-button" v-on:click="resubmitOrder"> Confirm </button>
                </div>
           </div>
        </slot>
      </div>

      <div class="modal-footer">
        <slot name="footer">
        </slot>
      </div>
    </div>
  </div>
</div>
</transition>

</div>
</div>
</template>

<script>
  import {RotateSquare2} from 'vue-loading-spinner'
  export default {
      created() {
        this.getData()
      },
      mounted() {
        setTimeout(() => this.mainView = 1, 1000);
                  Object.keys(this.searchTable).reduce(function(accum, key) {
                  accum[key.toLowerCase()] = this.searchTable[key];
                  return accum;
                }, {});
        },
        components: {
          RotateSquare2
        },
        data () {
            return {
                search: {},
                orders: [],
                ordersAll: [],
                targetOrder: [],
                hardcopyOrder: [],
                orderStatus: 0,
                orderETA: '',
                targetOrderName: '',
                itemLink: '',
                displayComment: '',
                displayLink: '',
                displayManufacturer: '',
                displayUser: '',
                displayDate: '',
                displayPartNo: '',
                displayProdName: '',
                displayDetails: 0,
                mainView: 0,
                orderView: 0,
                editView: 0,
                modalAdditionalInfo: 0,
                modalConfirm: 0,
                deleteFlag: 0,
                updateFlag: 0,
                flag2: 0,
                modal1: 0,
                hardcopyFlag: 0,
                searchObjects: [],
                searchNames: [],
                autoFill: [],
                searchTable: {},
                allOrders: {},
                cancelForm: [],
                currentItem: {}
            }
        },
        methods: {
            getData() {
              this.$store.dispatch('getOrders').then(res => {
                this.orders = res
                this.ordersAll = res
                res.forEach(el => {
                    this.searchObjects.push(el)
                    this.allOrders[el.orderName] = el
                })
                this.modifyData()
              })
            },
            modifyData(){
              console.log("in modify data")
              // console.log(this.searchObjects)
                for(let i = 0 ; i < this.searchObjects.length; i++){
                    let cur = JSON.parse(decodeURI(this.searchObjects[i].orderInfo))
                    // console.log("in modifyData")
                    // console.log(cur)
                    if(cur.length > 1){
                      this.grabItems(cur, this.searchObjects[i].orderName)
                    } else {
                      if(cur[0]){
                          if (this.searchTable[cur[0].name]){
                            this.searchTable[cur[0].name].push(this.searchObjects[i].orderName)
                          } else {
                            this.searchTable[cur[0].name] = [this.searchObjects[i].orderName]
                          }
                      }
                    }
                }
                this.searchNames = Object.keys(this.searchTable)
                for(let i = 0; i < this.searchNames.length; i++) {
                  this.searchNames[i] = this.searchNames[i].toLowerCase()
                }
                this.searchTable = Object.keys(this.searchTable).reduce((acc, key) => {
                  acc[key.toLowerCase()] = this.searchTable[key];
                  return acc;
              }, {});
            },
            grabItems(arr, orderName){
                for(let i = 0; i < arr.length; i++) {
                  let cur = arr[i].name
                  if(this.searchTable[cur]) {
                    this.searchTable[cur].push(orderName)
                  } else {
                    this.searchTable[cur] = [orderName]
                  }
                }
            },
            showOrder(order){
                // console.log(order)
                this.mainView = 0
                this.orderView = 1
                this.orderStatus = order.status
                this.targetOrderName = order.orderName
                let newObj = JSON.parse(decodeURI(order.orderInfo))
                // console.log(newObj)
                let seen = {}
                let output = []
                for (let i = 0; i < newObj.length; i++){
                  // console.log(newObj[i])
                  if(!seen[newObj[i].custom]){
                    seen[newObj[i].custom] = 1
                    output.push(newObj[i])
                  }
                }
                this.orderETA = order.eta
                this.targetOrder = output
            },
            showLink(link){
                this.itemLink = link
            },
            redirectLink(link){
                console.log(link)
                window.open(link, "_blank");    
            },
            returnMain(){
                this.orderView = 0
                this.mainView = 1
                this.editView = 0
            },
            closeView(){
                this.orderView = 0
                this.mainView = 1
                this.displayDetails = 0
                this.itemLink = ''
            },
            editScreen(){
                this.mainView = 0
                this.orderView = 0
                this.editView = 1
                let output = []
                for(let i = 0; i < this.targetOrder.length;i++){
                    if(this.targetOrder[i].status != '5'){
                        output.push(this.targetOrder[i])
                    }
                }
                this.targetOrder = output
            },
             //////////////////////////////////////////// Search Bar Functions //////////////////////////////////////////////
            findItemOrder(e){
              let term = event.target.value
              if(term.length > 2){
                  const result = this.match(term) 
                  let temp = []
                  if(result.length){
                    for(let i = 0; i < result.length; i++){
                        let cur = result[i]
                        if(this.searchTable[cur]){
                            temp.push(cur)
                        }
                    }
                    this.autoFill = temp
                  }
              }
            },
            match(s){
                const p = Array.from(s).reduce((a, v, i) => `${a}[^${s.substr(i)}]*?${v}`, '');
                const re = RegExp(p);
                this.searchNames.filter(v => v.match(re));
                console.log("what happens here")
                return this.searchNames.filter(v => v.match(re));
            },
            fetchRelatedOrders(item){
                let fetchItems = this.searchTable[item]
                let temp = []
                for (let i = 0; i < fetchItems.length; i++){
                  let cur = fetchItems[i]
                  temp.push(this.allOrders[cur])
                }
                this.orders = temp
            },
            showDetails(item){
                console.log(item) 
                this.displayDetails = 1
                this.displayComment = item.comment
                this.displayLink = item.productLink
                this.displayManufacturer = item.manufacturer
                this.displayPartNo = item.part
                this.displayProdName = item.name
                this.displayUser = item.orderedBy
                this.displayDate = new Date(item.orderDate)
            },
//////////////////////////////////////////// Edit Function ////////////////////////////////////////////////
            showDelete(){
              this.modalConfirm = 1
              this.deleteFlag = 1
              this.updateFlag = 0
            },
            showUpdate(){
              this.modalConfirm = 1
              this.updateFlag = 1
              this.deleteFlag = 0
            },
            deleteOrder(){
              this.modalConfirm = 1
              let id = this.targetOrderName
              this.$store.dispatch('deleteOrder', id).then( res => {
                if(res){
                  this.modalConfirm = 0
                  this.getData()
                  this.returnMain()
                } else {
                  console.log('order was not deleted')
                }
              })
            },
            editItem(item){
              console.log(item)
              this.modalAdditionalInfo = 1
              this.currentItem = item
            },
            cancelItem(item) {
              this.hardcopyOrder = this.targetOrder
              this.hardcopyFlag = 1
              let output = []
              for(let i = 0; i < this.hardcopyOrder.length; i++){
                if(item.custom === this.hardcopyOrder[i].custom){
                    this.hardcopyOrder[i].status = '5'
                } else {
                  output.push(this.hardcopyOrder[i])
                }
              }
              this.targetOrder = output
            },
            resubmitOrder(){
              let output = [this.targetOrder, this.targetOrderName]
              if(this.hardcopyFlag){
                output[0] = this.hardcopyOrder
              }
              this.$store.dispatch('updateOrder', output).then(res => {
                this.modalConfirm = 0
                this.getData()
                this.returnMain()
              })
            },
            addDetails(item, event, subject){
              console.log("Item is: " + item)
              console.log(item);
              // this.order = this.$store.state.shoppingCart
              console.log(this.targetOrder)
              if (subject ===  'link'){
                  for(let i = 0; i < this.targetOrder.length; i++) {
                      if(item.custom === this.targetOrder[i].custom) {
                          this.targetOrder[i].productLink = event.target.value
                          this.currentItem.productLink = event.target.value
                          this.targetOrder[i].modified = 1
                      }
                  }
              } else if (subject === 'manu'){
                  for(let i = 0; i < this.targetOrder.length; i++) {
                      if(item.custom === this.targetOrder[i].custom) {
                          this.targetOrder[i].manufacturer = event.target.value
                          this.currentItem.manufacturer = event.target.value
                          this.targetOrder[i].modified = 1
                      }
                  }
              } else if (subject === 'part'){
                  for(let i = 0; i < this.targetOrder.length; i++) {
                      if(item.custom === this.targetOrder[i].custom) {
                          this.targetOrder[i].part = event.target.value
                          this.currentItem.part = event.target.value
                          this.targetOrder[i].modified = 1
                      }
                  }                  
              } else if (subject === 'comment'){
                  for(let i = 0; i < this.targetOrder.length; i++) {
                      if(item.custom === this.targetOrder[i].custom) {
                          this.targetOrder[i].additionalComment = event.target.value
                          this.currentItem.additionalComment = event.target.value
                          this.targetOrder[i].modified = 1
                      }
                  }                  
              } else if (subject === 'amount'){
                  for(let i = 0; i < this.targetOrder.length; i++) {
                      if(item.custom === this.targetOrder[i].custom) {
                          this.targetOrder[i].amount = event.target.value
                          this.currentItem.amount = event.target.value
                          this.targetOrder[i].modified = 1
                          console.log(this.targetOrder[i])
                      }
                  }                  
              }
            },
//////////////////////////////////////////// Filter Functions /////////////////////////////////////////////
            filterSearch(query){
              // console.log(facility)
              if(!this.flag2){
                  this.orders = this.orders.filter(order => order.status === query)
                  this.flag2 = 1
              } else {
                  this.orders = this.ordersAll
                  this.orders = this.orders.filter(order => order.status == query)
              }
              if(query === 3){
                  this.orders = this.ordersAll
              }
            },
        },

    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    /* * {
       overflow: scroll;
    } */
    .table {
      display: table;   /* Allow the centering to work */
      margin: 0 auto;
    }
    ul#horizontal-list {
      min-width: 696px;
      list-style: none;
      padding-top: 20px;
    }
    
    ul#horizontal-list li {
      display: inline;
    }
    table, th, td {
      border: 1px solid #DFDFDF;
      padding: 2px;
      font-size: 1.2em;
    }

    table {
        border-collapse: collapse;
        margin-left:auto;
        margin-right:auto;
    }

    h1, h2 {
        font-weight: normal;
    }
    ul {
        list-style-type: none;
        padding: 0;
    }
    li {
        display: inline-block;
        margin: 0 10px;
    }
    a {
        color: #07ABA1;
    }

    button {
      padding: 1%;
      font-size: 1em;
      background-color: #07ABA1;
      color: #FFF;
      border-radius: 2px;
      border: none;
      cursor: pointer;
      outline: none;
      box-shadow: none;
    }

    .nav {
      padding-left: 3%;
      /* height: 1vh;
      width: 100vw; */
      /* display: flex;
      align-items: center; */
      /* justify-content: left; */
    }
    .navlink {
        padding-right: 25px;
        font-size: 1.5em;
        text-decoration: none;
        cursor: pointer;
    }

    .control {
      width: 150px;
      height: 100px;
      background-color: #07ABA1;
      margin: 5px 5px 5px 5px;
      text-decoration : none;
      color: #FFF;
      font-size: 1.5rem;
      cursor: pointer;
    }

    .displayOrders {
      display: flex;
      justify-content: center;
      height: 220px;
      overflow-y: scroll;
      padding-right: 2%;
    }

    .filterButton {
      margin-right: 1%;
    }

/* Modal CSS */

    .modal-mask {
      position: fixed;
      z-index: 9998;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: table;
      transition: opacity 0.3s ease;
    }

    .modal-wrapper {
      display: table-cell;
      vertical-align: middle;
    }

    .modal-container {
      width: 80%;
      height: 80%;
      margin: 0px auto;
      background-color: #fff;
      border-radius: 2px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
      transition: all 0.3s ease;
      /* overflow-y: scroll;
      overflow-x: scroll; */
      min-height:0;
    }

    .modal-header h3 {
      margin-top: 0;
      color: #42b983;
    }

    .modal-body {
      margin: 20px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0% 5% 2% 5%;
      min-height:0;
    }
    .modal-banner {
      margin: 0px;
      padding: 0px; 
      background-color: #07ABA1; 
      width: 98%;
      font-size: 2em;
      color: #FFF;

    }
    .modal-footer {
      display: flex;
      justify-content: center;
    }
    .cancelButton {
      background-color: #07ABA1;
      color: #FFF; 
      float: lef; 
      font-size: 1.4em;
      font-weight: 1200;
      border-radius: 0px;
    }

    /* Transition CSS */

    .fade-enter-active, .fade-leave-active {
      transition-property: opacity;
      transition-duration: .25s;
    }

    .fade-enter-active {
      transition-delay: .25s;
    }

    .fade-enter, .fade-leave-active {
      opacity: 0;
    }

    .modal-enter {
      opacity: 0;
    }       

    .modal-leave-active {
      opacity: 0;
    }

    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
/*                form test                       */
    .form-style-7{
      max-width:400px;
      margin:50px auto;
      background:#fff;
      border-radius:2px;
      padding:20px;
    }
    .form-style-7 h1{
      display: block;
      text-align: center;
      padding: 0;
      margin: 0px 0px 20px 0px;
      color: #5C5C5C;
      font-size:x-large;
    }
    .form-style-7 ul{
      list-style:none;
      padding:0;
      margin:0;	
    }
    .form-style-7 li{
      display: block;
      padding: 9px;
      border:1px solid #DDDDDD;
      margin-bottom: 30px;
      border-radius: 3px;
    }
    .form-style-7 li:last-child{
      border:none;
      margin-bottom: 0px;
      text-align: center;
    }
    .form-style-7 li > label{
      display: block;
      float: left;
      margin-top: -19px;
      background: #FFFFFF;
      height: 14px;
      padding: 2px 5px 2px 5px;
      color: #B9B9B9;
      font-size: 14px;
      overflow: hidden;
    }
    .form-style-7 input[type="text"],
    .form-style-7 input[type="date"],
    .form-style-7 input[type="datetime"],
    .form-style-7 input[type="email"],
    .form-style-7 input[type="number"],
    .form-style-7 input[type="search"],
    .form-style-7 input[type="time"],
    .form-style-7 input[type="url"],
    .form-style-7 input[type="password"],
    .form-style-7 textarea,
    .form-style-7 select 
    {
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      width: 100%;
      display: block;
      outline: none;
      border: none;
      height: 25px;
      line-height: 25px;
      font-size: 16px;
      padding: 0;
    }
    .form-style-7 input[type="text"]:focus,
    .form-style-7 input[type="date"]:focus,
    .form-style-7 input[type="datetime"]:focus,
    .form-style-7 input[type="email"]:focus,
    .form-style-7 input[type="number"]:focus,
    .form-style-7 input[type="search"]:focus,
    .form-style-7 input[type="time"]:focus,
    .form-style-7 input[type="url"]:focus,
    .form-style-7 input[type="password"]:focus,
    .form-style-7 textarea:focus,
    .form-style-7 select:focus 
    {
    }
    .form-style-7 li > span{
      background: #F3F3F3;
      display: block;
      padding: 3px;
      margin: 0 -9px -9px -9px;
      text-align: center;
      color: #C0C0C0;
      font-size: 11px;
    }
    .form-style-7 textarea{
      resize:none;
    }
    .form-style-7 input[type="submit"],
    .form-style-7 input[type="button"]{
      background: #2471FF;
      border: none;
      padding: 10px 20px 10px 20px;
      border-bottom: 3px solid #5994FF;
      border-radius: 3px;
      color: #D2E2FF;
    }
    .form-style-7 input[type="submit"]:hover,
    .form-style-7 input[type="button"]:hover{
      background: #6B9FFF;
      color:#fff;
    }
    
</style>
