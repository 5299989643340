<template>
<div>

<div>
  <div v-if="!scanFlag">
  <div>
      <h2>{{item.name}}</h2>
      <h1 style="font-size: 100px; margin: 0; padding: 0">{{item.quantity}}</h1>
  </div>

  <div style="padding-top: 1%">
      <button v-on:click="scanFlag = 1" style="margin-right: 1%; background-color: #8CB6D2;"> Cancel </button>
      <button v-on:click="addUpdate" style="margin-right: 1%; background-color: #9CDDD9;"> Add </button>
      <button v-on:click="removeUpdate" style="margin-right: 1%; background-color: #ff9b9b;"> Remove </button>
      <button v-on:click="updateItem"> Update </button>
  </div>
  </div>
  <p>
    {{ error }}
  </p>
  <div v-if="scanFlag">
  <qrcode-stream @decode="onDecode" @init="onInit"></qrcode-stream>
  </div>

</div>

<!---//////////////////////////////////  Add Link to Order Model //////////////////////////////////// -->


<transition name="modal">
<div v-if="modalUpdate" class="modal-mask">
  <div class="modal-wrapper">
    <div class="modal-container" style="width: 400px; height: 200px; overflow-y: hidden;">
      <div class="modal-header">
        <slot name="header">
            <h2 class="modal-banner" style="width: 100%; font-size: 1; font-size: 1.7em">+</h2>
        </slot>
      </div>

      <div class="modal-body">
        <slot name="body">
            <div>
                <input type="number" v-model="updateAmount" min="1">
                <br>
                <div style="padding-top: 2%">
                <button v-on:click="modalUpdate = 0"> Cancel </button>
                <button v-on:click="modifyView"> Submit </button>
                </div>
            </div>
        </slot>
      </div>

      <div class="modal-footer">
        <slot name="footer">
        </slot>
      </div>
    </div>
  </div>
</div>
</transition>

</div>
</template>

<script>
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader'

export default {
  components: {
    QrcodeStream,
    QrcodeDropZone,
    QrcodeCapture
  },
  data() {
    return {
      decodedContent: '',
      result: '',
      error: '',
      fid: '',
      scanFlag: 1,
      addFlag: 0,
      subtractFlag: 0,
      modalUpdate: 0,
      item: {
        name: '',
        quantity: 0
      },
      updateAmount: 1,
      test: {
        name: 'Mykos',
        quantity: 3,
        necessary_amount: 1,
        notes: 'For testing QR scanner'
      }
    }
  },
  methods: {
    onDecode (result) {
      if(result){
          this.result = result
          this.fid = result
          this.scanFlag = 0
          this.$store.dispatch('getItem', result).then(res => {
            this.item.name = res.name
            this.item.quantity = res.quantity
            this.item.FID = this.fid
          })
      }
    },
    addUpdate(){
      this.modalUpdate = 1
      this.addFlag = 1
      this.subtractFlag = 0
    },
    removeUpdate(){
      this.modalUpdate = 1
      this.addFlag = 0
      this.subtractFlag = 1
    },
    modifyView(){
      if(this.addFlag){
      this.item.quantity = parseInt(this.item.quantity) + parseInt(this.updateAmount)
      }

      if(this.subtractFlag){
      this.item.quantity = parseInt(this.item.quantity) - parseInt(this.updateAmount)  
      }
      // this.test.quantity += this.updateAmount
      this.modalUpdate = 0 
    },
    updateItem(){
      let output = this.item
      this.$store.dispatch('updateItemQuantity', output).then( res => {
          console.log(res)
          this.scanFlag = 1
      })
    },
    async onInit (promise) {
      try {
        await promise
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = "ERROR: you need to grant camera access permisson"
        } else if (error.name === 'NotFoundError') {
          this.error = "ERROR: no camera on this device"
        } else if (error.name === 'NotSupportedError') {
          this.error = "ERROR: secure context required (HTTPS, localhost)"
        } else if (error.name === 'NotReadableError') {
          this.error = "ERROR: is the camera already in use?"
        } else if (error.name === 'OverconstrainedError') {
          this.error = "ERROR: installed cameras are not suitable"
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = "ERROR: Stream API is not supported in this browser"
        }
      }
    }
  }
}
</script>

<style scoped>

button {
  padding: 1%;
  font-size: 1em;
  background-color: #07ABA1;
  color: #FFF;
  border-radius: 2px;
  border: none;
  cursor: pointer;
  outline: none;
  box-shadow: none;
}

.toolButton {
  width: 50px; 
  height: 50px; 
  margin: 1%; 
  margin-top: 10%;
  cursor: pointer;
  border-radius: 50%;
  color: #FFF;
  font-size: 1.5em;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Modal CSS */

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 1000px;
  height: 500px;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  overflow-y: scroll;
  padding-bottom: 2%;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.modal-default-button {
  margin: 5px;
}
.modal-banner {
  margin: 0px;
  padding: 0px; 
  background-color: #07ABA1; 
  width: 98%;
  font-size: 2em;
  color: #FFF;

}
.cancelButton {
  background-color: #07ABA1;
  color: #FFF; 
  float: left; 
  padding-right: 2%;
  font-size: 1.4em;
  font-weight: 1200;
  border-radius: 0px;
}

/* Transition CSS */

.fade-enter-active, .fade-leave-active {
  transition-property: opacity;
  transition-duration: .25s;
}

.fade-enter-active {
  transition-delay: .25s;
}

.fade-enter, .fade-leave-active {
  opacity: 0;
}

.modal-enter {
  opacity: 0;
}       

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
