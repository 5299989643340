<template>
<div id="main">
<!--- navigation header 20 vph (Main)--->
<div v-if="mainView" style="width: 100%; height: 20%; background-color: #07ABA1; display: flex; flex-direction: row; justify-content: flex-start; padding-top: 10px; padding-bottom: 10px">
  <div style="width: 80%; height: auto">
      <!-- <form action="."> -->
      <div style="padding-left: 1%; display: flex; flex-direction: row; justify-content: center;">
      <input type="search" name="search" style="width: 300px; margin-top: 1%; padding: 10px" v-model="searchADS" v-on:input="confirmItemsADS($event)" placeholder="Search through ADS products">
      </div>
      <!-- </form> -->
  </div>
  <div style="align-self: end; width: 20%; height: auto">
    <div style="cursor: pointer" v-on:click="goToCart(0)">
     <span><img src="~@/assets/cart.png" style="width: 25px; height: 25px; background-size: contain; padding-left: 8%; padding-top: 8%"/></span>
     </div>
  </div>
</div>

<!--- navigation header 20 vph (Cart)--->
<div v-else-if="cartView" style="width: 100%; height: 20%; background-color: #07ABA1; display: flex; flex-direction: row; justify-content: flex-start; padding-top: 10px; padding-bottom: 10px">
  <div style="align-self: center; width: 80%; height: auto;">
    <div style="cursor: pointer; padding-right: 75%" v-on:click="goToMain">
     <span style="color: #FFF">Shop</span>
     </div>
  </div>
  <div style="align-self: end; width: 20%; height: auto">
    <div style="cursor: pointer" v-on:click="submitCart">
     <span style="color: #FFF">Submit</span>
     </div>
  </div>
</div>

<!--- navigation header 20 vph (Cart)--->
<div v-else style="width: 100%; height: 20%; background-color: #07ABA1; display: flex; flex-direction: row; justify-content: flex-start; padding-top: 10px; padding-bottom: 10px">
  <div style="align-self: center; width: 80%; height: auto;">
    <div v-if="viewQuery == 0" style="cursor: pointer; padding-right: 75%" v-on:click="goToMain">
        <span style="color: #FFF">Shop</span>
     </div>
     <div v-else style="cursor: pointer; padding-right: 75%" v-on:click="goToCart">
        <span style="color: #FFF">Cart</span>
     </div>
  </div>
  <div style="align-self: end; width: 20%; height: auto">
    <!-- <div style="cursor: pointer" v-on:click="submitCart">
     <span>Submit</span>
     </div> -->
  </div>
</div>

<transition name="slide">
<div v-if="alertView" style="width: 100%; height: 20%; background-color: #FF9B9B">
    <p style="color: #FFF">{{alertMsg}} <span v-on:click="alertView = 0" style="color: #FFF; float: right; cursor: pointer; padding-right: 2%">X</span></p>
</div>
</transition>

<!----- main body 80 vph -------->
<div v-if="mainView" style="width: 100%; height: 60%">
<div style="width: 100%; height: 625px; overflow-y: scroll;">
  <div v-for="(item, i) in stock" v-bind:key="i" style="border-bottom: 1px solid lightgrey;">
    <div style="display: flex; flex-direction: row; justify-content: flex-start; align-items: center; padding-top: 2%">
      <div style="width: 10%; height: 100%; align-items:center; justify-content: flex-start; padding-left: 1%">
        <span v-if="item.category == 'Equipment' || item.category == 'Fans & Filter'">
          <img src="~@/assets/equipment.png" class="icon"/>
        </span>
        <span v-else-if="item.category == 'Nutrients & Supplements'"  v-on:click="filterSearch(item.category)">
          <img src="~@/assets/fert.png" class="icon"/>
        </span>
        <span v-else-if="item.category == 'Office Supplies'" v-on:click="filterSearch(item.category)">
          <img src="~@/assets/shears.png" class="icon"/>
        </span>
        <span v-else-if="item.category == 'Grow Media & Amendments'" v-on:click="filterSearch(item.category)">
          <img src="~@/assets/grow.png" class="icon"/>
        </span>
        <span v-else-if="item.category == 'Lighting'" v-on:click="filterSearch(item.category)">
          <img src="~@/assets/lighting.png" class="icon"/>
        </span>
        <span v-else-if="item.category == 'Hygiene & Safety'" v-on:click="filterSearch(item.category)">
          <img src="~@/assets/safety.png" class="icon"/>
        </span>
        <span v-else-if="item.category == 'Bugs'" v-on:click="filterSearch(item.category)">
          <img src="~@/assets/bug.png" class="icon"/>
        </span>
        <span v-else-if="item.category == 'Irrigation' || item.category == 'Water Pump & Air Pump'" v-on:click="filterSearch(item.category)">
          <img src="~@/assets/pump.png" class="icon"/>
        </span>
        <span v-else>
          <img src="~@/assets/custom.png" class="icon"/>
        </span>
      </div>
      <div style="width:80%; height: 100%; text-align: left; align-self: center; padding-left: 5%;">
        {{item.name}}
      </div>
      <div style="width: 20%; height: 100%">
        <p>
          <input type="checkbox" :value="item" v-model="orderForm" :id="i" checked="checked" @change="checkItem(item)" />
          <label :for="i"></label>
        </p>
      </div>
    </div>
  </div>
</div>
</div>
<!----- main body end ----->

<!------ Cart View --------->
<div style="width: 100%; height: 60%" v-if="cartView">
<div style="width: 100%; height: 625px; overflow-y: scroll;">
  <!-- Custom Button -->
  <div style="display: flex; flex-direction: row; justify-content: flex-start; align-items: center; padding-top: 2%; border-bottom: 1px solid lightgrey;">
    <div style="width: 10%; height: 100%; align-items:center; justify-content: flex-start; padding-left: 1%">
      <span><img src="~@/assets/custom.png" style="width: 40px; height: 40px; background-size: contain;"/></span>
    </div>
    <div style="width:80%; height: 100%; text-align: left; align-self: center; padding-left: 5%; cursor: pointer">
      <span v-on:click="goToCustom()">Create Custom</span>
    </div>
    <div style="width: 20%; height: 100%">
      <p>
      <!-- if you need to add text -->
      </p>
    </div>
  </div>
  <div v-for="(item, i) in order" v-bind:key="i" style="border-bottom: 1px solid lightgrey;" v-on:click="goToItem(item, 1)">
    <div style="display: flex; flex-direction: row; justify-content: flex-start; align-items: center; padding-top: 2%">
      <div style="width: 10%; height: 100%; align-items:center; justify-content: flex-start; padding-left: 1%">
        <span v-if="item.category == 'Equipment' || item.category == 'Fans & Filter'">
          <img src="~@/assets/equipment.png" class="icon"/>
        </span>
        <span v-else-if="item.category == 'Nutrients & Supplements'">
          <img src="~@/assets/fert.png" class="icon"/>
        </span>
        <span v-else-if="item.category == 'Office Supplies'">
          <img src="~@/assets/shears.png" class="icon"/>
        </span>
        <span v-else-if="item.category == 'Grow Media & Amendments'">
          <img src="~@/assets/grow.png" class="icon"/>
        </span>
        <span v-else-if="item.category == 'Lighting'">
          <img src="~@/assets/lighting.png" class="icon"/>
        </span>
        <span v-else-if="item.category == 'Hygiene & Safety'">
          <img src="~@/assets/safety.png" class="icon"/>
        </span>
        <span v-else-if="item.category == 'Bugs'">
          <img src="~@/assets/bug.png" class="icon"/>
        </span>
        <span v-else-if="item.category == 'Irrigation' || item.category == 'Water Pump & Air Pump'">
          <img src="~@/assets/pump.png" class="icon"/>
        </span>
        <span v-else>
          <img src="~@/assets/custom.png" class="icon"/>
        </span>
      </div>
      <div style="width:80%; height: 100%; text-align: left; align-self: center; padding-left: 5%; cursor: pointer">
        <span>{{item.name}}</span>
      </div>
      <div style="width: 20%; height: 100%">
        <p>
          <!-- <input type="checkbox" :value="item" v-model="orderForm" :id="i" checked="checked" />
          <label :for="i"></label> -->
          {{item.amount}}
        </p>
      </div>
    </div>
  </div>
</div>
</div>
<!----- Cart View end --------->

<!------ Item View --------->
<div v-if="itemView" style="width: 100%; height: 60%">
<div style="width: 100%; height: 625px; overflow-y: scroll;">
  <div style="display: flex; flex-direction:column;">
    <div style="width:100%; height: 40%">
        <h2>{{targetItem.name}}</h2>
        <span v-if="targetItem.category == 'Equipment' || targetItem.category == 'Fans & Filter'">
          <img src="~@/assets/equipment.png" class="iconL"/>
        </span>
        <span v-else-if="targetItem.category == 'Nutrients & Supplements'">
          <img src="~@/assets/fert.png" class="iconL"/>
        </span>
        <span v-else-if="targetItem.category == 'Office Supplies'">
          <img src="~@/assets/shears.png" class="iconL"/>
        </span>
        <span v-else-if="targetItem.category == 'Grow Media & Amendments'">
          <img src="~@/assets/grow.png" class="iconL"/>
        </span>
        <span v-else-if="targetItem.category == 'Lighting'">
          <img src="~@/assets/lighting.png" class="iconL"/>
        </span>
        <span v-else-if="targetItem.category == 'Hygiene & Safety'">
          <img src="~@/assets/safety.png" class="iconL"/>
        </span>
        <span v-else-if="targetItem.category == 'Bugs'">
          <img src="~@/assets/bug.png" class="iconL"/>
        </span>
        <span v-else-if="targetItem.category == 'Irrigation' || targetItem.category == 'Water Pump & Air Pump'">
          <img src="~@/assets/pump.png" class="iconL"/>
        </span>
        <span v-else>
          <img src="~@/assets/custom.png" class="iconL"/>
        </span>
    </div>
    <div style="width: 100%; height: 60%;" class="form-style-7">
        <li>
            <label for="amount">Amount</label>
            <input type="number" name="minimum" maxlength="100" v-on:input="onChange(targetItem.name, $event)" :placeholder="targetItem.amount">
            <span>Enter Amount</span>
        </li>
        <li>
            <label for="comment"> Comment</label>
            <input type="text" name="name" maxlength="100" v-model="targetItem.comment"/>
            <span>Enter a comment</span>
        </li>
        <li style="display: block; padding: 9px; border: 1px solid #ddd; margin-bottom: 30px; border-radius: 3px">
            <label for="link">Link</label>
            <textarea style="height: 30px" name="link" v-model="targetItem.productLink" :placeholder="targetItem.productLink"></textarea>
            <span>Enter a link for the product</span>
        </li>
      <button v-if="viewQuery != 0" v-on:click.stop.prevent="onRemove(targetItem.custom, $event)" style="background-color: #FF9B9B"> Remove </button>
      <button v-else v-on:click="goToMain"> Return </button>
      <button v-if="viewQuery != 0" v-on:click="onUpdate(targetItem, $event)"> Update Cart </button>
      <button v-else v-on:click="addToOrderForm" style="background-color: #FF9B9B"> Add to Cart </button>

    </div>
  </div>
</div>
</div>
<!----- Item View end --------->

<!------ Custom View --------->
<div v-if="customView" style="width: 100%; height: 60%">
<div style="width: 100%; height: 625px; overflow-y: scroll;">
  <div style="display: flex; flex-direction:column;">
    <div style="width:100%; height: 40%">
        <h2>{{customItem.name}}</h2>
        <img src="~@/assets/custom.png" style="width: 150px; height: 150px; background-size: contain;"/>
    </div>
    <div style="width: 100%; height: 100%;" class="form-style-7">
        <li>
            <label for="comment"> Name</label>
            <input type="text" name="name" maxlength="100" v-model="customItem.name"/>
            <span>Enter product name</span>
        </li>
        <li>
            <label for="amount">Amount</label>
            <input type="number" name="minimum" maxlength="100" v-model="customItem.amount">
            <span>Enter Amount</span>
        </li>
        <li>
            <label for="comment"> Comment</label>
            <input type="text" name="comment" maxlength="100" v-model="customItem.comment"/>
            <span>Enter a comment</span>
        </li>
        <li style="display: block; padding: 9px; border: 1px solid #ddd; margin-bottom: 30px; border-radius: 3px">
            <label for="link">Link</label>
            <textarea style="height: 30px" name="link" v-model="customItem.productLink"></textarea>
            <span>Enter a link for the product</span>
        </li>
      <button v-on:click="goToCart"> Cancel </button>
      <button v-on:click.stop.prevent="addCustom(customItem)" style="background-color: #FF9B9B"> Add to Order </button>
    </div>
  </div>
</div>
</div>
<!----- Item View end --------->

<!------ Order Result View --------->
<div style="width: 100%; height: 60%" v-if="resultView">
  <div style="display: flex; flex-direction:column; justify-content: center; align-items: center;">
      <div style="padding-top: 3%">
          <img src="~@/assets/ads.png" style="width: 150px; height: auto; background-size: contain;"/>
      </div>

      <div style="padding-bottom: 2%">
          <h2> Thank you for ordering with ADS! </h2>
          <h3> Your order is expected to arrive on: </h3>
          <h3 style="margin: 0; padding: 0"> {{lastOrder.eta}}</h3>
      </div>

      <div style="height: 300px; overflow-y: scroll; border-top: solid 1px lightgrey">
      <div v-for="(item, i) in lastOrder.orderInfo" v-bind:key="i" style="border-bottom: 1px solid lightgrey;">
          <div style="display: flex; flex-direction: row; justify-content: flex-start; align-items: center; padding-top: 2%">
            <div style="width:80%; height: 100%; text-align: left; align-self: center; padding-left: 5%; cursor: pointer">
              <span>{{item.name}}</span>
            </div>
            <div style="width: 20%; height: 100%">
              <p>
                {{item.amount}}
              </p>
            </div>
          </div>
      </div>
      </div>
  </div>
</div>
<!----- Item View end --------->

<!------ Modal Alert ----------->
<transition name="modal">
<div v-if="modalView" class="modal-mask">
  <div class="modal-wrapper">
    <div class="modal-container" style="width: 90%; height: 300px; overflow-y: hidden;">
      <div class="modal-header">
        <slot name="header">
          <div style="display: flex; flex-direction: row;">
            <h2 class="modal-banner">Error <span v-on:click="modalView = 0" style="color: #FFF; float: right; cursor: pointer; padding-right: 10%">X</span> </h2>
            <!-- <button v-on:click="modalView = 0" class="cancelButton" style="padding-right: 5%; border: none"> X </button> -->
          </div>
        </slot>
      </div>

      <div class="modal-body" style="padding:2%">
        <slot name="body">
            <div>
              <h3 style="margin: 0; padding: 0">{{msg}}</h3>
              <p>{{errorItems}}</p>
            </div>
        </slot>
      </div>

      <div class="modal-footer">
        <slot name="footer">
        </slot>
      </div>
    </div>
  </div>
</div>
</transition>

</div>
</template>

<script>
export default {
  created(){
    this.getADSInventory()
    this.$store.dispatch('getLastOrder').then(res => {
      console.log(res)
      let cur = res[0].orderInfo
      this.prevOrder =  JSON.parse(decodeURI(cur))
      console.log(this.prevOrder)
    })
  },
  data() {
    return {
      testForm: {
        name: '',
        text: ''
      },
      ////////////////////// Item loading data /////////////////////
      filter: [],
      ADSstock: [],
      allStock: [],
      stock: [],
      restock: [],
      orderForm: [],
      order: [],
      stockTable: {},
      adsFlag : 0,
      ///////////////////// Search Functions ///////////////////////
      searchTable: {},
      searchNames: [],
      searchTableADS: {},
      searchNamesADS: [],
      searchADS: '',
      /////////////////////// Ordering /////////////////////////////
      accProfile: this.$store.state.userProfile,
      orderForm: [],
      order: [],
      lastOrder: {},
      errorItems: [],
      prevOrder: [],
      /////////////////////// Views ///////////////////////////////
      mainView: 1,
      cartView: 0,
      itemView: 0,
      checkView: 0,
      customView: 0,
      resultView: 0,
      modalView: 0,
      targetItem: {},
      msg: '',
      alertMsg: '',
      customItem: {'isCustom' : 1},
      viewQuery: 0,
      alertView: 0,
      ////////////////////// Flag /////////////////////////////////
      emergencyFlag: 0
    }
  },
  methods: {
    getADSInventory() {
      this.ADSstock = []
      this.searchNamesADS = []
      this.$store.dispatch('getADSInventory').then(res => {
        for(let i = 0; i < res.length; i++){
          if(res[i]){
            this.searchTableADS[(res[i].name.toLowerCase())] = res[i]
            this.searchNamesADS.push(res[i].name.toLowerCase())
            this.ADSstock.push(res[i])
            this.stockTable[res[i].name] = (res[i].necessary_amount + 1) - res[i].quantity
          }
        }
        this.stock = this.ADSstock.sort(this.sortItems)
        this.adsFlag = 1
        this.getInventory()
      })
    },
    getInventory() {
      this.$store.dispatch('getUserInfo').then(res => {
          this.$store.state.userProfile = res
          this.allStock = []
          this.searchTable = {}
          this.searchNames = []
          this.stockTable = {}
          this.$store.dispatch('getInventory').then(res => {
            for(let i = 0; i < res.length; i++){
              if(res[i]){
                this.searchTable[(res[i].name.toLowerCase())] = res[i]
                this.searchNames.push(res[i].name.toLowerCase())
                this.allStock.push(res[i])
                this.stockTable[res[i].name] = (res[i].necessary_amount + 1) - res[i].quantity
              }
            }
            this.allStock = this.allStock.sort(this.sortItems)
          })
      })
    },
    sortItems(a,b){
      const itemA = a.name.toUpperCase()
      const itemB = b.name.toUpperCase()
      let comparison = 0
      if(itemA > itemB){
        comparison = 1;
      } else if(itemA < itemB) {
        comparison = -1
      }
      return comparison
    },
//////////////////////////////////////////// Views /////////////////////////////////////////////////////////////
    goToMain(){
      this.mainView = 1
      this.cartView = 0
      this.itemView = 0
      this.customView = 0
      this.resultView = 0
      // for(let i = 0; i < this.order.length; i++){
      //   this.orderForm.push(this.order[i])
      // }
    },
    goToCart(query){
      this.mainView = 0
      this.cartView = 1
      this.itemView = 0
      this.customView = 0
      this.resultView = 0
      this.beforeCheckForm()
      if(query == 0){
        this.viewQuery = 1
      }
      // this.scanPrevOrder()
      // if(!this.checkView){
      //   this.checkForm()
      //   this.checkView = 1
      // }
    },
    goToItem(item, query){
      this.mainView = 0
      this.cartView = 0
      this.itemView = 1
      this.customView = 0
      this.targetItem = item
      this.viewQuery = query
      this.resultView = 0
    },
    goToCustom(){
      this.mainView = 0
      this.cartView = 0
      this.itemView = 0
      this.customView = 1
      this.resultView = 0
    },
    goToResult(){
      this.mainView = 0
      this.cartView = 0
      this.itemView = 0
      this.customView = 0
      this.resultView = 1
      this.order = []
      this.orderForm = []
      this.msg = ''
      sessionStorage.clear()
      this.$store.dispatch('getLastOrder').then(res => {
        console.log(res)
        this.lastOrder = res[0]
        this.lastOrder.orderInfo = JSON.parse(decodeURI(this.lastOrder.orderInfo))
        console.log(this.lastOrder)
      })
    },
//////////////////////////////////////////// Search Bar Functions //////////////////////////////////////////////
    confirmItems(e){
      console.log(event.target.value)
      //console.log(this.searchTable)
      let term = event.target.value
      // let result = this.searchNames.filter(word => word.includes(term));
      
      const result = this.match(term.toLowerCase()) 
      let temp = []
      if(result.length){
        //go through the searchTable find the right items and store for display
        for(let i = 0; i < result.length; i++){
            let cur = result[i]
            if(this.searchTable[cur]){
                temp.push(this.searchTable[cur])
            }
        }
        this.stock = [...new Set (temp.sort(this.sortItems))]
      }
    },
    confirmItemsADS(e){
      console.log(event.target.value)
      //console.log(this.searchTable)
      let term = event.target.value
      // let result = this.searchNames.filter(word => word.includes(term));
      
      const result = this.matchADS(term.toLowerCase()) 
      let temp = []
      if(result.length){
        //go through the searchTable find the right items and store for display
        for(let i = 0; i < result.length; i++){
            let cur = result[i]
            if(this.searchTableADS[cur]){
                temp.push(this.searchTableADS[cur])
            }
        }
        this.stock = [...new Set (temp.sort(this.sortItems))]
      }
    },
    match(s){
        const p = Array.from(s).reduce((a, v, i) => `${a}[^${s.substr(i)}]*?${v}`, '');
        const re = RegExp(p);
        return this.searchNames.filter(v => v.match(re));
    },
    matchADS(s){
        const p = Array.from(s).reduce((a, v, i) => `${a}[^${s.substr(i)}]*?${v}`, '');
        const re = RegExp(p);
        return this.searchNamesADS.filter(v => v.match(re));
    },
///////////////////////////////////////// Filter ///////////////////////////////////////////////
    filterSearch(query){
        this.stock = this.stock.filter(item => item.category === query)
    },
///////////////////////////////////////  Ordering Function /////////////////////////////////////////////////
    checkItem(item){
      let checkFlag = 1
      // if(this.order.length == 0){
      //   this.goToItem(item)
      // }
      for(let i = 0; i < this.order.length; i++){
        if(this.order[i].name == item.name){
          checkFlag = 0
          this.alertView = 1
          this.alertMsg = item.name + ' is already in your cart'
        }
      }
      
      if(checkFlag){
        this.goToItem(item, 0)
      }
    },
    beforeCheckForm(){
      let output = []
      let seen = {}
      for(let i = 0; i < this.order.length; i++){
        if(!seen[this.order[i].name]){
          seen[this.order[i].name] = 1
        }
      }
      for(let i = 0; i < this.orderForm.length; i++){
        if(!seen[this.orderForm[i].name]){
          output.push(this.orderForm[i])
        }
      }
      this.orderForm = output
      this.checkForm()
    },
    scanPrevOrder(){
      let output = []
      let seen = {}
      for(let i = 0; i < this.prevOrder.length; i++){
        if(!seen[this.prevOrder[i].name]){
          seen[this.prevOrder[i].name] = this.prevOrder[i]
        }
      }
      for(let i = 0; i < this.order.length; i++){
        if(seen[this.order[i].name]){
          output.push(this.order[i].name)
        }
      }
      this.alertMsg = 'The following items are in your most recent order: ' + output
      this.alertView = 1
    },
    generateHash(el, arr){
      if(arr === null){
        this.order = []
        arr = this.order
      }
      let newObject = {'name': '', 
                        'amount' : '',  
                        'cur' : '', 
                        'onhand' : '', 
                        'comment' : '', 
                        'category': '',
                        'custom' : '',
                        'isCustom' : '', 
                        'ADSitem' : '',
                        'status' : '',
                        'adsComment' : '',
                        'productLink' : '',
                        'manufacturer' : '',
                        'part' : '',
                        'orderDate': '',
                        'orderedBy' : '',
                        }
          newObject['name'] = el.name
          newObject['isCustom'] = el.isCustom
          newObject['amount'] = el.amount
          newObject['category'] = el.category
          newObject['comment'] = el.comment
          newObject['ADSitem'] = el.ADSitem
          newObject['custom'] = el.custom
          newObject['status'] = el.status
          newObject['productLink'] = el.productLink
          newObject['manufacturer'] = el.manufacturer
          newObject['part'] = el.partNo
          newObject['orderDate'] = el.orderDate
          newObject['orderedBy'] = el.orderedBy
          arr.push(newObject)
    },
    addCustom(custom){
      this.orderForm.push(custom)
      this.checkForm()
      this.goToCart(1)
    },
    checkForm() {
      if(this.order === null){
        this.order = []
      }
      console.log("in checkForm")
      console.log(this.order)
      if(this.order === undefined || this.order.length  === 0){
        let output = sessionStorage.getItem("order")
        output = JSON.parse(decodeURI(output))
        this.order = output
      }
      this.$store.dispatch('getUserInfo').then(res => {
              this.$store.state.userProfile = res
              this.accProfile = res
              this.flag1 = 0
              this.flag2 = 1
              let temp = []
              for(let i = 0; i < this.orderForm.length; i++) {
                  let newObject = {'name' : this.orderForm[i].name,
                                  'isCustom' : this.orderForm[i].isCustom,
                                  'amount' : this.orderForm[i].amount,
                                  'category' : this.orderForm[i].category,
                                  'comment' : this.orderForm[i].comment,
                                  'ADSitem' : this.orderForm[i].ADSitem,
                                  'custom' : this.createRand(),
                                  'status' : 0,
                                  'adsComment' : '',
                                  'productLink' : this.orderForm[i].productLink,
                                  'manufacturer' : this.orderForm[i].manufacturer,
                                  'part' : this.orderForm[i].partNo,
                                  'orderDate' : new Date(),
                                  'orderedBy' : this.accProfile[0].name
                                  }
                  console.log(newObject)
                  temp.push(newObject)
              }
              this.orderForm = temp
              // console.log("In check form on custom.")
              // console.log(this.orderForm)
              for(let i = 0; i < this.orderForm.length; i++){
                this.generateHash(this.orderForm[i], this.order)
              }
              // this.orderForm.forEach(el =>
              //     this.generateHash(el, this.order)
              // );
              let body = '';
              body = JSON.stringify(this.order)
              body = encodeURI(body)
              sessionStorage.setItem("order", body);
              console.log(sessionStorage.getItem("order"))
              this.orderForm = []
  
          })
    },
    addToOrderForm(){
      for(let i = 0; i < this.orderForm.length; i++){
        if(this.orderForm[i].name === this.targetItem.name){
          this.orderForm[i] = this.targetItem
        }
      }
      this.checkForm()
      this.goToMain()
    },
    cancelOrderForm(){
        this.flag2 = 0
        this.flag1 = 1
        this.orderForm =[]
    },
    createRand() {
        return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    },
    insertProd(e){
        e.preventDefault()
        this.$store.dispatch('getUserInfo').then(res => {
            this.$store.state.userProfile = res
            this.accProfile = res
            let newObject = {'name' : '', 
                          'amount' : '', 
                          'cur': '', 
                          'onhand' : '', 
                          'comment' : '', 
                          'category' : '',
                          'isCustom' : 0,
                          'ADSitem' : 0,
                          'custom' : this.createRand(), 
                          'status' : 0, 
                          'adsComment' : '',
                          'productLink' : '',
                          'manufacturer' : '',
                          'part' : '',
                          'orderDate' : new Date(),
                          'orderedBy': this.accProfile[0].name}
            this.orderForm.push(newObject)
            this.order.push(newObject)
        })
    },
    onChange(str, event){
        if(event.target.value < 1){
            this.msg = "A invalid amount has been typed for " + str
        }
        else {
        this.msg = ''
            for (let i = 0; i < this.orderForm.length; i++){
                  if(this.orderForm[i].name === str){
                    // this.order[i].amount = event.target.value
                    console.log("Found item")
                    this.orderForm[i].amount = event.target.value
                }
            }
            for (let i = 0; i < this.order.length; i++){
                  if(this.order[i].name === str){
                    // this.order[i].amount = event.target.value
                    console.log("Found item")
                    this.order[i].amount = event.target.value
                }
            }
        }
    },
    onTextChange(str, event){
        for (let i = 0; i < this.order.length; i++){
              if(this.order[i].name === str){
                this.order[i].comment = event.target.value
            }
        }
    },
    onCustomChange(id, event) {
        event.preventDefault()
        console.log("item id is: " + id)
        for (let i = 0; i < this.order.length; i++) {
            if(id === this.order[i].custom){
                  this.order[i].name = event.target.value
            }
        }
    },
    onUpdate(item) {
        for(let i = 0; i < this.order.length; i++){
          console.log(this.order[i])
          if(this.order[i].custom === item.custom){
            this.order[i] = item
          }
        }
        
        // let body = JSON.stringify(this.order)
        // body = encodeURI(body)
        // sessionStorage.clear()
        // sessionStorage.setItem("order", body)
        this.goToCart(1)
    },
    onRemove(id) {
      console.log(this.orderForm)
      console.log("firing from on onRemove - id is: " + id)
      let newView = []
      for(let i = 0; i < this.orderForm.length; i++){
          if(this.orderForm[i].custom != id) {
              newView.push(this.orderForm[i])
          }
      }

      this.orderForm = newView

      let output = []
      for(let j = 0; j < this.order.length; j++){
          console.log(id)
          if(this.order[j].custom != id) {
              output.push(this.order[j])
          }
      }
      this.order = output
      let body = JSON.stringify(this.order)
      body = encodeURI(body)
      sessionStorage.clear()
      sessionStorage.setItem("order", body)
      this.goToCart(1)
    },
    createDetails(item){
      console.log('what is happening: ' + item)
      console.log(item)
      this.modalAdditionalInfo = 1
      this.currentItem = item
      console.log('add a link to the current order row for: ' + item.custom)
    },
    addDetails(item, event, subject){
      console.log("Item is: " + item)
      console.log(item);
      if(this.order.length < 1){
        if(this.$store.state.shoppingCart.length < 1){
          this.order = []
        } else {
        this.order = this.$store.state.shoppingCart
        }
      }
      if (subject ===  'link'){
          for(let i = 0; i < this.order.length; i++) {
              if(item.custom === this.order[i].custom) {
                  this.order[i].productLink = event.target.value
                  this.currentItem.productLink = event.target.value
              }
          }
      } else if (subject === 'manu'){
          for(let i = 0; i < this.order.length; i++) {
              if(item.custom === this.order[i].custom) {
                  this.order[i].manufacturer = event.target.value
                  this.currentItem.manufacturer = event.target.value
              }
          }
      } else if (subject === 'part'){
          for(let i = 0; i < this.order.length; i++) {
              if(item.custom === this.order[i].custom) {
                  this.order[i].part = event.target.value
                  this.currentItem.part = event.target.value
              }
          }                  
      } else if (subject === 'comment'){
          for(let i = 0; i < this.order.length; i++) {
              if(item.custom === this.order[i].custom) {
                  this.order[i].comment = event.target.value
                  this.currentItem.comment = event.target.value
              }
          }                  
      }
    },
    addToCart(){
        this.dupAlert = []
        this.alertDup = 0
        let commit = 1
        let missing = []
        let curCart = []
        if(this.$store.state.shoppingCart === null || this.$store.state.shoppingCart.length < 1){
          console.log("it's null")
        } else {
            curCart = this.$store.state.shoppingCart
        }
        for (let i = 0; i < curCart.length;i++){
            if(!this.dupCheck[curCart[i].name]){
              this.dupCheck[curCart[i].name] = true
            }
        }
        // scan through and make sure there aren't any items that have not amounts attached
        for(let i = 0; i < this.order.length; i++){
            if(!this.order[i].amount){
              missing.push(this.order[i].name)
              commit = 0
              this.msg = "Please insert an amount for: " + missing
              break
            } 
        }
        if(!commit){
            console.log("Add to cart did not go through")
        }
        if(commit){
            for(let i = 0; i < this.order.length;i++){
                if(!this.dupCheck[this.order[i].name]){
                    this.$store.commit('addItems', this.order[i])
                    this.dupCheck[this.order[i].name] = true
                }
                else {
                    this.dupAlert.push(this.order[i].name)
                    this.alertDup = 1
                }
            }
            this.flag2 = 0
            this.flag5 = 1
            this.orderForm = []
            this.order = []
            this.msg = ''
            let body = '';
            body = JSON.stringify(this.$store.state.shoppingCart)
            body = encodeURI(body)
            sessionStorage.setItem("order", body);
        }
    },
/////////////////////////////// Submit Cart //////////////////////////
    submitCart(e){
        e.preventDefault()
        //check order and see if any items are missing an amount
        let check = 1
        let output = []
        for(let i = 0; i < this.order.length; i++){
          if(!this.order[i].amount){
            check = 0
            output.push(this.order[i].name)
          }
        }
        if(check){
            this.handleSubmit(e)
        } else {
          this.msg = "Please insert an amount for the following items: "
          this.errorItems = output
          this.modalView = 1
        }

    },
    handleSubmit(e){
      e.preventDefault()
      this.$store.state.shoppingCart = this.order
      let output = {}
      if(this.emergencyFlag){
        // mark that it is an emergency run
        output.status = -1
      } else {
        output.status = 0
      }
      output.order = this.order
      console.log("In handle submit - this is the order info")
      this.$store.dispatch('logOrder', output).then(res => {
          console.log("res is equal to: ")
          console.log(res)
          this.goToResult();

      })
    },
  }
}
</script>

<style scoped>
* {
  outline: none
}
html {
  margin: 0;
  overflow-x: hidden;
}
body {
  margin: 0 0 0 0;
}

button {
  padding: 1%;
  font-size: 1em;
  background-color: #07ABA1;
  margin-left: 1%;
  margin-right: 1%;
  color: #FFF;
  border-radius: 2px;
  border: none;
  cursor: pointer;
  outline: none;
  box-shadow: none;
}

#main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.icon {
  width: 40px; 
  height: 40px; 
  border-radius: 50%;
  background-size: contain;
 box-shadow: 0px 0px 5px rgba(0,0,0,0.2);
}
.iconL {
  width: 150px; 
  height: 150px; 
  border-radius: 50%;
  background-size: contain;
  box-shadow: 0px 0px 5px rgba(0,0,0,0.2);
}
/*   Testing new input stying */ 
/* Base for label styling */
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
  outline: none;
  box-shadow: none;
}
[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
  position: relative;
  padding-left: 1.95em;
  cursor: pointer;
  outline: none;
  box-shadow: none;
}
/* checkbox aspect */
[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before {
  content: '';
  position: absolute;
  left: 0; top: 0;
  width: 1.25em; height: 1.25em;
  border: 2px solid #ccc;
  background: #fff;
  border-radius: 4px;
  box-shadow: inset 0 1px 3px rgba(0,0,0,.1);
  outline: none;
  box-shadow: none;
}
/* checked mark aspect */
[type="checkbox"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after {
  content: '\2713\0020';
  position: absolute;
  top: .15em; left: .22em;
  font-size: 1.3em;
  line-height: 0.8;
  color: #9CDDD9;
  transition: all .2s;
  font-family: 'Lucida Sans Unicode', 'Arial Unicode MS', Arial;
  outline: none;
  box-shadow: none;
}
/* checked mark aspect changes */
[type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
[type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}
/* disabled checkbox */
[type="checkbox"]:disabled:not(:checked) + label:before,
[type="checkbox"]:disabled:checked + label:before {
  box-shadow: none;
  border-color: #bbb;
  background-color: #ddd;
}
[type="checkbox"]:disabled:checked + label:after {
  color: #999;
}
[type="checkbox"]:disabled + label {
  color: #aaa;
}
/* accessibility */
[type="checkbox"]:checked:focus + label:before,
[type="checkbox"]:not(:checked):focus + label:before {
  border: 2px dotted blue;
  outline: none;
  box-shadow: none;
}
/* hover style just for information */
label:hover:before {
  border: 2px solid #4778d9!important;
  outline: none;
  box-shadow: none;
}

/*                modal                           */
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 1000px;
  height: 500px;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  overflow-y: scroll;
  padding-bottom: 2%;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.modal-default-button {
  margin: 5px;
}
.modal-banner {
  background-color: #07ABA1; 
  width: 100%;
  font-size: 1em;
  color: #FFF;
  margin: 0px;
  padding: 0px;
}
.cancelButton {
  background-color: #07ABA1;
  /* color: #FFF;  */
  float: left; 
  padding-right: 2%;
  font-size: 1.4em;
  font-weight: 1200;
  border-radius: 0px;
  cursor: pointer;
}

/*                  animations                    */
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: opacity 0.5s;
}

.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
}

.slide-enter-active {
  transition: all 0.3s ease;
}

.slide-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-enter,
.slide-leave-to {
  transform: translateX(10px);
  opacity: 0;
}


/*                form test                       */
.form-style-7{
  max-width:350px;
  margin:50px auto;
  background:#fff;
  border-radius:2px;
  padding:20px;
}
.form-style-7 h1{
  display: block;
  text-align: center;
  padding: 0;
  margin: 0px 0px 20px 0px;
  color: #5C5C5C;
  font-size:x-large;
}
.form-style-7 ul{
  list-style:none;
  padding:0;
  margin:0;	
}
.form-style-7 li{
  display: block;
  padding: 9px;
  border:1px solid #DDDDDD;
  margin-bottom: 30px;
  border-radius: 3px;
}
.form-style-7 li:last-child{
  border:none;
  margin-bottom: 0px;
  text-align: center;
}
.form-style-7 li > label{
  display: block;
  float: left;
  margin-top: -19px;
  background: #FFFFFF;
  height: 14px;
  padding: 2px 5px 2px 5px;
  color: #B9B9B9;
  font-size: 14px;
  overflow: hidden;
}
.form-style-7 input[type="text"],
.form-style-7 input[type="date"],
.form-style-7 input[type="datetime"],
.form-style-7 input[type="email"],
.form-style-7 input[type="number"],
.form-style-7 input[type="search"],
.form-style-7 input[type="time"],
.form-style-7 input[type="url"],
.form-style-7 input[type="password"],
.form-style-7 textarea,
.form-style-7 select 
{
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  width: 100%;
  display: block;
  outline: none;
  border: none;
  height: 25px;
  line-height: 25px;
  font-size: 16px;
  padding: 0;
}
.form-style-7 input[type="text"]:focus,
.form-style-7 input[type="date"]:focus,
.form-style-7 input[type="datetime"]:focus,
.form-style-7 input[type="email"]:focus,
.form-style-7 input[type="number"]:focus,
.form-style-7 input[type="search"]:focus,
.form-style-7 input[type="time"]:focus,
.form-style-7 input[type="url"]:focus,
.form-style-7 input[type="password"]:focus,
.form-style-7 textarea:focus,
.form-style-7 select:focus 
{
}
.form-style-7 li > span{
  background: #F3F3F3;
  display: block;
  padding: 3px;
  margin: 0 -9px -9px -9px;
  text-align: center;
  color: #C0C0C0;
  font-size: 11px;
}
.form-style-7 textarea{
  resize:none;
}
.form-style-7 input[type="submit"],
.form-style-7 input[type="button"]{
  background: #2471FF;
  border: none;
  padding: 10px 20px 10px 20px;
  border-bottom: 3px solid #5994FF;
  border-radius: 3px;
  color: #D2E2FF;
}
.form-style-7 input[type="submit"]:hover,
.form-style-7 input[type="button"]:hover{
  background: #6B9FFF;
  color:#fff;
}
</style>