<template>
    <div class="main">
        <div class="nav">
            <router-link to="/" class="navlink" style="color: #036D69"> Home </router-link>
            <router-link to="/phase" class="navlink" style="color: #036D69"> Room Checker</router-link>
            <router-link to="/recommend" class="navlink" style="color: #036D69"> Recommended</router-link>
            <router-link to="/allItems" class="navlink" style="color: #036D69"> All Items</router-link>
            <router-link to="/createItem" class="navlink"> Add Item </router-link>
            <router-link to="/deleteItem" class="navlink"> Remove Item </router-link>
            <router-link to="/orders" class="navlink"> Order History </router-link>
            <a class="navLink" v-on:click="showCart" style="cursor: pointer; color: #1279B7;"> Cart </a>
        </div>
        
        <transition appear appear-class="fade">
        <div v-if="flag0" style="display: flex; flex-direction: row; justify-content: center; padding-top: 15%;">
            <rotate-square2></rotate-square2>
        </div>
        </transition>
 
        <transition name="fade">
        <div v-if="flag1" style="display: flex; justify-content: center; padding-top: 2%;">
            <div class="cardBody">
                <div class="card" v-for="item in output" v-bind:key="item.id">
                    <button v-on:click="onEdit(item)"> Edit </button>
                    <div>
                        <h2 style="padding: 0px; margin: 25px 0px 0px 0px; font-size: 1.2em;">{{item.name}}</h2>
                    </div> 
                     <div>
                        <h1 v-bind:style="item.quantity > item.custom_attribute_values[0].value ? {'color' : '#000'} : {'color' : '#ff9b9b'} " style="font-size: 4em; padding: 0px; margin: 0px;">{{item.quantity}}</h1>
                        <input type="checkbox" :value="item" v-model="orderForm">
                    </div>
                </div>
            </div>
        </div>
        </transition>

        <transition name="fade">
        <div v-if="flag1" style="padding-top: 2%;">
            <button v-on:click="checkForm"> Order Form </button>
        </div>
        </transition>

<transition name="fade">
<div v-if="flag2" style="padding-top: 3%">
<h1> Order Sheet </h1>
<h2 style="color: #ff9b9b">{{msg}}</h2>
<div v-if="flag2" style="display: flex; justify-content: center;">
    <form>
    <table>
        <tr>
            <th> Name </th>
            <th> Current Stock </th>
            <th> Minimum On Hand </th>
            <th> Order Amount </th>
            <th> Comments </th>
            <th> Remove </th>
        </tr>
        <tr v-for="product in orderForm">
            <td v-if="product.name.length > 1">{{product.name}}</td>
            <td v-else><input type="text" style="wdith: 200px" v-on:change="onCustomChange(product.custom, $event)"/></td>
            <td>{{product.quantity}}</td>
            <td v-if="product.onhand" v-bind:style="product.quantity >= product.onhand? {'color' : '#07ABA1' } : {'color' : '#ff9b9b'}">{{product.quantity}} <span style="color: #000">/</span> <span style="color: #000"> {{product.onhand}}</span></td>
            <td v-else>N/A</td>
            <td><input type="number" name-="amount" min="1" style="width: 50px;" v-on:input="onChange(product.name, $event)" required/></td>
            <td><input type="text" name="comment" style="width: 400px" v-on:input="onTextChange(product.name, $event)"/></td>
            <td><button style="width: 25px; height: 25px; padding: 2px;" v-on:click.stop.prevent="onRemove(product.custom, $event)">x</button></td>
        </tr>
    </table>
    </form>
</div>
<div style="padding-top: 2%">
    <button v-on:click.stop.prevent="cancelOrderForm" style="background-color: #8CB6D2; margin-right: 5px;"> Cancel </button>
    <button v-on:click.stop.prevent="insertProd" style="background-color: #9CDDD9; margin-right: 5px;"> Custom </button>
    <button v-on:click.stop.prevent="addToCart"> Add to Cart </button>
</div>
</div>
</transition>

<transition name="fade">
<div v-if="!flag2 && !flag1 && flag5" style="padding-top: 3%">
    <h1> Order has Successfully Been Added to Your Cart</h1>
    <div v-if="alertDup">
        <h2 style="color: red;">The following item(s) are already in your cart: </h2>
        <ul v-for="dup in dupAlert">
            <li>{{dup}}</li>
        </ul>
    </div>
    <h3>Sending you back to the previous screen</h3>
</div>
</transition>

<!-- edit modal -->
        
<transition name="modal">
<div v-if="showModal" class="modal-mask">
  <div class="modal-wrapper">
    <div class="modal-container">

      <div class="modal-header">
        <slot name="header">
          <h1> Edit </h1>
        </slot>
      </div>

      <div class="modal-body" v-if="modal1">
        <slot name="body">
            <table>
                <tr>
                    <th> Name </th>
                    <th> Current Stock </th>
                    <th> Minimum Requirement </th>
                    <th> Notes </th>
                <tr>
                    <td><input type="text" name="name" v-model="name" required="true"></td>
                    <td><input type="number" name="current stock" v-model="quantity" required="true"></td>
                    <td><input type="number" name="minimum" v-model="minimum" required="true"></td>
                    <td><input type="text" name="name" v-model="notes"></td>
                </tr>
            </table>
        </slot>
      </div>

      <div class="modal-body" v-else>
        <slot name="body">
            <h1> Item was successfully updated - Rerouting you back to the page </h1>
        </slot>
      </div>

      <div class="modal-footer">
        <slot name="footer">
          <br>
          <button class="modal-default-button" v-on:click="showModal = 0" style="background-color: red;"> Cancel </button>
          <button class="modal-default-button" v-on:click="editItem"> Edit </button>
        </slot>
      </div>
    </div>
  </div>
</div>
</transition>

<!-- Cart Model -->

<transition name="modal">
<div v-if="modal2" class="modal-mask">
  <div class="modal-wrapper">
    <div class="modal-container">

      <div class="modal-header">
        <slot name="header">
          <div style="display: flex; flex-direction: row;">
            <h2 class="modal-banner">Your Cart</h2>
            <button v-on:click="closeCart" class="cancelButton"> X </button>
          </div>
        </slot>
      </div>

      <div class="modal-body" v-if="modal3">
        <slot name="body">
            <div>
            <table>
                <tr>
                    <th> Name </th>
                    <th> Amount </th>
                    <th> On Hand </th>
                    <th> Check </th>
                    <th> Comment </th>
                <tr v-for="item in this.$store.state.shoppingCart" :key="item.custom">
                    <td>{{item.name}}
                    <td>{{item.amount}}</td>
                    <td v-if="item.onhand" v-bind:style="item.cur >= item.onhand? {'color' : '#07ABA1' } : {'color' : '#ff9b9b'}">{{item.cur}} <span style="color: #000">/</span> <span style="color: #000"> {{item.onhand}}</span></td>
                    <td v-else style="color: #CDEEEC"> N/A </td>
                    <td> <span v-if="item.cur + item.amount >= item.onhand || !item.onhand" style="color: #07ABA1">&#x2713;</span>
                         <span v-else style="color: #ff9b9b; font-weight: bold;"> X </span>
                    </td>
                    <td>{{item.comment}}</td>
                </tr>
            </table>
            </div>
        </slot>
      </div>

      <div class="modal-body" v-if="modal4">
        <slot name="body">
            <div style="padding: 5px;">
            <h3 style="color: #ff9b9b; margin: 0px 0px 10px 0px;">{{msg}}</h3>
            <table>
                <tr>
                    <th> Name </th>
                    <th> Order Amount </th>
                    <th> On Hand </th>
                    <th> Comments </th>
                    <th> Remove</th>
                </tr>
                <tr v-for="product in orderForm">
                    <td v-if="product.name.length > 1">{{product.name}}</td>
                    <td v-else><input type="text" style="width: 200px" v-on:change="onCustomChangeCart(product.custom, $event)"/></td>
                    <td><input type="number" name="amount" min="1" style="width: 50px; border:none;" v-on:input="onChangeCart(product.name, $event)" :placeholder="product.amount"/></td>
                    <td v-bind:style="product.cur > product.onhand? {'color' : '#07ABA1' } : {'color' : '#ff9b9b'}">{{product.cur}} <span style="color: #000">/</span> <span style="color: #000"> {{product.onhand}}</span></td>
                    <td><input type="text" name="comment" style="width: 400px; border: none;" v-on:input="onTextChangeCart(product.name, $event)" :placeholder="product.comment"/></td>
                    <td><button style="width: 25px; height: 25px; padding: 2px; background-color: #FF9b9b" v-on:click.stop.prevent="onRemove(product.custom, $event)">x</button></td>
                </tr>
            </table>
            <button style="margin-top: 2%; background-color: #07ABA1" v-on:click="updateCart"> Update Cart </button>
            </div>
        </slot>
      </div>

      <div class="modal-body" v-if="modal5">
        <slot name="body">
            <div>
                <h1> Your order has been successfully registered! </h1>
                <h2> We will contact you if there are any problems</h2>
                <table>
                    <tr>
                        <th> Name </th>
                        <th> Amount </th>
                        <th> Comment </th>
                    <tr v-for="item in this.$store.state.shoppingCart" :key="item.custom">
                        <td>{{item.name}}
                        <td>{{item.amount}}</td>
                        <td>{{item.comment}}</td>
                    </tr>
                </table>
            </div>
        </slot>
      </div>

      <div class="modal-footer" v-if="!modal4 && !modal5">
        <slot name="footer">
          <button class="modal-default-button" v-on:click="clearCart" style="background-color: #8CB6D2;"> Clear Cart </button>
          <button class="modal-default-button" v-on:click="editCart" style="background-color: #9CDDD9;"> Edit Cart </button>
          <button class="modal-default-button" v-on:click="submitCart"> Submit </button>
        </slot>
      </div>
    </div>
  </div>
</div>
</transition>

</div>

</template>

<script>
    import axios from 'axios';
    import {RotateSquare2} from 'vue-loading-spinner'
    axios.defaults.baseURL = 'http://localhost:8080/'
    axios.defaults.headers.common = {'Authorization': `Bearer: sk_sortly_zgeTP9QsLkrag84CTZw5`}

    export default {
        created(){
          this.getInventory()
          this.loadFolders()
        },
        mounted() {
          setTimeout(() => this.loadItems(), 2000);
        },
        data () {
            return {
                id: this.$route.params.id,
                facility: '15505335',
                posts: [],
                error: [],
                options: [1,2,3,4,5,6,7,8,9,10],
                restock: [],
                orderForm: [],
                order: [],
                folderIDs: [],
                subFolderIDs: [],
                phasesFolders: [],
                facilityFolders: [],
                additionalLocations: [],
                filter: [],
                roomItems: [],
                output: [],
                dupAlert: [],
                testTable: {},
                stockTable: {},
                dupCheck: {},
                i: 0,
                flag0: 1,
                flag1: 1,
                flag2: 0,
                flag3: 0,
                flag4: 1,
                flag5: 0,
                showOrder: 0,
                custom: 0,
                showModal: 0,
                modal1: 1,
                modal2: 0,
                modal3: 0,
                modal4: 0,
                modal5: 0,
                editFlag: 0,
                modal1: 1,
                finalSub: 0,
                name: '',
                notes: '',
                quantity: '',
                minimum: '',
                edit_id: '',
                search: '',
                msg: '',
            }
        },
      components: {
        RotateSquare2
      },
      methods: {
/////////////////////////////////////////////// Beginning of SubFolder Functions /////////////////////////////////////////////////////////////////////////
              loadSubFolders(){
                axios.get('https://api.sortly.co/api/v1/items?per_page=100&page=1&folder_id=' + this.facility + '&include=custom_attributes%2Cphotos')
                  .then(response => {
                    for (let i = 0; i < response.data.data.length; i++){
                        this.subFolderIDs.push(response.data.data[i].id)
                    }
                    this.subFolderSearch(this.subFolderIDs)
                  })
                  .catch(e => {
                    console.log(e);
                  })
              },
              subFolderSearch(folders){
                  for(let i = 0; i < folders.length; i++){
                    this.subFolderScan(folders[i])
                  }
              },
              subFolderScan(id) {
                axios.get('https://api.sortly.co/api/v1/items?per_page=100&page=1&folder_id=' + id + '&include=custom_attributes%2Cphotos')
                  .then(response => {
                    this.fetchRoomFolders(response.data.data)
                  })
                  .catch(e => {
                    console.log(e);
                  })
              },
              fetchRoomFolders(arr){
                  for (let i = 0; i < arr.length; i++) {
                      this.subFolderItemScan(arr[i].id)
                  }
              },
              subFolderItemScan(id) {
                axios.get('https://api.sortly.co/api/v1/items?per_page=100&page=1&folder_id=' + id + '&include=custom_attributes%2Cphotos')
                  .then(response => {
                    this.fetchSubItems(response.data.data)
                  })
                  .catch(e => {
                    console.log(e);
                  })
              },
              fetchSubItems(arr){
                  for(let i = 0; i < arr.length; i++){
                      if(arr[i].name === this.search) {
                        let newObj = {'id' : '', 'name': ''}
                        newObj['id'] = arr[i].id
                        newObj['name'] = arr[i].name
                        this.additionalLocations.push(newObj)
                      }
                  }
              },
/////////////////////////////////////////////// End of Folder Functions ////////////////////////////////////////////////////////////////////////
              getInventory() {
                console.log("this id is currently: :" + this.id)
                axios.get('https://api.sortly.co/api/v1/items?per_page=100&page=1&folder_id=' + this.id + '&include=custom_attributes%2Cphotos')
                  .then(response => {
                    console.log(response)
                    for (this.i = 0; this.i < response.data.data.length; this.i++){
                        this.roomItems.push(response.data.data[this.i].name)
                      }
                    this.i = 0;
                    this.restock.sort(this.sortItems)
                  })
                  .catch(e => {
                    console.log(e);
                  })
              },
              sortItems(a,b){
                const itemA = a.name.toUpperCase()
                const itemB = b.name.toUpperCase()

                let comparison = 0
                if(itemA > itemB){
                  comparison = 1;
                } else if(itemA < itemB) {
                  comparison = -1
                }
                return comparison
              },
              checkForm(){
                this.orderForm.forEach(el =>
                  this.posts.push(el + " | " + this.stockTable[el] + "x"));
                this.flag1 = 0
                this.showOrder = 1
              },
              loadFolders(){
                axios.get('https://api.sortly.co/api/v1/items?per_page=100&page=1&folder_id=15127806&include=custom_attributes%2Cphotos')
                  .then(response => {
                    for (this.i; this.i < response.data.data.length; this.i++){
                        this.folderIDs.push(response.data.data[this.i].id)
                    }
                    this.i = 0
                    this.folderSearch()
                  })
                  .catch(e => {
                    console.log(e);
                  })
              },
              folderScan(id) {
                axios.get('https://api.sortly.co/api/v1/items?per_page=100&page=1&folder_id=' + id + '&include=custom_attributes%2Cphotos')
                  .then(response => {
                    this.fetchItems(response.data.data)
                  })
                  .catch(e => {
                    console.log(e);
                  })
              },
              folderSearch(){
                  for (let i = 0; i < this.folderIDs.length; i++) {
                      this.folderScan(this.folderIDs[i])
                  }
              },
              fetchItems(arr){
                  arr.forEach(el =>
                    this.stockTable[el.name] = el
                  )
              },
              generateCards(arr){
                  this.flag1 = 1
                  arr.forEach(el =>
                      this.output.push(this.stockTable[el])
                  )
              },
              checkForm() {
                this.flag1 = 0
                this.flag2 = 1
                let temp = []
                for(let i = 0; i < this.orderForm.length; i++) {
                    let newObject = {'name' : this.orderForm[i].name,
                                     'cur' : this.orderForm[i].quantity,
                                     'onhand': this.orderForm[i].custom_attribute_values[0].value,
                                     'quantity' : this.orderForm[i].quantity,
                                     'custom' : this.createRand()
                                    }
                    temp.push(newObject)
                }
                this.orderForm = temp
                this.orderForm.forEach(el =>
                    this.generateHash(el, this.order)
                );
              },
              cancelOrderForm(){
                  this.flag2 = 0
                  this.flag1 = 1
                  this.orderForm = []
              },
              createRand() {
                  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
              },
              insertProd(e){
                e.preventDefault()
                this.custom = this.custom + 1
                let newObject = {'name': '', 'amount': '', 'comment' : '', 'custom' : this.createRand()}
                this.orderForm.push(newObject)
                this.order.push(newObject)
              },
              generateHash(el, arr){
                let newObject = {'name': '', 'amount' : '',  'cur' : '', 'onhand' : '', 'comment' : '', 'custom' : ''}
                    newObject['name'] = el.name
                    newObject['cur'] = el.cur
                    newObject['onhand'] = el.onhand
                    newObject['custom'] = el.custom
                    arr.push(newObject)
              },
              loadItems() {
                  this.flag0 = 0
                  this.flag1 = 1
                  this.generateCards(this.roomItems)
              },
              onChange(str, event){
                  if(event.target.value < 1){
                      this.msg = "A invalid amount has been typed for " + str
                  }
                  else {
                  this.msg = ''
                      for (let i = 0; i < this.order.length; i++){
                            if(this.order[i].name === str){
                              this.order[i].amount = event.target.value
                          }
                      }
                  }
              },
              onTextChange(str, event){
                  for (let i = 0; i < this.order.length; i++){
                        if(this.order[i].name === str){
                          this.order[i].comment = event.target.value
                      }
                  }
              },
              onRemove(id) {
                  console.log("firing from on onRemove - id is: " + id)
                  let newView = []
                  for(let i = 0; i < this.orderForm.length; i++){
                      if(this.orderForm[i].custom != id){
                          newView.push(this.orderForm[i])
                     }
                  }

                  this.orderForm = newView

                  let output = []
                  for(let j = 0; j < this.order.length; j++){
                      console.log(id)
                      if(this.order[j].custom != id){
                          output.push(this.order[j])
                      }
                  }
                  this.order = output
                  console.log(output)
              },
              onCustomChange(id, event) {
                  event.preventDefault()
                  console.log("item id is: " + id)
                  for (let i = 0; i < this.order.length; i++) {
                      if(id === this.order[i].custom){
                            this.order[i].name = event.target.value
                      }
                  }
              },
//////////////////////////////////////////// Edit + Modal Functions ////////////////////////////////////////////
              onEdit(item) {
                this.showModal = 1
                console.log("the id of the product is: " + item.id)
                console.log(item)
                //// Find Parent Item ///
                console.log(this.stockTable)


                this.edit_id = item.id
                this.name = item.name
                this.notes = item.notes
                this.quantity = item.quantity
                this.minimum = item.custom_attribute_values[0].value
                this.search = item.name
                this.loadSubFolders()
                console.log(this.additionalLocations)
              },
              insertItem(body, id){
                axios.put('https://api.sortly.co/api/v1/items/' + id +'/', body)
                  .then(response => {
                    console.log('SUCCESS')
                  })
                  .catch(e => {
                    console.log(e)
                  })
              },
              editItem() {
                // load initial target then get the subfolders
                let p_id = this.edit_id

                let body = {
                  'name': this.name,
                  'notes': this.notes,
                  'quantity': this.quantity,
                  'tags': [{}],
                  'custom_attribute_values': [
                    {
                      'custom_attribute_id': 183374,
                      'attribute_value': this.minimum
                    }
                  ],
                };
                
                //insert parent item
                this.insertItem(body, p_id)

                //insert child item
                for(let i = 0; i < this.additionalLocations.length; i++){

                    let body = {
                      'name': this.name,
                      'notes': this.notes,
                      'quantity': 0,
                      'tags': [{}],
                      'custom_attribute_values': [
                        {
                          'custom_attribute_id': 183374,
                          'attribute_value': this.minimum
                        }
                      ],
                    };

                    let id = this.additionalLocations[i].id
                    this.insertItem(body, id)
                }

                this.modal1 = 0
                setTimeout(() => this.$router.go(0), 2000);
            },
//////////////////////////////////////////// Shopping Cart Functions ////////////////////////////////////////////
            showCart(){
                console.log("View Cart")
                this.modal2 = 1
                this.modal3 = 1
            },
            closeCart(){
                if(this.finalSub){
                  this.clearCart()
                  this.finalSub = 0
                }
                this.modal2 = 0
                this.modal3 = 0
                this.modal4 = 0
                this.modal5 = 0
                this.orderForm = []
                this.editFlag = 0
            },
            editCart(){
                this.modal4 = 1
                this.modal3 = 0
                this.modal5 = 0
                if(!this.editFlag){
                    this.editFlag = 1
                    let curCart = this.$store.state.shoppingCart
                    for(let i = 0; i < curCart.length; i++){
                       this.orderForm.push(curCart[i])
                    }
                }
                console.log(this.orderForm)
            },
            clearCart(){
                this.$store.commit('removeItems', this.$store.state.shoppingCart.length)
                this.modal2 = 0
                this.modal3 = 0
                this.modal4 = 0
            },
            updateCart(){
                console.log("Updating cart...")
                let curCart = this.$store.state.shoppingCart
                this.$store.commit('removeItems', curCart.length)
                console.log(this.$store.state.shoppingCart)
                for(let j = 0; j < this.orderForm.length;j++){
                    this.$store.commit('addItems', this.orderForm[j])
                }
                this.modal3 = 1
                this.modal4 = 0
                this.orderForm = []
                this.editFlag = 0
                console.log(curCart)
            },
            submitCart(e){
                e.preventDefault()
                this.modal5 = 1
                this.modal3 = 0
                console.log("Flags are: " + this.modal2 + this.modal3 + this.modal4)
                this.handleSubmit(e)
                this.finalSub = 1
            },
            stepBack(){
              this.cleartCart()
              this.$router.go(-1)
            },
            addToCart(){
                this.dupAlert = []
                this.alertDup = 0
                let commit = 1
                let missing = []
                let curCart = this.$store.state.shoppingCart
                for (let i = 0; i < curCart.length;i++){
                    if(!this.dupCheck[curCart[i].name]){
                      this.dupCheck[curCart[i].name] = true
                    }
                }

                // scan through and make sure there aren't any items that have not amounts attached
                for(let i = 0; i < this.order.length; i++){
                    if(!this.order[i].amount){
                      missing.push(this.order[i].name)
                      commit = 0
                    }
                }
                if(!commit){
                this.msg = "Please insert an amount for: " + missing 
                }

                if (commit){
                    for(let i = 0; i < this.order.length;i++){
                        if(!this.dupCheck[this.order[i].name]){
                            this.$store.commit('addItems', this.order[i])
                            this.dupCheck[this.order[i].name] = true
                        }
                        else {
                            this.dupAlert.push(this.order[i].name)
                            this.alertDup = 1
                        }
                    }
                    this.flag0 = 1
                    this.flag2 = 0
                    this.flag5 = 1
                    this.orderForm = []
                    this.order = []
                    this.msg = ''
                    setTimeout(() => this.$router.go(-1),  2000);
                }
            },
            // Submit order form
            handleSubmit(e){
              e.preventDefault()
              let order = this.$store.state.shoppingCart
              console.log(order)
              this.$store.dispatch('logOrder', order)
            },
            resetCartView() {
                this.flag1 = 1
                this.dupAlert = []
                this.alertDup = 0
            },
            onChangeCart(str, event){
                  if(event.target.value < 1){
                    this.msg = "Please input a valid amount " + str
                  }
                  else {
                    this.msg = ''
                    for (let i = 0; i < this.orderForm.length; i++){
                          if(this.orderForm[i].name === str){
                            this.orderForm[i].amount = event.target.value
                        }
                    }
                }
            },
            onTextChangeCart(str, event){
                for (let i = 0; i < this.orderForm.length; i++){
                      if(this.orderForm[i].name === str){
                        this.orderForm[i].comment = event.target.value
                    }
                }
            },
            onCustomChangeCart(id, event) {
                event.preventDefault()
                console.log("item id is: " + id)
                for (let i = 0; i < this.orderForm.length; i++) {
                    if(id === this.orderForm[i].custom){
                          this.orderForm[i].name = event.target.value
                    }
                }
            },
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    html {
        overflow-y:scroll;
    }
    h2 {
        font-weight: normal;
    }
    h1 {
        font-weight: normal;
    }
    h3 {
      margin: 40px 0 0;
    }
    table, th, td {
      border: 1px solid #DFDFDF;
      padding: 2px;
    }

    table {
        border-collapse: collapse;
        margin-left:auto;
        margin-right:auto;
    } 
    ul {
      list-style-type: square;
      padding: 0;
    }
    li {
      display: inline-block;
      margin: 0 10px;
    }
    a {
      color: #07ABA1;
      font-size: 1.5em;
    }
    button {
      padding: 1%;
      font-size: 1em;
      background-color: #07ABA1;
      color: #FFF;
      border-radius: 2px;
      border: none;
      cursor: pointer;
      outline: none;
      box-shadow: none;
    }
    .main {
      display: block;
      width: 100vw;
      height: 100vh;
    }
    .nav {
      padding-left: 3%;
      height: 1vh;
      width: 100vw;
      display: flex;
      /* border: 1px dotted #000; */
      align-items: center;
      justify-content: left;

    }

    .navlink {
        padding-right: 25px;
        font-size: 1.5em;
        text-decoration: none;
    }

    .sectionBody {
      width: 100vw;
      height: 90vh;
      /* border: 1px dotted #000; */
      display: flex;
      justify-content: center;
      padding-top: 5%;
    }

    .cardBody {
      display: grid;
      grid-template-columns: 250px 250px 250px 250px;
      grid-gap: 10px;
      padding: 1%;
      overflow-y: scroll;
      height: 400px;
    }

    .card {
      background-color: lightgrey;
      border-radius: 5px;
      width: 250px;
      height: 200px;
      display: flex;
      flex-direction: column;
    }

    .photo {
      width: 100px;
      height: 100px;
      object-fit: cover;
    }
    
/* Modal CSS */

    .modal-mask {
      position: fixed;
      z-index: 9998;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: table;
      transition: opacity 0.3s ease;
    }

    .modal-wrapper {
      display: table-cell;
      vertical-align: middle;
    }

    .modal-container {
      width: 60%;
      height: 80%;
      margin: 0px auto;
      background-color: #fff;
      border-radius: 2px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
      transition: all 0.3s ease;
    }

    .modal-header h3 {
      margin-top: 0;
      color: #42b983;
    }

    .modal-body {
      margin: 20px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .modal-default-button {
      margin: 5px;
    }
    .modal-banner {
      margin: 0px;
      padding: 0px; 
      background-color: #07ABA1; 
      width: 98%;
      font-size: 2em;
      color: #FFF;

    }
    .cancelButton {
      background-color: #07ABA1;
      color: #FFF; 
      float: lef; 
      font-size: 1.4em;
      font-weight: 1200;
      border-radius: 0px;
    }

    /* Transition CSS */

    .fade-enter-active, .fade-leave-active {
      transition-property: opacity;
      transition-duration: .25s;
    }

    .fade-enter-active {
      transition-delay: .25s;
    }

    .fade-enter, .fade-leave-active {
      opacity: 0;
    }

    .modal-enter {
      opacity: 0;
    }       

    .modal-leave-active {
      opacity: 0;
    }

    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
</style>
