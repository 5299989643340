<template>
    <div class="main">
        <div class="nav">
            <router-link to="/" class="navlink"> Home </router-link>
            <!-- <router-link to="/phase" class="navlink"> Room </router-link> -->
            <!-- <router-link to="/deleteItem" class="navlink"> Remove Item </router-link> -->
        </div>
        <h1> Insert New Product </h1>
        <h2 v-if="systemMsg" style="color: #FF9b9b">{{systemMsg}}</h2>
        <div v-if="flag1" class="sectionBody">
            <form>
            <table>
                <tr>
                    <th> Name </th>
                    <th> Current Stock </th>
                    <th> Minimum Requirement </th>
                    <th> Notes </th>
                <tr>
                    <td><input type="text" name="name" v-model="name" required="true"></td>
                    <td><input type="number" name="quantity" v-model="quantity" required="true"></td>
                    <td><input type="number" name="minimum" v-model="minimum" required="true"></td>
                    <td><input type="text" name="name" v-model="notes"></td>
                </tr>
            </table>
            <!-- <div>
                <div>
                    <h3> Which Rooms Should this Product be Added to? </h3> 
                    <input type="checkbox" id="room1" v-model="rooms" value="Veg">
                    <label for="room1">Veg</label>
                    <input type="checkbox" id="room2" v-model="rooms" value="1">
                    <label for="room2">1</label> 
                    <input type="checkbox" id="room3" v-model="rooms" value="2">
                    <label for="room2">2</label> 
                    <input type="checkbox" id="room4" v-model="rooms" value="3">
                    <label for="room2">3</label> 
                    <input type="checkbox" id="room4" v-model="rooms" value="Cure">
                    <label for="room2">Cure</label> 
                </div>
                <div>
                    <h3> Which Phases Should this Product be Added to? </h3> 
                    <li v-for="phase in phases">
                          <input type="checkbox" v-bind:value="phase.value" v-model="selectedPhases" name="phase">
                          <label for="phase">{{phase.phase}}</label>
                    </li>
                </div>
            </div> -->
            <div style="padding-top: 5%">
                <button v-on:click="onSubmit">Insert Item</button>
            </div>
            </form>
        </div>

        <div v-if="!flag1" style="padding-top: 2%;">
            <h2> {{name}} Has Been Added to Your Inventory </h2>
            <button v-on:click="flag1 = 1"> Add Another Item </button>
        </div>
    </div>
</template>

<script>
    // import axios from 'axios';
    // axios.defaults.baseURL = 'http://localgost:8080/'
    // axios.defaults.headers.common = {'Authorization': `Bearer: sk_sortly_zgeTP9QsLkrag84CTZw5`}
    
    let clientID = '398bf712e189aa1'
    let clientSecret = '6b408aedf14819bf5ced5425a6b7ab2d68695b38'

    export default {
        created() {
            this.getInventory()
        },
        data () {
            return {
                flag1: 1,
                msg: '',
                name: '',
                notes: '',
                quantity: '',
                minimum: '',
                systemMsg: '',
                parent_id: this.$store.state.inventory,
                stockTable: {},
                searchTable: {},
                searchNames: [],
                restock: [],
                rooms: [],
            }
        },
        methods: {
            getInventory() {
                axios.get('https://api.sortly.co/api/v1/items?per_page=100&page=1&folder_id=' + this.$store.state.inventory +'&include=custom_attributes%2Cphotos')
                  .then(response => {
                    for (let i = 0; i < response.data.data.length; i++){
                      if (response.data.data[i]){
                        this.searchTable[response.data.data[i].name.toLowerCase()] = response.data.data[i]
                        this.searchNames.push(response.data.data[i].name.toLowerCase())
                        this.restock.push(response.data.data[i])
                        this.stockTable[response.data.data[i].name] = (response.data.data[i].custom_attribute_values[0].value + 1) - response.data.data[i].quantity
                      }
                    }
                    this.restock.sort(this.sortItems)
                    console.log(this.searchTable)
                  })
                  .catch(e => {
                    console.log(e);
                  })
              },
              sortItems(a,b){
                const itemA = a.name.toUpperCase()
                const itemB = b.name.toUpperCase()

                let comparison = 0
                if(itemA > itemB){
                  comparison = 1;
                } else if(itemA < itemB) {
                  comparison = -1
                }
                return comparison
              },
               addItem(){
                      let body = {
                      'name': this.name,
                      'notes': this.notes,
                      'quantity': this.quantity,
                      'type': 'item',
                      'parent_id': this.$store.state.inventory,
                      'tags': [{}],
                      'custom_attribute_values': [
                        {
                          'custom_attribute_id': this.$store.state.customAtt,
                          'attribute_value': this.minimum
                        }
                      ]
                    };
                    
                    this.insertItem(body)
                    this.flag1 = 0
                },
                insertItem(item){
                  axios.post('https://api.sortly.co/api/v1/items', item)
                    .then(response => {
                      console.log('SUCCESS')
                    })
                    .catch(e => {
                      console.log(e)
                    })
                },
                onSubmit(e){
                  e.preventDefault() 
                  if (this.validateEntry()){
                        this.addItem()
                  }
                  else {
                      console.log("Please insert a name for the product, an amount, and or a minimum amount necessary")
                      setTimeout(() => this.systemMsg = '', 5000)
                  }
                },
                validateEntry() {
                  if(this.name.length < 1){
                      this.systemMsg = "Please insert a name for the product"
                      return 0
                  }
                  if(this.quantity < 0) {
                      this.systemMsg = "Please insert an amount above 0 for your quantity available"
                      return 0
                  }
                  if(this.minimum <= 0) {
                      this.systemMsg = "Please insert an amount above 1 for your minimum on hand"
                      return 0
                  }
                  if(this.searchTable[this.name.toLowerCase()]){
                      this.systemMsg = "It looks like this item already exists in your inventory. We found an item called: " + this.searchTable[this.name.toLowerCase()].name + " in your inventory"
                      return 0
                  }
                  return 1 
                },
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    h1, h2 {
        font-weight: normal;
    }
    ul {
        list-style-type: none;
        padding: 0;
    }
    li {
        display: inline-block;
        margin: 0 10px;
    }
    a {
        color: #07aba1;
    }

    button {
        padding: 2%;
        font-size: 1em;
        background-color: #07ABA1;
        color: #FFF;
        border-radius: 2px;
        border: none;
        cursor: pointer;
        outline: none;
        box-shadow: none;
    }
    input[type=checkbox] + label {
         position: relative;
	       cursor: pointer;
    }
    input[type=checkbox]:before {
         background-color: #FFF;
    }
    input[type=checkbox] + label :checked:after {
         color: #07ABA1;
    }
    .main {
      display: block;
      width: 100vw;
      height: 100vh;
    }
    .nav {
      height: 1vh;
      width: 100vw;
      padding-left: 3%;
      display: flex;
      /* border: 1px dotted #000; */
      align-items: center;
      justify-content: left;

    }
    .navlink {
        padding-right: 25px;
        font-size: 1.5em;
        text-decoration: none;
    }
    .controller {
        width: 100px;
        height: 50px;
    }
    .sectionBody {
        width: 100vw;
        height: 90vh;
        padding-top: 2%;
        /* border: 1px dotted #000; */
        display: flex;
        justify-content: center;
    }
    .phaseController {
        width: 85%;
        height: 85%;
        border: dotted #000 1px;
        display: flex;
        flex-direction: column;
    }
    .phases {
        width: 100%;
        height: 75%;
        border: dotted #000 1px;
        display: flex;
        flex-direction: row;
    }
    .phase {
        width: 25%;
        height: 100%;
        margin: 2px;
        background-color: lightyellow;
        cursor : grab;
    }
    .phase:hover {
        width: 25%;
        height: 100%;
        background-color: lightgreen;
        cursor: grab;
    }
    .phaseSelected {
        width: 25%;
        height: 100%;
        background-color: lightgreen;
        cursor: grab;
    }
    .selectSubmit {
        height: 25%;
        width: 100%;
        background-color: grey;
        display: flex;
        flex-direction: column;
    }
    .roomSelector {
        width: 100%;
        height: 100%;
        background-color: purple;
        color: #FFF;
    }
    .roomSubmit {
        width: 100%;
        height: 40%;
        background-color: navy;
    }
</style>
