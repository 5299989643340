<template>
<div>

<div class="nav">
    <router-link to="/" class="navlink" style="color: #036D69"> Home </router-link>
    <router-link to="/allItems" class="navlink" style="color: #036D69"> Inventory </router-link>
    <router-link to="/orders" class="navlink"> Order History </router-link>
</div>

<div>
    <div v-if="mainDisplay" style="display: flex; justify-content: center; align-items: center; padding-top: 5%">
        <div class="cardBody">
              <div class="card" v-for="(item, i) in stock" v-bind:key="i">
                  <div> 
                      <h1 class="cardHeader" v-bind:style="item.name.length < 25 ? {'font-size' : '1.1em'} : {'font-size' : '0.9em', 'padding-top' : '5px'}" >{{item.name}}</h1>
                  </div>
                  <div>
                      <input type="checkbox" :value="item" v-model="printList">
                  </div>
              </div>
        </div>
        <!-- <div id="qrcode" class="cardBody">
            <div v-for="(item, i) in stock" v-bind:key="i">
                <div :id="i">
                <vue-qrcode :value="item.FID"/>
                </div>
            </div>
        </div> -->
    </div>

    <div>
        <form>
        <!-- <h3> Inventory Search </h3> -->
        <div style="display: flex; flex-direction: row; justify-content: center;">
        <input style="width: 300px; margin-top: 1%; padding: 5px; outline: none;" type="text" v-model="search" v-on:input="confirmItems($event)" placeholder="Search for a product in your inventory">
        </div>
        </form>
    </div>

    <div style="padding-top: 1%"> 
      <button v-on:click="qr_generate(printList)" style="margin-right: 1%"> Print Selected </button>
      <button v-on:click="qr_generate(printForm)"> Print All </button>
    </div>

</div>

<div style="position: absolute; visibility: hidden;" class="qrcode" ref="qrCodeUrl">
</div>

</div>
</template>

<script>
import VueQrcode from 'vue-qrcode';
import QRCode from '../../lib/qrcode.js';
import jsPDF from 'jspdf';
export default {
  components: {
    VueQrcode
  },
  created() {
    this.getInventory()
  },
  data() {
    return {
      testForm: {
        name: '',
        text: ''
      },
      mainDisplay: 1,
      printDisplay: 0,
      allStock: [],
      searchTable: {},
      searchNames: [],
      allStock: [],
      stockTable: {},
      stock: [],
      printForm: [],
      printList: []
    }
  },
  methods: {
    test() {
      this.$store.dispatch('test', this.testForm)
    },
    testRead(){
      this.$store.dispatch('testRead')
    },
    getInventory() {
      this.allStock = []
      this.searchTable = {}
      this.searchNames = []
      this.allStock = []
      this.stockTable = {}
      this.stock = []
      this.$store.dispatch('getInventory').then(res => {
        for(let i = 0; i < res.length; i++){
          if(res[i]){
            this.printForm.push({'FID' : res[i].FID, 'name' : res[i].name })
            this.searchTable[(res[i].name.toLowerCase())] = res[i]
            this.searchNames.push(res[i].name.toLowerCase())
            this.allStock.push(res[i])
            this.stockTable[res[i].name] = (res[i].necessary_amount + 1) - res[i].quantity
          }
        }
        this.stock = this.allStock.sort(this.sortItems)
      })
    },
    confirmItems(e){
      console.log(event.target.value)
      //console.log(this.searchTable)
      let term = event.target.value
      // let result = this.searchNames.filter(word => word.includes(term));
      
      const result = this.match(term.toLowerCase()) 

      let temp = []
      if(result.length){
        //go through the searchTable find the right items and store for display
        for(let i = 0; i < result.length; i++){
            let cur = result[i]
            if(this.searchTable[cur]){
                temp.push(this.searchTable[cur])
            }
        }
        this.stock = [...new Set (temp.sort(this.sortItems))]
      }
    },
    match(s){
        const p = Array.from(s).reduce((a, v, i) => `${a}[^${s.substr(i)}]*?${v}`, '');
        const re = RegExp(p);

        return this.searchNames.filter(v => v.match(re));
    },
    getBase64Image(img) {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      var dataURL = canvas.toDataURL("image/png");
      return dataURL;
    },
    qr_generate(codes, columns, scale, paper_width, paper_height, margin_left, margin_top){
        columns = columns || 4;
        scale = scale || 0.9;
        paper_width = paper_width || 210.0;
        paper_height = paper_height || 297.0;
        margin_left = margin_left || 10.0;
        margin_top = margin_top || 10.0;

        var doc = new jsPDF();

        //QR CODE GENERATOR - DO NOT CHANGE ANY PARAMETERS EXCEPT columns
        //--------------------------------------------------------------------------
        var ratio = 8.0/parseFloat(columns);
        var x_0 = 3.125*ratio;
        var y_0 = 3.125*ratio;
        var dx = 6.25*ratio;
        var dy = 6.25*ratio; 
        var qr_width = 20.0*ratio;
        var code_margin_top = 5.15*ratio, code_margin_between = 1.0*ratio;
        var font_size_1 = 8.0*ratio, 
            font_size_2 = 8.0*ratio;    

        x_0*=scale;
        y_0*=scale;
        dx*=scale;
        dy*=scale;
        qr_width*=scale;
        code_margin_top*=scale;
        code_margin_between*=scale;
        font_size_1*=scale * 0.8;
        font_size_2*=scale * 0.8;

        x_0 += margin_left;
        y_0 += margin_top;

        var codes_per_page = columns*columns;
        var red_square_width_ratio = 0.13;

        var QRErrorCorrectLevel = {
          L : 1,
          M : 0,
          Q : 3,
          H : 2
        };

        var options = {
            render      : "canvas",
            width       : qr_width,
            height      : qr_width,
            typeNumber  : -1,
            correctLevel    : QRErrorCorrectLevel.H,
                        background      : "#ffffff",
                        foreground      : "#000000"
        };

        var red_square_width = options.width*(red_square_width_ratio);

        for(var k = 0; k < codes.length; k++){
            var code_id = codes[k].FID;
            var code_name = codes[k].name

            if(k != 0 && k%codes_per_page == 0){
                doc.addPage();
            }

            if(k%codes_per_page == 0){
                for(var i = 0; i < columns+1; i++){
                    doc.setDrawColor(200,200,200); // draw red lines
                    doc.setLineWidth(0.2);
                    doc.line(margin_left + scale*paper_width/columns*i, margin_top, margin_left + scale*paper_width/columns*i, margin_top + scale*paper_height); // vertical line
                }
                for(var i = 0; i < columns+1; i++){
                    doc.setDrawColor(200,200,200); // draw red lines
                    doc.setLineWidth(0.2);
                    doc.line(margin_left, margin_top + scale*paper_height/columns*i, margin_left + scale*paper_width, margin_top + scale*paper_height/columns*i); // horizontal line
                }
            }

            var x   = x_0 + (k%columns)*(options.width+dx)
            var y_1 = y_0 + ((k%codes_per_page-k%columns)/columns)*(options.height+code_margin_top+code_margin_between+(parseFloat(font_size_1)+parseFloat(font_size_2))/17.0*4.0+dy)
        
            // create the qrcode itself
            var qrcode  = new QRCode(options.typeNumber, options.correctLevel);
            qrcode.addData(code_id);
            qrcode.make();

            // compute tileW/tileH based on options.width/options.height
            var tileW   = options.width  / qrcode.getModuleCount();
            var tileH   = options.height / qrcode.getModuleCount();

            // draw in the canvas
            var black_line = 0;
            doc.setDrawColor(0);
            doc.setFillColor(0,0,0);

            for( var row = 0; row < qrcode.getModuleCount(); row++ ){
                black_line = 0;
                for( var col = 0; col < qrcode.getModuleCount(); col++ ){
                    //QR CODE (v3) using SQUARES OPTIMIZED
                    if(qrcode.isDark(row, col)){
                        black_line = black_line+1;
                        if(col == qrcode.getModuleCount()-1){
                            doc.rect(x + tileW*(col-black_line+1), y_1 + tileH*row, tileW*black_line, tileH, 'F'); 
                        }
                    } else {
                        if(black_line != 0){
                            doc.rect(x + tileW*(col-black_line), y_1 + tileH*row, tileW*black_line, tileH, 'F'); 
                            black_line = 0;
                        }
                    }
                }   
            }

            var code_name_pre = code_name.substring(0,16);
            var code_name_post = code_name.substring(16,32);

            var y_text_line_1 = y_1 + options.height + parseFloat(font_size_1)/17.0*4.0 + code_margin_top;
            var y_text_line_2 = y_text_line_1 + parseFloat(font_size_2)/17.0*4.0 + code_margin_between;

            doc.setFontSize(font_size_1);
            doc.text(x, y_text_line_1, code_name_pre);
            doc.setFontSize(font_size_2);
            doc.text(x, y_text_line_2, code_name_post);
        }

        doc.output('save', 'qr_codes.pdf');
    }
  }
}
</script>

<style scoped>
    table, th, td {
      border: 1px solid #DFDFDF;
      padding: 2px;
    }
    table {
        border-collapse: collapse;
        margin-left:auto;
        margin-right:auto;
    }  

    ul {
      list-style-type: square;
    }

    h3 {
      margin: 40px 0 0;
    }
    ul {
      list-style-type: none;
      padding: 0;
    }
    li {
      display: inline-block;
      margin: 0 10px;
    }
    a {
      color: #07ABA1;
      font-size: 1.5em;
    }
    button {
      padding: 1%;
      font-size: 1em;
      background-color: #07ABA1;
      color: #FFF;
      border-radius: 2px;
      border: none;
      cursor: pointer;
      outline: none;
      box-shadow: none;
    }
    .toolButton {
      width: 50px; 
      height: 50px; 
      margin: 1%; 
      margin-top: 10%;
      cursor: pointer;
      border-radius: 50%;
      color: #FFF;
      font-size: 1.5em;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .nav {
      padding-left: 3%;
      height: 1vh;
      width: 100vw;
      display: flex;
      align-items: center;
      justify-content: left;
    }

    .navlink {
      padding-right: 25px;
      font-size: 1.5em;
      text-decoration: none;
    }
    .photo {
      width: 75px;
      height: 75px;
      object-fit: cover;
    }

    .test {
      margin: 0px;
      padding: 0px;
    }

    h1, h2 {
      font-weight: normal;
    }
    .cardBody {
      display: grid;
      grid-template-columns: 250px 250px 250px 250px;
      grid-gap: 10px;
      padding: 1%;
      height: 395px;
      overflow-y : scroll;
    }
    .card {
      background-color: lightgrey;
      border-radius: 5px;
      width: 250px;
      height: 200px;
      display: flex;
      flex-direction: column;
    }

/* Modal CSS */

    .modal-mask {
      position: fixed;
      z-index: 9998;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: table;
      transition: opacity 0.3s ease;
    }

    .modal-wrapper {
      display: table-cell;
      vertical-align: middle;
    }

    .modal-container {
      width: 1000px;
      height: 500px;
      margin: 0px auto;
      background-color: #fff;
      border-radius: 2px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
      transition: all 0.3s ease;
      overflow-y: scroll;
      padding-bottom: 2%;
    }

    .modal-header h3 {
      margin-top: 0;
      color: #42b983;
    }

    .modal-body {
      margin: 20px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .modal-default-button {
      margin: 5px;
    }
    .modal-banner {
      margin: 0px;
      padding: 0px; 
      background-color: #07ABA1; 
      width: 98%;
      font-size: 2em;
      color: #FFF;

    }
    .cancelButton {
      background-color: #07ABA1;
      color: #FFF; 
      float: left; 
      padding-right: 2%;
      font-size: 1.4em;
      font-weight: 1200;
      border-radius: 0px;
    }

    /* Transition CSS */

    .fade-enter-active, .fade-leave-active {
      transition-property: opacity;
      transition-duration: .25s;
    }

    .fade-enter-active {
      transition-delay: .25s;
    }

    .fade-enter, .fade-leave-active {
      opacity: 0;
    }

    .modal-enter {
      opacity: 0;
    }       

    .modal-leave-active {
      opacity: 0;
    }

    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
/*                form test                       */
    .form-style-7{
      max-width:400px;
      margin:50px auto;
      background:#fff;
      border-radius:2px;
      padding:20px;
    }
    .form-style-7 h1{
      display: block;
      text-align: center;
      padding: 0;
      margin: 0px 0px 20px 0px;
      color: #5C5C5C;
      font-size:x-large;
    }
    .form-style-7 ul{
      list-style:none;
      padding:0;
      margin:0;	
    }
    .form-style-7 li{
      display: block;
      padding: 9px;
      border:1px solid #DDDDDD;
      margin-bottom: 30px;
      border-radius: 3px;
    }
    .form-style-7 li:last-child{
      border:none;
      margin-bottom: 0px;
      text-align: center;
    }
    .form-style-7 li > label{
      display: block;
      float: left;
      margin-top: -19px;
      background: #FFFFFF;
      height: 14px;
      padding: 2px 5px 2px 5px;
      color: #B9B9B9;
      font-size: 14px;
      overflow: hidden;
    }
    .form-style-7 input[type="text"],
    .form-style-7 input[type="date"],
    .form-style-7 input[type="datetime"],
    .form-style-7 input[type="email"],
    .form-style-7 input[type="number"],
    .form-style-7 input[type="search"],
    .form-style-7 input[type="time"],
    .form-style-7 input[type="url"],
    .form-style-7 input[type="password"],
    .form-style-7 textarea,
    .form-style-7 select 
    {
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      width: 100%;
      display: block;
      outline: none;
      border: none;
      height: 25px;
      line-height: 25px;
      font-size: 16px;
      padding: 0;
    }
    .form-style-7 input[type="text"]:focus,
    .form-style-7 input[type="date"]:focus,
    .form-style-7 input[type="datetime"]:focus,
    .form-style-7 input[type="email"]:focus,
    .form-style-7 input[type="number"]:focus,
    .form-style-7 input[type="search"]:focus,
    .form-style-7 input[type="time"]:focus,
    .form-style-7 input[type="url"]:focus,
    .form-style-7 input[type="password"]:focus,
    .form-style-7 textarea:focus,
    .form-style-7 select:focus 
    {
    }
    .form-style-7 li > span{
      background: #F3F3F3;
      display: block;
      padding: 3px;
      margin: 0 -9px -9px -9px;
      text-align: center;
      color: #C0C0C0;
      font-size: 11px;
    }
    .form-style-7 textarea{
      resize:none;
    }
    .form-style-7 input[type="submit"],
    .form-style-7 input[type="button"]{
      background: #2471FF;
      border: none;
      padding: 10px 20px 10px 20px;
      border-bottom: 3px solid #5994FF;
      border-radius: 3px;
      color: #D2E2FF;
    }
    .form-style-7 input[type="submit"]:hover,
    .form-style-7 input[type="button"]:hover{
      background: #6B9FFF;
      color:#fff;
    }
</style>
