<template>

<div>

<div class="nav">
    <router-link to="/" class="navlink" style="color: #036D69"> Home </router-link>
    <!-- <router-link to="/recommend" class="navlink" style="color: #036D69"> Recommended</router-link> -->
    <router-link to="/allItems" class="navlink" style="color: #036D69"> All Items</router-link>
    <router-link to="/createItem" class="navlink"> Add Item </router-link>
    <!-- <router-link to="/deleteItem" class="navlink"> Remove Item </router-link> -->
    <router-link to="/orders" class="navlink"> Order History </router-link>
    <a class="navLink" v-on:click="showCart" style="cursor: pointer; color: #1279B7;"> Cart </a>
</div>

<transition appear appear-class="fade">
<div v-if="!flag1 && !flag2" style="display: flex; flex-direction: row; justify-content: center; padding-top: 15%;">
    <rotate-square2></rotate-square2>
</div>
</transition appear>

<transition name="fade">
<div v-if="flag1" style="display: flex; justify-content: center; padding-top: 2%;">
    <div class="cardBody">
          <div class="card" v-for="(item, i) in restock" v-bind:key="i">
              <button v-on:click="onEdit(item)"> Edit </button>
              <div> 
                  <h2 class="cardHeader" v-bind:style="item.name.length < 25 ? {'font-size' : '1.1em'} : {'font-size' : '0.9em', 'padding-top' : '5px'}" >{{item.name}}</h2>
              </div>
              <div>
                  <h1 v-bind:style="item.quantity  > item.necessary_amount? {'color' : '#000' } : {'color' : '#ff9b9b'}" style="font-size: 4em; padding: 0px; margin: 0px;">{{item.quantity}}</h1> 
                  <input type="checkbox" :value="item" v-model="orderForm">
              </div>
          </div>
    </div>
</div>
</transition>

<!--- //////////////////////////////////////// handling search option //////////////////////////////////////////////-->

<transition name="fade">
<div v-if="flag1" style="padding-top: 2%">
    <button v-on:click="checkForm" style="background-color: #FF9b9b"> Add to Cart </button>
    <div>
        <form>
        <div style="display: flex; flex-direction: row; justify-content: center;">
        <input style="width: 300px; margin-top: 1%; padding: 5px; outline: none;" type="text" v-model="search" v-on:input="confirmItems($event)" placeholder="Search for a product">
        </div>
        </form>
    </div>
</div>
</transition>

<!--- ///////////////////////////////////////// edit features ///////////////////////////////////////////////////////-->

<transition name="fade">
<div v-if="flag2 && !flag1" style="padding-top: 3%">
<h1> Order Sheet </h1>
<h2 style="color: #ff9b9b">{{msg}}</h2>
<div v-if="flag2 && !flag1" style="display: flex; justify-content: center;">
    <form>
    <table>
        <tr>
            <th> Name </th>
            <th> Current Stock </th>
            <th> Minimum On Hand </th>
            <th> Order Amount </th>
            <th> Add Details </th>
           <!-- <th> Comments </th> -->
            <th> Remove </th>
        </tr>
        <tr v-for="product in orderForm">
            <td v-if="product.name.length > 1">{{product.name}}</td>
            <td v-else><input type="text" style="wdith: 200px" v-on:change="onCustomChange(product.custom, $event)"/></td>
            <td>{{product.quantity}}</td>
            <td v-if="product.onhand" v-bind:style="product.quantity >= product.onhand? {'color' : '#07ABA1' } : {'color' : '#ff9b9b'}">{{product.quantity}} <span style="color: #000">/</span> <span style="color: #000"> {{product.onhand}}</span></td>
            <td v-else>N/A</td>
            <td><input type="number" name-="amount" min="1" style="width: 50px;" v-on:input="onChange(product.name, $event)" required/></td>
            <td><button style="width: 25px; height: 25px; padding: 2px;" v-on:click.stop.prevent="createDetails(product)">+</button> </td>
            <!-- <td><input type="text" name="comment" style="width: 400px" v-on:input="onTextChange(product.name, $event)"/></td> -->
            <td><button style="width: 25px; height: 25px; padding: 2px; background-color: #FF9b9b" v-on:click.stop.prevent="onRemove(product.custom, $event)">x</button></td>
        </tr>
    </table>
    </form>
</div>
<div style="padding-top: 2%">
    <button v-on:click.stop.prevent="cancelOrderForm" style="background-color: #8CB6D2; margin-right: 5px;"> Cancel </button>
    <button v-on:click.stop.prevent="insertProd" style="background-color: #9CDDD9; margin-right: 5px;"> Custom </button>
    <button v-on:click.stop.prevent="addToCart"> Add to Cart </button>
</div>
</div>
</transition>

<transition name="fade">
<div v-if="!flag2 && !flag1 && flag5" style="padding-top: 3%">
<h1> Order has Successfully Been Added to Your Cart</h1>
<div v-if="alertDup">
    <h2 style="color: red;">The following item(s) are already in your cart: </h2>
    <ul v-for="dup in dupAlert">
        <li>{{dup}}</li>
    </ul>
</div>
<h3>Sending you back to the previous screen</h3></div>
</transition>

<!-- ////////////////////////////////////////// edit modal ///////////////////////////////////////////////////////-->

<transition name="modal">
<div v-if="showModal" class="modal-mask">
  <div class="modal-wrapper">
    <div class="modal-container" style="width: 450px; height: 500px; overflow-y: hidden;">
      <div class="modal-header">
        <slot name="header">
        <h1 v-if="modal1" style="padding: 0; margin: 0; background-color: #8CB6D2; color: #FFF"> Edit </h1>
        <h1 v-else style="padding 0; margin: 0; background-color: #FF9B9B; color: #FFF"> Remove </h1>
        </slot>
      </div>

      <div class="modal-body" style="padding: 0; margin: 0;" v-if="modal1">
        <slot name="body">
           <div class="form-style-7">
            <ul>
            <li>
                <label for="name">Product Name</label>
                <input type="text" name="name" maxlength="100" v-model="name" :placeholder="name">
                <span>Enter a comment</span>
            </li>
            <li>
                <label for="stock">Current Stock</label>
                <input type="number" name="stock" maxlength="100" v-model="quantity" :placeholder="quantity">
                <span>Enter amount currently available in inventory</span>
            </li>
            <li>
                <label for="minimum">Minimum Requirement</label>
                <input type="number" name="minimum" maxlength="100" v-model="minimum" :placeholder="minimum">
                <span>Enter amount necessary for operations at any given time</span>
            </li>
            <li style="display: block; padding: 9px; border: 1px solid #ddd; margin-bottom: 30px; border-radius: 3px">
                <label for="notes">Notes</label>
                <input type="text" name="notes" v-model="notes" :placeholder="notes">
                <span>Enter additional info (eg: Pack of 20)</span>
            </li>
                <div v-if="modal1">
                    <button class="modal-default-button" v-on:click="modal1 = 0" style="background-color: #FF9B9B"> Remove </button>
                    <button class="modal-default-button" v-on:click="showModal = 0" style="background-color: rgb(156, 221, 217)"> Cancel </button>
                    <button class="modal-default-button" v-on:click="editItem"> Update </button>
                </div>
            </ul>
           </div>

        </slot>
      </div>

      <div class="modal-body" v-else>
        <slot name="body">
           <div class="form-style-7">
            <h1> Are you sure you want to remove <span>{{name}}</span>?</h1>
           </div>
        </slot>
          <div>
              <button class="modal-default-button" style="width: 65px"  v-on:click="removeItem"> Yes </button>
              <button class="modal-default-button" style= "width: 65px; background-color: #8CB6D2;" v-on:click="modal1 = 1"> Cancel </button>
          </div>
      </div>

      <div class="modal-footer">
        <slot name="footer">

          <!-- <div v-else>
            <button v-on:click="returnPage"> Return </button>
          </div> -->
        </slot>
      </div>
    </div>
  </div>
</div>
</transition>

<!--//////////////////////////////////////////// Cart Model///////////////////////////////////////////////////////// -->

<transition name="modal">
<div v-if="modal2" class="modal-mask">
  <div class="modal-wrapper">
    <div class="modal-container">

      <div class="modal-header">
        <slot name="header">
          <div style="display: flex; flex-direction: row;">
            <h2 class="modal-banner">Your Cart</h2>
            <button v-on:click="closeCart" class="cancelButton" style="padding-right: 3%"> X </button>
          </div>
        </slot>
      </div>

      <div class="modal-body" v-if="modal3">
        <slot name="body">
            <div v-if="shoppingCart">
            <table>
                <tr>
                    <th> Name </th>
                    <th> Amount </th>
                    <th> On Hand </th>
                    <th> Check </th>
                    <th> Comment </th>
                <tr v-for="item in this.$store.state.shoppingCart" :key="item.custom">
                    <td>{{item.name}}
                    <td>{{item.amount}}</td>
                    <td v-if="item.onhand" v-bind:style="item.cur >= item.onhand? {'color' : '#07ABA1' } : {'color' : '#ff9b9b'}">{{item.cur}} <span style="color: #000">/</span> <span style="color: #000"> {{item.onhand}}</span></td>
                    <td v-else style="color: #CDEEEC"> N/A </td>
                    <td> <span v-if="item.cur + item.amount >= item.onhand || !item.onhand" style="color: #07ABA1">&#x2713;</span>
                         <span v-else style="color: #ff9b9b; font-weight: bold;"> X </span>
                    </td>
                    <td>{{item.comment}}</td>
                </tr>
            </table>
            </div>
        </slot>
      </div>

      <div class="modal-body" v-if="modal4">
        <slot name="body">
            <div style="padding: 5px;">
            <h3 style="color: #ff9b9b; margin: 0px 0px 10px 0px;">{{msg}}</h3>
            <table>
                <tr>
                    <th> Name </th>
                    <th> On Hand </th>
                    <th> Order Amount </th>
                    <th> Add Details </th>
                    <!-- <th> Comments </th> -->
                    <th> Remove</th>
                </tr>
                <tr v-for="product in orderForm">
                    <td v-if="product.name.length > 1">{{product.name}}</td>
                    <td v-else><input type="text" style="width: 200px; border: solid lightgrey 1px" v-on:change="onCustomChangeCart(product.custom, $event)"/></td>
                    <td v-if="product.onhand"v-bind:style="product.cur > product.onhand? {'color' : '#07ABA1' } : {'color' : '#ff9b9b'}">{{product.cur}} <span style="color: #000">/</span> <span style="color: #000"> {{product.onhand}}</span></td>
                    <td v-else> N/A </td>
                    <td><input type="number" name="amount" min="1" style="width: 50px; border: lightgrey solid 1px;" v-on:input="onChangeCart(product.name, $event)" :placeholder="product.amount"/></td>
                    <td><button style="width: 25px; height: 25px; padding: 2px;" v-on:click.stop.prevent="createDetails(product)">+</button> </td>
                    <!-- <td><input type="text" name="comment" style="width: 400px; border: none;" v-on:input="onTextChangeCart(product.name, $event)" :placeholder="product.comment"/></td> -->
                    <td><button style="width: 25px; height: 25px; padding: 2px; background-color: #FF9b9b" v-on:click.stop.prevent="onRemove(product.custom, $event)">x</button></td>
                </tr>
            </table>
            <button v-on:click.stop.prevent="insertProd" style="background-color: #9CDDD9; margin-right: 5px;"> Custom </button>
            <button style="margin-top: 2%; background-color: #07ABA1" v-on:click="updateCart"> Update Cart </button>
            </div>
        </slot>
      </div>

      <div class="modal-body" v-if="modal5">
        <slot name="body">
            <div>
                <h1> Your order has been successfully registered! </h1>
                <h2> We will contact you if there are any problems</h2>
                <table>
                    <tr>
                        <th> Name </th>
                        <th> Amount </th>
                        <th> Comment </th>
                    <tr v-for="item in this.$store.state.shoppingCart" :key="item.custom">
                        <td>{{item.name}}
                        <td>{{item.amount}}</td>
                        <td>{{item.comment}}</td>
                    </tr>
                </table>
            </div>
        </slot>
      </div>

      <div class="modal-footer" v-if="!modal4 && !modal5">
        <slot name="footer">
          <button class="modal-default-button" v-on:click="clearCart" style="background-color: #8CB6D2;"> Clear Cart </button>
          <button class="modal-default-button" v-on:click="editCart" style="background-color: #9CDDD9;"> Edit Cart </button>
          <button class="modal-default-button" v-on:click="submitCart"> Submit </button>
        </slot>
      </div>
    </div>
  </div>
</div>
</transition>

<!---//////////////////////////////////  Add Link to Order Model //////////////////////////////////// -->


<transition name="modal">
<div v-if="modalAdditionalInfo" class="modal-mask">
  <div class="modal-wrapper">
    <div class="modal-container" style="width: 450px; height: 500px; overflow-y: hidden;">
      <div class="modal-header">
        <slot name="header">
          <div style="display: flex; flex-direction: row;">
            <h2 class="modal-banner">{{currentItem.name}}</h2>
            <button v-on:click="modalAdditionalInfo = 0" class="cancelButton" style="padding-right: 6%"> + </button>
          </div>
        </slot>
      </div>

      <div class="modal-body">
        <slot name="body">
            <div class="form-style-7">
            <ul>
            <li>
                <label for="comment">Comment</label>
                <input type="text" name="name" maxlength="100" v-on:input="addDetails(currentItem, $event, 'comment')" :placeholder="currentItem.comment">
                <span>Enter a comment</span>
            </li>
            <li>
                <label for="manufacturer">Manufacturer</label>
                <input type="text" name="manufacturer" maxlength="100" v-on:input="addDetails(currentItem, $event, 'manu')" :placeholder="currentItem.manufacturer">
                <span>Enter a manufacturer (eg: OpenSprinklr)</span>
            </li>
            <li>
                <label for="part">Part No.</label>
                <input type="text" name="part" maxlength="100" v-on:input="addDetails(currentItem, $event, 'part')" :placeholder="currentItem.part">
                <span>Enter a part number (if applicable)</span>
            </li>
            <li style="display: block; padding: 9px; border: 1px solid #ddd; margin-bottom: 30px; border-radius: 3px">
                <label for="link">Link</label>
                <textarea name="link" v-on:input="addDetails(currentItem, $event, 'link')" :placeholder="currentItem.productLink"></textarea>
                <span>Enter a link for the product</span>
            </li>
            <!-- <li>
                <input type="submit" value="Send This" >
            </li> -->
            </ul>
            </div>
        </slot>
      </div>

      <div class="modal-footer" v-if="!modal4 && !modal5">
        <slot name="footer">
        </slot>
      </div>
    </div>
  </div>
</div>
</transition>

<!--  ////////////////////////////// End of the HTML elements ////////////////////////////// -->

</div>

</template>

<script>
    import {RotateSquare2} from 'vue-loading-spinner'
    export default {
        created() {
            // this.$store.dispatch('getUserInfo').then(res => {
            //     this.$store.state.userProfile = res
            // })
            this.getInventory()
            // this.loadFolders()
        },
        mounted() {
            // setTimeout(() => this.flag1 = 1, 500);
            this.flag1 = 1
            // this.$store.dispatch('getUserInfo').then(res => {
            //     this.$store.state.userProfile = res
            // })
        },
        components: {
          RotateSquare2
        },
        data () {
            return {
                facility: this.$store.state.facility,
                posts: [],
                error: [],
                filter: [],
                restock: [],
                orderForm: [],
                order: [],
                folderIDs: [],
                subFolderIDs: [],
                phasesFolders: [],
                facilityFolders: [],
                additionalLocations: [],
                dupAlert: [],
                testTable: {},
                stockTable: {},
                shoppingCart: this.$store.state.shoppingCart,
                dupCheck: {},
                //////
                searchTable: {},
                searchNames: [],
                //////
                accProfile: this.$store.state.userProfile,
                modalAdditionalInfo: 0,
                modalAddLink: 0,
                currentItem: {},
                //////
                i: 0,
                flag1: 0,
                flag2: 0,
                flag3: 0,
                flag4: 1,
                flag5: 0,
                alertDup: 0,
                showOrder: 0,
                custom: 0,
                showModal: 0,
                modal1: 1,
                modal2: 0,
                modal3: 0,
                modal4: 0,
                modal5: 0,
                editFlag: 0,
                finalSub: 0,
                name: '',
                notes: '',
                quantity: '',
                minimum: '',
                edit_id: '',
                search: '',
                msg: '',
            }
        },
        methods: {
              getInventory() {
                this.$store.dispatch('getInventory').then(res => {
                  console.log(res)
                  for(let i = 0; i < res.length; i++){
                    if(res[i]){
                      // console.log(res[i])
                      if (res[i].quantity <= res[i].necessary_amount){
                        this.searchTable[(res[i].name.toLowerCase())] = res.[i]
                        this.searchNames.push(res[i].name.toLowerCase())
                        this.restock.push(res[i])
                        this.stockTable[res[i].name] = (res[i].necessary_amount + 1) - res[i].quantity
                      }
                    }
                  }
                  this.restock.sort(this.sortItems)
                })
              },
              sortItems(a,b){
                const itemA = a.name.toUpperCase()
                const itemB = b.name.toUpperCase()

                let comparison = 0
                if(itemA > itemB){
                  comparison = 1;
                } else if(itemA < itemB) {
                  comparison = -1
                }
                return comparison
              },
              generateHash(el, arr){
                let newObject = {'name': '', 
                                 'amount' : '',  
                                 'cur' : '', 
                                 'onhand' : '', 
                                 'comment' : '', 
                                 'custom' : '',
                                 'isCustom' : '', 
                                 'status' : '',
                                 'adsComment' : '',
                                 'productLink' : '',
                                 'manufacturer' : '',
                                 'part' : '',
                                 'orderDate': '',
                                 'orderedBy' : '',
                                 }
                    newObject['name'] = el.name
                    newObject['cur'] = el.cur
                    newObject['onhand'] = el.onhand
                    newObject['isCustom'] = el.isCustom
                    newObject['custom'] = el.custom
                    newObject['status'] = el.status
                    newObject['productLink'] = el.productLink
                    newObject['manufacturer'] = el.manufacturer
                    newObject['part'] = el.partNo
                    newObject['orderDate'] = el.orderDate
                    newObject['orderedBy'] = el.orderedBy
                    arr.push(newObject)
              },
              checkForm() {
                this.flag1 = 0
                this.flag2 = 1
                let temp = []
                for(let i = 0; i < this.orderForm.length; i++) {
                    let newObject = {'name' : this.orderForm[i].name,
                                     'cur' : this.orderForm[i].quantity,
                                     'onhand': this.orderForm[i].necessary_amount,
                                     'quantity' : this.orderForm[i].quantity,
                                     'isCustom' : this.orderForm[i].isCustom,
                                     'custom' : this.createRand(),
                                     'status' : 0,
                                     'adsComment' : '',
                                     'productLink' : this.orderForm[i].productLink,
                                     'manufacturer' : this.orderForm[i].manufacturer,
                                     'part' : this.orderForm[i].partNo,
                                     'orderDate' : new Date(),
                                     'orderedBy' : this.accProfile[0].name
                                    }
                    temp.push(newObject)
                }
                this.orderForm = temp
                this.orderForm.forEach(el =>
                    this.generateHash(el, this.order)
                );
              },
              cancelOrderForm(){
                  this.flag2 = 0
                  this.flag1 = 1
                  this.orderForm =[]
              },
              createRand() {
                  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
              },
              insertProd(e){
                  e.preventDefault()
                  let newObject = {'name' : '', 
                                   'amount' : '', 
                                   'cur': '', 
                                   'onhand' : '', 
                                   'comment' : '', 
                                   'isCustom' : 1,
                                   'custom' : this.createRand(), 
                                   'status' : 0, 
                                   'adsComment' : '',
                                   'productLink' : '',
                                   'manufacturer' : '',
                                   'part' : '',
                                   'orderDate' : new Date(),
                                   'orderedBy': this.accProfile[0].name}
                  this.orderForm.push(newObject)
                  this.order.push(newObject)
              },
              onChange(str, event){
                  if(event.target.value < 1){
                      this.msg = "A invalid amount has been typed for " + str
                  }
                  else {
                  this.msg = ''
                      for (let i = 0; i < this.order.length; i++){
                            if(this.order[i].name === str){
                              this.order[i].amount = event.target.value
                          }
                      }
                  }
              },
              onTextChange(str, event){
                  for (let i = 0; i < this.order.length; i++){
                        if(this.order[i].name === str){
                          this.order[i].comment = event.target.value
                      }
                  }
              },
              onCustomChange(id, event) {
                  event.preventDefault()
                  console.log("item id is: " + id)
                  for (let i = 0; i < this.order.length; i++) {
                      if(id === this.order[i].custom){
                            this.order[i].name = event.target.value
                      }
                  }
              },
              onRemove(id) {
                console.log("firing from on onRemove - id is: " + id)
                let newView = []
                for(let i = 0; i < this.orderForm.length; i++){
                    if(this.orderForm[i].custom != id) {
                        newView.push(this.orderForm[i])
                    }
                }

                this.orderForm = newView

                let output = []
                for(let j = 0; j < this.order.length; j++){
                    console.log(id)
                    if(this.order[j].custom != id) {
                        output.push(this.order[j])
                    }
                }
                this.order = output
              },
              createDetails(item){
                this.modalAdditionalInfo = 1
                this.currentItem = item
                console.log('add a link to the current order row for: ' + item.custom)
              },
              addDetails(item, event, subject){
                if (subject ===  'link'){
                    for(let i = 0; i < this.order.length; i++) {
                        if(item.custom === this.order[i].custom) {
                            this.order[i].productLink = event.target.value
                            this.currentItem.productLink = event.target.value
                        }
                    }
                } else if (subject === 'manu'){
                    for(let i = 0; i < this.order.length; i++) {
                        if(item.custom === this.order[i].custom) {
                            this.order[i].manufacturer = event.target.value
                            this.currentItem.manufacturer = event.target.value
                        }
                    }
                } else if (subject === 'part'){
                    for(let i = 0; i < this.order.length; i++) {
                        if(item.custom === this.order[i].custom) {
                            this.order[i].part = event.target.value
                            this.currentItem.part = event.target.value
                        }
                    }                  
                } else if (subject === 'comment'){
                    for(let i = 0; i < this.order.length; i++) {
                        if(item.custom === this.order[i].custom) {
                            this.order[i].comment = event.target.value
                            this.currentItem.comment = event.target.value
                        }
                    }                  
                }
              },
//////////////////////////////////////////// Search Bar Functions //////////////////////////////////////////////
              confirmItems(e){
                console.log(event.target.value)
                //console.log(this.searchTable)
                let term = event.target.value
                // let result = this.searchNames.filter(word => word.includes(term));
                
                const result = this.match(term.toLowerCase()) 

                let temp = []
                if(result.length){
                  //go through the searchTable find the right items and store for display
                  for(let i = 0; i < result.length; i++){
                      let cur = result[i]
                      if(this.searchTable[cur]){
                          temp.push(this.searchTable[cur])
                      }
                  }
                  this.restock = [...new Set (temp.sort(this.sortItems))]
                }
              },
              match(s){
                  const p = Array.from(s).reduce((a, v, i) => `${a}[^${s.substr(i)}]*?${v}`, '');
                  const re = RegExp(p);
  
                  return this.searchNames.filter(v => v.match(re));
              },

//////////////////////////////////////////// Edit + Modal Functions ////////////////////////////////////////////
              onEdit(item) {
                console.log("in edit item")
                console.log(item)
                this.showModal = 1
                console.log(item)
                console.log("the id of the product is: " + item.id)
                this.edit_id = item.FID
                this.name = item.name
                this.notes = item.notes
                this.quantity = item.quantity
                this.minimum = item.necessary_amount
              },
              editItem() {
                let body = {
                  'name': this.name,
                  'notes': this.notes,
                  'quantity': this.quantity,
                  'necessary_amount' : this.minimum,
                  'FID' : this.edit_id
                };
                this.$store.dispatch('updateItem', body).then(res => {
                  console.log(res)
                  this.showModal = 0
                  setTimeout(() => {
                    this.modal1 = 1
                    this.restock = []
                    this.search = ''
                    this.getInventory()
                  }, 1000);
                })
            },
            returnPage(){
              this.showModal = 0
              setTimeout(() => {
                this.modal1 = 1
                this.restock = []
                this.search = ''
                this.getInventory()
              })
            },
            removeItem() {
                  let id = this.edit_id
                  console.log("id is: " + id)
                  this.$store.dispatch('deleteItem', id).then(res => {
                    console.log(res)
                    this.returnPage()
                  })
            },
//////////////////////////////////////////// Shopping Cart Functions ////////////////////////////////////////////
            showCart(){
                console.log("View Cart")
                this.modal2 = 1
                this.modal3 = 1
            },
            closeCart(){
                if(this.finalSub){
                  this.clearCart()
                  this.finalSub = 0
                }
                this.modal2 = 0
                this.modal3 = 0
                this.modal4 = 0
                this.modal5 = 0
                this.orderForm = []
                this.editFlag = 0
            },
            editCart(){
                this.modal4 = 1
                this.modal3 = 0
                this.modal5 = 0
                if(!this.editFlag){
                    this.editFlag = 1
                    let curCart = this.$store.state.shoppingCart
                    for(let i = 0; i < curCart.length; i++){
                       this.orderForm.push(curCart[i])
                    }
                }
                console.log(this.orderForm)
            },
            clearCart(){
                this.$store.commit('removeItems', this.$store.state.shoppingCart.length)
                this.modal2 = 0
                this.modal3 = 0
                this.modal4 = 0
                this.msg = ''
            },
            updateCart(){
                let commit = 1
                let curCart = this.$store.state.shoppingCart
                let missing = []
                this.$store.commit('removeItems', curCart.length)
                let order = this.$store.state.shoppingCart
                console.log('order: ' + order)
                for(let i = 0; i < this.orderForm.length; i++){
                    let cur = this.orderForm[i]
                    console.log(cur)
                    if(!cur.amount){
                      missing.push(cur.name)
                      commit = 0
                      this.msg = "Please insert an amount for: " + missing
                      break
                    } else if (cur.isCustom && cur.comment.length < 3){
                      missing.push(cur.name)
                      commit = 0
                      this.msg = "Please include a description in the comments (link, details, etc.) for: " + missing
                      break
                    }
                }

                if (commit){
                  for(let j = 0; j < this.orderForm.length;j++){
                      this.$store.commit('addItems', this.orderForm[j])
                  }
                  this.modal3 = 1
                  this.modal4 = 0
                  this.orderForm = []
                  this.editFlag = 0
                  console.log(curCart)
                } 
            },
            submitCart(e){
                e.preventDefault()
                this.modal5 = 1
                this.modal3 = 0
                console.log("Flags are: " + this.modal2 + this.modal3 + this.modal4)
                this.handleSubmit(e)
                this.finalSub = 1
            },
            addToCart(){
                this.dupAlert = []
                this.alertDup = 0
                let commit = 1
                let missing = []
                let curCart = this.$store.state.shoppingCart
                console.log(curCart)
                for (let i = 0; i < curCart.length;i++){
                    if(!this.dupCheck[curCart[i].name]){
                      this.dupCheck[curCart[i].name] = true
                    }
                }

                // scan through and make sure there aren't any items that have not amounts attached
                for(let i = 0; i < this.order.length; i++){
                    if(!this.order[i].amount){
                      missing.push(this.order[i].name)
                      commit = 0
                      this.msg = "Please insert an amount for: " + missing
                      break
                    } else if (this.order[i].isCustom && this.order[i].comment.length < 3){
                      missing.push(this.order[i].name)
                      commit = 0
                      this.msg = "Please include a description in the comments (link, details, etc.) for: " + missing
                      break
                    }
                }
                if(!commit){
                    console.log("Add to cart did not go through")
                }

                if (commit){
                    for(let i = 0; i < this.order.length;i++){
                        if(!this.dupCheck[this.order[i].name]){
                            this.$store.commit('addItems', this.order[i])
                            this.dupCheck[this.order[i].name] = true
                        }
                        else {
                            this.dupAlert.push(this.order[i].name)
                            this.alertDup = 1
                        }
                    }
                    this.flag2 = 0
                    this.flag5 = 1
                    this.orderForm = []
                    this.order = []
                    this.msg = ''
                    setTimeout(() => this.flag1 = 1 ,  2000);
                }
            },
            // Submit order form
            handleSubmit(e){
              e.preventDefault()
              let order = this.$store.state.shoppingCart
              console.log(order)
              this.$store.dispatch('logOrder', order)

            },
            resetCartView() {
                this.flag1 = 1
                this.dupAlert = []
                this.alertDup = 0
            },
            onChangeCart(str, event){
                  if(event.target.value < 1){
                    this.msg = "Please input a valid amount " + str
                  }
                  else {
                    this.msg = ''
                    for (let i = 0; i < this.orderForm.length; i++){
                          if(this.orderForm[i].name === str){
                            this.orderForm[i].amount = event.target.value
                        }
                    }
                }
            },
            onTextChangeCart(str, event){
                for (let i = 0; i < this.orderForm.length; i++){
                      if(this.orderForm[i].name === str){
                        this.orderForm[i].comment = event.target.value
                    }
                }
            },
            onCustomChangeCart(id, event) {
                event.preventDefault()
                console.log("item id is: " + id)
                for (let i = 0; i < this.orderForm.length; i++) {
                    if(id === this.orderForm[i].custom){
                          this.orderForm[i].name = event.target.value
                    }
                }
            },
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    table, th, td {
      border: 1px solid #DFDFDF;
      padding: 2px;
    }
    table {
        border-collapse: collapse;
        margin-left:auto;
        margin-right:auto;
    }  

    ul {
      list-style-type: square;
    }

    h3 {
      margin: 40px 0 0;
    }
    ul {
      list-style-type: none;
      padding: 0;
    }
    li {
      display: inline-block;
      margin: 0 10px;
    }
    a {
      color: #07ABA1;
      font-size: 1.5em;
    }
    button {
      padding: 1%;
      font-size: 1em;
      background-color: #07ABA1;
      color: #FFF;
      border-radius: 2px;
      border: none;
      cursor: pointer;
      outline: none;
      box-shadow: none;
    }
    .nav {
      padding-left: 3%;
      height: 1vh;
      width: 100vw;
      display: flex;
      align-items: center;
      justify-content: left;
    }

    .navlink {
      padding-right: 25px;
      font-size: 1.5em;
      text-decoration: none;
    }
    .photo {
      width: 75px;
      height: 75px;
      object-fit: cover;
    }

    .test {
      margin: 0px;
      padding: 0px;
    }

    h1, h2 {
      font-weight: normal;
    }
    .cardBody {
      display: grid;
      grid-template-columns: 250px 250px 250px 250px;
      grid-gap: 10px;
      padding: 1%;
      overflow-y: scroll;
      height: 400px;
    }
    .card {
      background-color: lightgrey;
      border-radius: 5px;
      width: 250px;
      height: 200px;
      display: flex;
      flex-direction: column;
    }

/* Modal CSS */

    .modal-mask {
      position: fixed;
      z-index: 9998;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: table;
      transition: opacity 0.3s ease;
    }

    .modal-wrapper {
      display: table-cell;
      vertical-align: middle;
    }

    .modal-container {
      width: 1000px;
      height: 500px;
      margin: 0px auto;
      background-color: #fff;
      border-radius: 2px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
      transition: all 0.3s ease;
      overflow-y: scroll;
      padding-bottom: 2%;
    }

    .modal-header h3 {
      margin-top: 0;
      color: #42b983;
    }

    .modal-body {
      margin: 20px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .modal-default-button {
      margin: 5px;
    }
    .modal-banner {
      margin: 0px;
      padding: 0px; 
      background-color: #07ABA1; 
      width: 98%;
      font-size: 2em;
      color: #FFF;

    }
    .cancelButton {
      background-color: #07ABA1;
      color: #FFF; 
      float: left; 
      padding-right: 2%;
      font-size: 1.4em;
      font-weight: 1200;
      border-radius: 0px;
    }

    /* Transition CSS */

    .fade-enter-active, .fade-leave-active {
      transition-property: opacity;
      transition-duration: .25s;
    }

    .fade-enter-active {
      transition-delay: .25s;
    }

    .fade-enter, .fade-leave-active {
      opacity: 0;
    }

    .modal-enter {
      opacity: 0;
    }       

    .modal-leave-active {
      opacity: 0;
    }

    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
/*                form test                       */
.form-style-7{
	max-width:400px;
	margin:50px auto;
	background:#fff;
	border-radius:2px;
	padding:20px;
}
.form-style-7 h1{
	display: block;
	text-align: center;
	padding: 0;
	margin: 0px 0px 20px 0px;
	color: #5C5C5C;
	font-size:x-large;
}
.form-style-7 ul{
	list-style:none;
	padding:0;
	margin:0;	
}
.form-style-7 li{
	display: block;
	padding: 9px;
	border:1px solid #DDDDDD;
	margin-bottom: 30px;
	border-radius: 3px;
}
.form-style-7 li:last-child{
	border:none;
	margin-bottom: 0px;
	text-align: center;
}
.form-style-7 li > label{
	display: block;
	float: left;
	margin-top: -19px;
	background: #FFFFFF;
	height: 14px;
	padding: 2px 5px 2px 5px;
	color: #B9B9B9;
	font-size: 14px;
	overflow: hidden;
}
.form-style-7 input[type="text"],
.form-style-7 input[type="date"],
.form-style-7 input[type="datetime"],
.form-style-7 input[type="email"],
.form-style-7 input[type="number"],
.form-style-7 input[type="search"],
.form-style-7 input[type="time"],
.form-style-7 input[type="url"],
.form-style-7 input[type="password"],
.form-style-7 textarea,
.form-style-7 select 
{
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	width: 100%;
	display: block;
	outline: none;
	border: none;
	height: 25px;
	line-height: 25px;
	font-size: 16px;
	padding: 0;
}
.form-style-7 input[type="text"]:focus,
.form-style-7 input[type="date"]:focus,
.form-style-7 input[type="datetime"]:focus,
.form-style-7 input[type="email"]:focus,
.form-style-7 input[type="number"]:focus,
.form-style-7 input[type="search"]:focus,
.form-style-7 input[type="time"]:focus,
.form-style-7 input[type="url"]:focus,
.form-style-7 input[type="password"]:focus,
.form-style-7 textarea:focus,
.form-style-7 select:focus 
{
}
.form-style-7 li > span{
	background: #F3F3F3;
	display: block;
	padding: 3px;
	margin: 0 -9px -9px -9px;
	text-align: center;
	color: #C0C0C0;
	font-size: 11px;
}
.form-style-7 textarea{
	resize:none;
}
.form-style-7 input[type="submit"],
.form-style-7 input[type="button"]{
	background: #2471FF;
	border: none;
	padding: 10px 20px 10px 20px;
	border-bottom: 3px solid #5994FF;
	border-radius: 3px;
	color: #D2E2FF;
}
.form-style-7 input[type="submit"]:hover,
.form-style-7 input[type="button"]:hover{
	background: #6B9FFF;
	color:#fff;
}

</style>
