<template>
    <div class="main">
        <div class="nav">
            <router-link to="/" class="navlink"> Home </router-link>
            <!-- <router-link to="/phase" class="navlink"> Room </router-link> -->
            <router-link to="/createItem" class="navlink"> Add Item </router-link>
            <a class="navlink" :href="$router.resolve({name: 'deleteItem'}).href">Remove Item</a>

        </div>
        
        <h1> Remove an Item </h1> 
        <h2 v-if="flag1 || flag3" style="color: #036D69"> {{ this.result }} </h2>
       
        <transition name="fade">
        <div v-if="flag2" style="display: flex; justify-content: center; align-items: center; flex-direction: row; padding-top: 2%;">
              <div class="card" v-for="item in targetAll" v-bind:key="item.id">
                  <h1> {{item.name}} </h1>
              </div>

              <div style="padding-left: 5%; text-align: left">
                  <h3 style="margin-bottom: 0px; padding-bottom: 0px;"> Remove From Your Entire Facility? </h3> 
                 <!-- <span> {{ removeList }} </span> -->
                  <br>
                  <button v-on:click="removeAllItems"> Yes </button>

                <!-- <h3> Remove From Specific Room? </h3>
                  <button v-on:click="flag3 = 1, flag2 = 0"> Yes </button> -->
              </div>
        </div>
        </transition>

        <transition name="fade">
        <div v-if="flag3" style="display: flex; justify-content: center;">
            <div class="cardBody">
                <div class="card" v-for="item in targetItem" v-bind:key="item.id">  
                    <h1 style="padding-bottom: 0px; margin-bottom: 0px; color: #036D69">{{item.roomName}}</h1>
                    <h2 style="margin: 0px; padding: 0px;">{{item.phaseName}}</h2>
                    <h3>{{item.name}}</h3>
                    <div>
                    <input type="checkbox" v-model="removeList" :value="item.id"/>
                    </div>
                </div>
            </div>
        </div>
        </transition>
        
        <transition name="fade">
        <div v-if="flag3">
           <!-- <span> {{removeList }} </span> -->
            <button v-on:click="removeItems"> Remove </button>
        </div>
        </transition>

        <transition name="fade">
        <div v-if="flag1" class="sectionBody">
            <div>
                <form>
                    <div style="display: flex; flex-direction: row;">
                    <input style="width: 300px" type="text" v-model="search" placeholder="Search for a product"><button class="searchButton" v-on:click="confirmItem"> Search </button>
                    </div>
                </form>
            </div>
        </div>
        </transition>

        <transition name="fade">
        <div v-if="flag4" class="sectionBody">
            <h1 style="color: #FF9B9B"> Delete Successful </h1>
        </div>
        </transition>
    </div>
</template>

<script>
    let clientID = '398bf712e189aa1'
    let clientSecret = '6b408aedf14819bf5ced5425a6b7ab2d68695b38'

    export default {
        created () {
            this.loadFolders()
            this.loadSubFolders()
        },
        beforeUpdate() {
        },
        data () {
            return {
                facility: this.$store.state.facility,
                flag1: 1,
                flag2: 0,
                flag3: 0,
                flag4: 0,
                search: '',
                targetItem: [],
                targetAll: [],
                stockTable: [],
                stockPhaseTable: [],
                folderIDs: [],
                subFolderIDs: [],
                phasesFolders: [],
                facilityFolders: [],
                additionalLocations: [],
                removeList: [],
                roomNames: [],
                result: '',
            }
        },
        methods: {
              loadFolders(){
                axios.get('https://api.sortly.co/api/v1/items?per_page=100&page=1&folder_id=' + this.$store.state.root+'&include=custom_attributes%2Cphotos')
                  .then(response => {
                    for (let i = 0; i < response.data.data.length; i++){
                        this.folderIDs.push(response.data.data[i].id)
                    }
                    this.folderSearch(this.folderIDs)
                  })
                  .catch(e => {
                    console.log(e);
                  })
              },
              loadSubFolders(){
                axios.get('https://api.sortly.co/api/v1/items?per_page=100&page=1&folder_id=' + this.facility + '&include=custom_attributes%2Cphotos')
                  .then(response => {
                    for (let i = 0; i < response.data.data.length; i++){
                        let newObj = {'id' : '', 'name' : '', 'parentFolder' : ''}
                        newObj['id'] = response.data.data[i].id
                        newObj['name'] = response.data.data[i].name
                        newObj['parentFolder'] = response.data.data[i].parent_id
                        this.roomNames.push(newObj)
                        this.subFolderIDs.push(response.data.data[i].id)
                    }
                    this.subFolderSearch(this.subFolderIDs)
                  })
                  .catch(e => {
                    console.log(e);
                  })
              },
              folderScan(id) {
                axios.get('https://api.sortly.co/api/v1/items?per_page=100&page=1&folder_id=' + id + '&include=custom_attributes%2Cphotos')
                  .then(response => {
                    this.fetchItems(response.data.data)
                  })
                  .catch(e => {
                    console.log(e);
                  })
              },
              subFolderScan(id) {
                axios.get('https://api.sortly.co/api/v1/items?per_page=100&page=1&folder_id=' + id + '&include=custom_attributes%2Cphotos')
                  .then(response => {
                    this.fetchRoomFolders(response.data.data)
                  })
                  .catch(e => {
                    console.log(e);
                  })
              },
              subFolderItemScan(id) {
                axios.get('https://api.sortly.co/api/v1/items?per_page=100&page=1&folder_id=' + id + '&include=custom_attributes%2Cphotos')
                  .then(response => {
                    this.fetchSubItems(response.data.data)
                  })
                  .catch(e => {
                    console.log(e);
                  })
              },
              folderSearch(folders){
                  folders.forEach(el =>
                 // folders
                    this.folderScan(el)
                    )
              },
              subFolderSearch(folders){
                  for(let i = 0; i < folders.length; i++){
                    this.subFolderScan(folders[i])
                  }
              },
              fetchItems(arr){
                  arr.forEach(el =>
                    this.stockTable[el.name] = el
                  )
              },
              fetchSubItems(arr){
                  for(let i = 0; i < arr.length; i++){
                      let newObj = {'id' : '', 'name': '', 'room' : '', 'phaseName' : '', 'roomName' : '', 'main': false}
                      let cur = this.findRoomName(arr[i].parent_id)
                      newObj['id'] = arr[i].id
                      newObj['name'] = arr[i].name
                      newObj['room'] = arr[i].parent_id
                      newObj['phaseName'] = cur.name
                      newObj['roomName'] = this.findRoomName(cur.parentFolder).name
                      this.additionalLocations.push(newObj)
                  }
              },
              fetchRoomFolders(arr){
                  for (let i = 0; i < arr.length; i++) {
                      let newObj = {'id' : '', 'name' : '', 'parentFolder' : ''}
                      newObj['id'] = arr[i].id
                      newObj['name'] = arr[i].name
                      newObj['parentFolder'] = arr[i].parent_id
                      this.roomNames.push(newObj)
                      this.subFolderItemScan(arr[i].id)
                  }
              },
              // Main function of the page
              confirmItem(e) {
                  e.preventDefault()
                  //console.log(this.roomNames)
                  if(this.stockTable[this.search]){
                      //scan facility for additional locations of that item
                      this.result = 'Item Found'
                      this.flag2 = 1
                      this.flag1 = 0
                      this.findItem()
                      this.locateAdditional()
                  }
                  else {
                      console.log('no item found')
                      this.result = 'Item Not Found (try a different spelling?)'
                  }
              },
              // main function
              findItem() {
                  let newObj = {'id' : '', 'name' : '', 'room' : '', 'phaseName' : 'Database', 'roomName' : 'Entire', 'main' : true}
                  let roomNames = []
                  newObj['id'] =  this.stockTable[this.search].id
                  newObj['name'] =  this.stockTable[this.search].name
                  this.findRoomName(this.stockTable[this.search].parent_id)
                  newObj['room'] =  this.stockTable[this.search].parent_id  
                  this.targetAll.push(newObj)
              },
              findRoomName(id) {
                  //console.log('The room id is: ' + id)
                  let name = {}
                  for(let i = 0; i < this.roomNames.length; i++){
                      if (this.roomNames[i].id === id) {
                          name = this.roomNames[i]
                          //this.findRoomName(this.roomNames[i].parentFolder)
                      }
                  }
                  return name
              },
              locateAdditional() {
                  for(let i = 0; i < this.additionalLocations.length; i++) {
                     if( this.additionalLocations[i].name == this.search) {
                        this.findRoomName(this.additionalLocations[i].room)
                        this.targetItem.push(this.additionalLocations[i])
                     }
                  }
              },
              removeItems(){
                  if (this.removeList.length < 1) {
                      alert("No room was selected!")
                  }
                  else {
                      this.flag3 = 0
                      for(let i = 0; i < this.removeList.length; i++) {
                          this.removeItem(this.removeList[i])
                      }
                  }
              },
              removeItem(id) {
                  axios.delete('https://api.sortly.co/api/v1/items/' + id)
                    .then(response => {
                      //this.flag1 = 1
                      this.flag4 = 1
                      console.log('DELETE SUCCESSFUL')
                    })
                    .catch(e => {
                      console.log(e);
                    })
              },
              removeAllItems() {
                  let remove = []
                  remove.push(this.targetAll[0].id)
                  console.log(remove)

                  for(let i = 0; i < this.targetItem.length; i++) {
                      remove.push(this.targetItem[i].id)
                  }

                  console.log(remove)

                  for(let j = 0; j < remove.length; j++) {
                     this.removeItem(remove[j])
                  } 

                  this.flag2 = 0
                  this.flag4 = 1
                  this.targetAll = []
                  this.result = 'All items have been removed'
              },
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    h1, h2 {
        font-weight: normal;
    }
    ul {
        list-style-type: none;
        padding: 0;
    }
    li {
        display: inline-block;
        margin: 0 10px;
    }
    a {
        color: #07ABA1;
    }
    button {
        padding: 1%;
        font-size: 1em;
        background-color: #07ABA1;
        color: #FFFF;
        border-radius: 2px;
        border: none;
        cursor: pointer;
        outline: none;
        box-shadow: none;
    }
    input[type="text"] {
        padding: 10px;
        font-size: 17px;
        border: 1px solid grey;
        float: left;
        width: 100%;
        background: #f1f1f1;
        outline: none;
    }
    .searchButton {
        float: left;
        width: 20%;
        padding: 10px;
        background: #07ABA1;
        color: white;
        font-size: 17px;
        border: 1px solid grey;
        border-left: none; /* Prevent double borders */
        cursor: pointer;    
        outline: none;
    }
    .main {
      display: block;
      width: 100vw;
      height: 100vh;
    }
    .nav {
      height: 1vh;
      width: 100vw;
      padding-left: 3%;
      display: flex;
     /*  border: 1px dotted #000; */
      align-items: center;
      justify-content: left;

    }
    .navlink {
        padding-right: 25px;
        font-size: 1.5em;
        text-decoration: none;
    }
    .sectionBody {
        width: 100vw;
        height: 90vh;
        /* border: 1px dotted #000; */
        display: flex;
        justify-content: center;
    }
    .cardBody {
        display: grid;
        grid-template-columns: 250px 250px 250px 250px;
        grid-gap: 10px;
        padding: 1%;
        overflow-y: scroll;
        height: 195px;
        margin-bottom: 3%;
    }
    .card {
        background-color: lightgrey;
        border-radius: 5px;
        width: 250px;
        height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .fade-enter-active, .fade-leave-active {
        transition-property: opacity;
        transition-duration: .20s;
    }
    .fade-enter-active {
        transition-delay: .20s;
    }

    .fade-enter, .fade-leave-active {
        opacity: 0;
    }
</style>
